import React from 'react';
import propTypes from 'prop-types';
import { Card, Empty, Pagination, Space, Spin, message, Skeleton, theme} from 'antd';
import { GET } from '../../frameworks/HttpClient';
import * as _ from 'lodash';
import { PRIMARY_COLOR } from '../../constants/color';
import InfiniteScroll from 'react-infinite-scroll-component';
import ColorButton from './ColorButton';
import { ItemChildrenWrap } from 'antd-mobile/es/components/dropdown/item';

const DEFAULT_PAGE_SIZE = 10;

const CustomCardList = React.forwardRef((props, ref) => {
  const {
    // required props
    url,
    details,

    // fetch related props
    manual,
    params,
    pagination,
    pageSize,
    infiniteScroll,
    buttonTitle,
    buttonExtra,
    action,
    // custom data
    data,

    // card style
    footer,
  } = props;

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1)
  const [dataLazyLoad ,setDataLazyLoad] = React.useState([])

  const { token: { colorSuccess } } = theme.useToken();

  React.useImperativeHandle(ref, () => ({
    fetchData: fetchData
  }))

  const fetchData = async (additionalParams) => {
    setLoading(true);
    try {
      const response = await GET(url, {
        page_size: pageSize,
        ...params, 
        ...additionalParams,
      })
      let data3 = response.data.links.next
      setDataLazyLoad(data3)
      setDataList(_.get(response, (pagination || url) ? 'data.results' : 'data', []))
      if (pagination) {
        setTotal(_.get(response, 'data.total', 0))
      }
    } catch (error) {
      messageApi.error(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }
  
  const fatchDataLazyload = async () =>{
    setLoading(true);
    try{
      if(dataLazyLoad){
        const response = await GET(dataLazyLoad)
        setDataList([...dataList, ...response.data.results])
        setDataLazyLoad(response.data.links.next)
      }
    } catch (error) {
      messageApi.error(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }
  const fetchMoreData= () => {
    setTimeout(fatchDataLazyload, 1000);
  };

  const renderValue = (field, el) => {
    let renderMethod = _.get(details, `${field}.render`, null)
    return renderMethod ? (
      renderMethod(_.get(el, _.get(details, `${field}.dataIndex`, ''), ''), el)
    ) : (
      _.get(el, _.get(details, `${field}.dataIndex`, ''), '')
    )
  }

  const checkCondition = (item, el) => {
    if(item.condition){
      let isPass = item.condition(el)
      return isPass
    } else {
      return true;
    }
  }

  React.useState(() => {
    if (!manual) {
      fetchData();
    }
  }, [])

  return (
    <div>
      {contextHolder}
      <Spin spinning={loading}>
        {(!data && !dataList.length && !loading ) && <Empty/>}
        <div>
        {
          (data ? data : dataList).map((el, idx) => (
            <Card
              className='card-footer'
              // key={el.id || idx} 
              hoverable = {false}
              actions={action}
              style={{ marginBottom: '1rem' }}
              onClick={() => {
                if (_.get(details, 'onClick', false)) {
                  details.onClick(el);
                }
              }}>
                <Card.Meta
                  title={
                    <span>
                      {_.get(details, 'title.title', '')} {renderValue('title', el)}
                      {_.get(details, 'header.title', '')} {renderValue('header', el)}
                      {buttonTitle && 
                        <div style={{ float : 'right' }}>
                          <a onClick={(e) => e.stopPropagation()}>
                            <ColorButton
                              type={buttonTitle.color ? 'primary' : ''}
                              style={{ marginLeft : 5}}
                              override={buttonTitle.color}
                              icon={buttonTitle.icon}
                              onClick={() => buttonTitle.onClick(el.id)}>
                                {buttonTitle.text}
                            </ColorButton>
                          </a>
                        </div>}
                    </span>
                  }
                  avatar={_.get(details, 'header.icon', '')}
                  description={
                    <div>
                      {_.get(details, 'descriptions', []).map((description, idx) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Space style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            {_.get(description, 'icon', '')}{ _.get(description, 'title', '') }
                          </Space>
                          <div style={{ whiteSpace: 'nowrap' }}>
                            {renderValue(`descriptions.${idx}`, el)}
                          </div>
                        </div>
                      ))}
                      {buttonExtra && buttonExtra.map((item) =>{
                        let isPass = checkCondition(item, el)
                        return (
                        <div style={{ float : 'right', marginTop : 10}}>
                            {(!item.condition || isPass) &&
                              <a onClick={(e) => e.stopPropagation()}>
                                <ColorButton
                                  type={item.color ? 'primary' : ''}
                                  style={{ marginLeft : 2}}
                                  override={item.color}
                                  icon={item.icon}
                                  onClick={() => item.onClick(el)}>
                                    {item.text}
                                </ColorButton>
                              </a>
                            }
                        </div>)}
                        )
                      }
                    </div>
                  }/>
                { footer && <footer style={{ background: PRIMARY_COLOR }}/> }
                
              </Card>
          )) }
          <div style={{ textAlign: 'center' }}>
            { (pagination && !loading) && (
              <Pagination
                showSizeChanger
                defaultCurrent={1}
                showLessItems
                defaultPageSize={pageSize}
                current={page}
                total={total}
                onChange={(page) => {
                  setPage(page)
                  fetchData({ page })
                }}/>
            )}
          </div>
          {infiniteScroll && 
            <InfiniteScroll
              dataLength={dataList.length}
              next={fetchMoreData}
              hasMore={dataLazyLoad}
              hasChildren={true}
              loader={
                <Skeleton
                  paragraph={{
                    rows: 1,
                  }}
                  active
                />
              }
            />}
        </div>
      </Spin>
    </div>
  )
});

export default CustomCardList;

CustomCardList.defaultProps = {
  // required props
  url: null,
  details: {
    header: {},
    descriptions: [],
  },

  // fetch related props
  manual: false,
  params: {},
  pagination: true,
  pageSize: DEFAULT_PAGE_SIZE,
  infiniteScroll: false,
  // custom data
  data: null,
  action : null,

  // card style
  footer: false,
}

CustomCardList.propTypes = {
  // required props
  url: propTypes.string.isRequired,
  urlLazylad: propTypes.string.isRequired,
  details: propTypes.array.isRequired,
  
  // fetch related props
  manual: propTypes.string.bool,
  params: propTypes.object,
  showPagination: propTypes.bool,
  showInfiniteScroll: propTypes.bool,
  buttonExtra : propTypes.array,
  buttonTitle : propTypes.object,

  // custom data
  data: propTypes.array,

  // card style
  footer: propTypes.bool
}
