import React from 'react';
import propTypes from "prop-types";
import {theme, Alert, Card, Image, List } from 'antd';
import { URL_MEMBER } from '../constants/urls';
import {  GET } from '../frameworks/HttpClient';
import userImg from '../assets/user.png'
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';


export default function FarmerProfileView(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [farmer, setFarmer] = React.useState(null);
  const [data, setData] = React.useState([]);
  const { t, i18n } = useTranslation();


  const {
    token: { colorPrimary }
  } = theme.useToken();

  // Fetch farmer detail
  const fetchFarmer = async (id) => {
    if (!id) {
      setErrorMessages(t("member_information_not_found"))
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_MEMBER.FARMER}${id}/`)
      setFarmer(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (farmer) {
      setData([
        {key: 1, title: t("farmers.full_name"), description: farmer.full_name},
        {key: 2, title: t("farmers.raot_number"), description: farmer.raot_number},
        {key: 3, title: t("farmers.address"), description: `${farmer.address} ${farmer.sub_district_detail}`},
      ])
    }
  }, [farmer])

  React.useEffect(() => {
    if (props.user && props.user.farmer)
      fetchFarmer(props.user.farmer)

  }, [props.user]);

  return (
    <Content
      style={{
        textAlign: 'center',
        color: colorPrimary,
        fontWeight: 'bold'
      }}>
      {errorMessages && <Alert style={{textAlign: "left", marginBottom: 16}} type="error" showIcon message={errorMessages}/>}

      <Card bordered={false} >
        <Image
          style={{borderRadius: 8, height: 180, width: "auto"}}
          src={(farmer && farmer.card) ? farmer.card : userImg}/>
      </Card>

      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item key={item.key}>
            <List.Item.Meta
              style={{textAlign: "left"}}
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </Content>
  )
}

FarmerProfileView.defaultProps = {
  user: null,
}

FarmerProfileView.propTypes = {
  user: propTypes.object,
}