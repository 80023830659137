import React from 'react';
import { Tabs, Layout, } from 'antd';
import ProductListView from '../components/collectingcenter/ProductListView'
import CustomerListView from '../components/collectingcenter/CustomerListView';
import CarListView from '../components/collectingcenter/CarListView';
import { CarOutlined, GoldOutlined, UserOutlined } from '@ant-design/icons';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';

export default function CCSettingView() {
  const [activeKey, setActiveKey] = React.useState('customer');
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');

  const onTabsChange = (key) => setActiveKey(key);

  return (
    <Layout style={{}}>
      <Tabs
        size='large'
        activeKey={activeKey}
        onChange={onTabsChange}
        items={[
          { key: 'customer', label: <span>{isOnMobile ? null : <UserOutlined/>} ลูกค้า</span>, children: <CustomerListView /> },
          { key: 'product', label: <span>{isOnMobile ? null : <GoldOutlined />} สินค้า</span>, children: <ProductListView /> },
          { key: 'car', label: <span>{isOnMobile ? null : <CarOutlined />} ยานพาหนะ</span>, children: <CarListView /> },
        ]} />
    </Layout>

  )
}