import React from 'react';
import propTypes from "prop-types";
import {Col, List, Row, theme} from 'antd';
import { ShopFilled } from '@ant-design/icons';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
export default function DetailItem(props) {
	const { lessThanOrEqual } = useWindowDimensionContext();
	const isOnMobile = lessThanOrEqual('md');

  const {
    token: {colorPrimary }
  } = theme.useToken();

  return (
		<List.Item style={{...props.style}} onClick={props.onClick}>
			{!isOnMobile && 
			<Row>
			<Col md={12} sm={24} xs={24}>
				{props.titleIcon}
				<label 
					style={{
						fontSize: 18, 
						marginLeft: (props.titleIcon) ? 8 : 0
					}}>
					
					{props.title}
				</label>
			</Col>

			<Col md={12} sm={24} xs={24}>
				<div style ={{ textAlign : 'right'}}>
				<label 
					style={{
						fontSize: 18, 
						marginRight: (props.onEdit) ? 16 : 0}}>{props.detail}</label>
						
				</div>
			</Col>
			</Row>
			}

      {isOnMobile && 
			<Row>
			<Col md={12} sm={24} xs={24}>
				{props.titleIcon}
				<label 
					style={{
            fontWeight: 'bold',
            color : colorPrimary,
						fontSize: 18, 
						marginLeft: (props.titleIcon) ? 8 : 0
					}}>
					
					{props.title}
				</label>
			</Col>

			<Col md={12} sm={24} xs={24}>
				<div>
				<label 
					style={{
						fontSize: 18, 
						marginRight: (props.onEdit) ? 16 : 0}}>{props.detail}</label>
						
				</div>
			</Col>
			</Row>
			}
    	</List.Item>

	)
}

DetailItem.defaultProps = {
	style: null,
	title: "",
	titleIcon: null,
	detail: "",
	fontSize: 18,
	onEdit: null,
	onClick: () => {},
}

DetailItem.propTypes = {
	style: propTypes.object,
	title: propTypes.string,
	titleIcon: propTypes.element,
	detail: propTypes.string,
	fontSize: propTypes.number,
	onEdit: propTypes.func,
	onClick: propTypes.func,
}