import React from 'react';
import { Alert, theme, Tooltip, Popconfirm, Space, Row, Col } from 'antd';
import TSTable from '../components/common/TSTable';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, CloudServerOutlined, UnlockOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from '../constants/string';
import { SECONDARY_COLOR } from '../constants/color';
import CCRegisterModal from '../components/collectingcenter/CCRegisterModal';
import { URL_AUTH, URL_MEMBER } from '../constants/urls';
import { DELETE } from '../frameworks/HttpClient';
import ColorButton from '../components/common/ColorButton';
import ChangePasswordModal from '../components/user/ChangePasswordModal';
import { PATH } from '../routes/CustomRoutes';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';


export default function CollectingCenterListView() {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [openCCModal, setOpenCCModal] = React.useState(false);
  const [targetChangePW, setTargetChangePW] = React.useState(null);
  const [targetEdit, setTargetEdit] = React.useState(null)
  const tableRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
    }
  }

  const {
    token: { colorError, colorWarning, colorInfo, colorSuccess }
  } = theme.useToken();

    const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_MEMBER.COLLECTING_CENTER}${id}/`)
      tableRef.current.fetch();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
    
  }

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("collecting_center"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        // landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("collecting_center"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("collecting_center")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      sorter: true,
      searcher: true,
    },
    {
      title: t("collecting_center"),
      dataIndex: i18n.language === 'th' ? "name" : "name_en",
      sorter: true,
      searcher: true, 
    },
    {
      title: t("phone_number"),
      width: 120,
      dataIndex: "phone",
      searcher: true,
    },
    {
      title: t("admin"),
      dataIndex: 'full_name',
      searcher: true,
    },
    {
      title: t('status'),
      width: 120,
      dataIndex: 'is_active',
      render: (value) => {return (value ? t("active") : t("not_active"))}
    },
    {
      title: 'Action',
      width: 180,
      dataIndex: 'id',
      skipReport: true,
      render: (value, original) => {
        return (
          <div style={{textAlign: "center"}}>
            <Space>
              <Tooltip title={t("edit")}>
                <ColorButton
                  type='primary'
                  override={colorWarning}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setTargetEdit(original)
                  }}/>
              </Tooltip>
              <Tooltip title={t("delete")}>
              <Popconfirm
                title={t("delete")}
                description={t("collecting_centers.confirm_delete")}
                onConfirm={() => handleDelete(value)}
                okText={t("delete")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}>
                  <ColorButton 
                    type='primary'
                    override={colorError}
                    icon={<DeleteOutlined />}/>
              </Popconfirm>
              </Tooltip>
              <Tooltip title="Dashboard">
                <ColorButton 
                  type='primary'
                  override={colorInfo}
                  icon={<CloudServerOutlined />}
                  onClick={() => window.open(`${PATH.COLLECTING_CENTER_DASHBOARD}/${value}`)}/>
              </Tooltip>
              
              <Tooltip title={t("change_password")}>
                <ColorButton 
                  type='primary'
                  override={colorSuccess}
                  icon={<UnlockOutlined/>}
                  onClick={() => {
                    setTargetChangePW(original.user)
                  }} />
              </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ]

  React.useEffect(() => {
    console.log(i18n.language)
  },[])

  return (
    <div>
      <Space style={{ float: 'right' }}>
      <Row gutter={8}>
        <Col>
          
          <ColorButton
            icon={<FileExcelOutlined />}
            style={{float: "right",  marginLeft : 8 }}
            onClick={() => exportTable("excel")}>
            Excel
          </ColorButton>
          <ColorButton
            icon={<FilePdfOutlined />}
            style={{float: "right", marginLeft : 8 }}
            onClick={() => exportTable("pdf")}>
            PDF
          </ColorButton>
        </Col>
        <Col>
          <ColorButton 
            type={"primary"} 
            override={SECONDARY_COLOR}
            icon={<PlusCircleOutlined />} 
            style={{float: "right", marginBottom: '1rem'}}
            onClick={() => setOpenCCModal(true)}>
              {t("collecting_centers.add")}
          </ColorButton>
        </Col>
      </Row>
      </Space>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: 16, textAlign: "left"}}
        />
      }
      <TSTable 
        ref={tableRef}
        columns={columns}
        data={''}
        url={`${URL_MEMBER.COLLECTING_CENTER}`}
        rowKey={"id"}
        size={"small"}
        pageSize={10}/>
      
      <CCRegisterModal
        open={(openCCModal || targetEdit) ? true : false}
        onUpdated={() => {
          if (openCCModal)
            setOpenCCModal(false)
          if (targetEdit)
            setTargetEdit(null)
          refreshData();
        }}
        onClose={() => {
          if (openCCModal)
            setOpenCCModal(false)
          if (targetEdit)
            setTargetEdit(null)
        }}
        target= {targetEdit}
      />

      <ChangePasswordModal 
        open={targetChangePW ? true : false}
        url={`${URL_AUTH.USER}${targetChangePW ? targetChangePW : ""}/change_password/`}
        onClose={() => setTargetChangePW(null)}/>
    </div>
  )
}