export const PRIMARY_COLOR = "#215640";
// export const SECONDARY_COLOR = "#EA6228";
export const SECONDARY_COLOR = "#EF6934";
export const WHITE_COLOR_BG = "#FFFFFF";
export const BG_LAYOUT_COLOR = "#EDEDED"
export const TYPE_FARMER = "#00C37D";
export const TYPE_COLLECTINGCENTER = "#48A0F1";
export const TYPE_CUSTOMER = "#EC9437";
export const TYPE_FACTORY = "#3f3f3f"
export const CYAN_COLOR = "#13c2c2"
export const ORANGE_COLOR = "#fa8c16"
export const DARK_GRAY_COLOR = "#656565"
export const BLACK_COLOR = "#000000"