import React from "react";
import { Col, Modal, Row, message, Select, Spin, Button, theme } from "antd";
import ColorButton from "../common/ColorButton";
import Form from "antd/es/form/Form";
import { URL_CUSTOMER, URL_MEMBER } from "../../constants/urls";
import { GET, POST } from "../../frameworks/HttpClient";
import _ from "lodash";
import { useTranslation } from 'react-i18next';


export default function SearchCCModal(props) {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [fetching, setFetching] = React.useState(false);
    const [collectingCenter, setCollectingCenter] = React.useState([]);
    const [debouncedCode, setDebouncedCode] = React.useState(null);
    const { t, i18n } = useTranslation();
    
    const {
      token: { colorPrimary, colorWarning, colorInfo },
    } = theme.useToken();

    const debounceCC = React.useCallback(
        _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
        []
      );

    const fetchCollectingCenter = async () => {
        setFetching(true);
        try {
          const response = await GET(`${URL_MEMBER.COLLECTING_CENTER}?search=${debouncedCode}`)
          setCollectingCenter(response.data.results.map((item) => {
            return {
              ...item,
              label: `${item.name}, ${item.sub_district_detail}`,
              value: item.user
            }
          }))
        } catch (error) {
          console.log(error.errorMessages)
        } finally {
          setFetching(false);
        }
    }

      const handleOK = async() => {
        setLoading(true);
        let data = {}
        
        try {
          data = await form.validateFields();
          data['ref'] = data.ref.value

          await POST(URL_CUSTOMER.CUSTOMER, data)

          props.onUpdate();

        } catch (error) {
            message.error(error.errorMessages);
        } finally {
            setLoading(false);
        }
      }

    React.useEffect(() => {
        if (debouncedCode)
        fetchCollectingCenter();
    }, [debouncedCode])


    React.useEffect(() => {
      if (!props.open && form) {
        form.resetFields();
      }
    }, [props.open])

  return(
    <Modal
      title={t("collecting_centers.add")}
      open={props.open}
      onCancel={props.onClose}
      confirmLoading={loading}
      layout={"vertical"}
      onOk={handleOK}
      footer={
        <>
          <Button loading={loading} onClick={props.onClose}>{t("cancel")}</Button>
          <ColorButton type='primary' override={props.target ? colorWarning : colorPrimary} loading={loading} onClick={handleOK}>{props.target ? t("edit") : t("collecting_centers.add")}</ColorButton>
        </>
      }>

      <Form
        form={form}
        autoComplete="off"
        layout={"vertical"}>
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              name={'ref'}
              label={t("search_for_collection_points")}
              rules={[{ required: true }]}>
                <Select 
                  initialvalues={"-"}
                  autoComplete="off" 
                  showSearch
                  labelInValue
                  filterOption={false}
                  notFoundContent={fetching ? <Spin size="small" /> : "-"}
                  onSearch={debounceCC}
                  options={collectingCenter}
                  allowClear={"-"}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
