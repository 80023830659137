
import React from 'react';
import { Col, Row, message, } from 'antd';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, LayersControl, Polygon, LayerGroup, Popup } from "react-leaflet";
import { URL_DASHBOARD } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';
import { useParams } from 'react-router-dom';
import { DEFAULT_LOCATION } from '../../constants/string';
import * as _ from 'lodash';


export default function DashBoardMapCC(props) {
  const [dataMap, setDataMap] = React.useState([]);
  const params = useParams();
  const id = (props.id ? props.id : params.id)

  const iconMarker = new L.Icon({
    iconUrl: require('../../assets/marker-1.png'),
    iconRetinaUrl: require('../../assets/marker-1.png'),
    iconAnchor: [13, 40],
    iconSize: [25, 40],
  });
  const fetchDataMap = async () => {
    try {
      const response = await GET(`${URL_DASHBOARD.OVERALL_PLANTATION_CC}${id}/`)
      setDataMap(response.data.results)
    } catch (error) {
      message.error(error.errorMessage);
    }
  }
  React.useEffect(() => {
    if (id) {
      fetchDataMap();
    }
  }, []);

  return (
    <Row gutter={16} >
      <Col md={24} sm={24} xs={24} >
        <div style={{ height: "800px", width: "100%" }}>
          <MapContainer zoom={8} maxZoom={20} minZoom={5} center={DEFAULT_LOCATION} attributionControl={false} >
            <LayersControl>
              <LayersControl.BaseLayer checked name="Google Map">
                <LayerGroup>
                  <TileLayer
                    maxZoom={20}
                    attribution="Google Maps"
                    url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                  />
                  {dataMap && dataMap.map((item) => {
                    let data = _.get(item, 'polygons.total', []).map((polygon) => {
                      return polygon.map((obj) => [obj.latitude, obj.longitude])
                    })
                    return (
                      <LayerGroup>
                        <Polygon
                          positions={data}
                          value={item.id}
                        >
                        </Polygon>
                      </LayerGroup>
                    )
                  })}
                </LayerGroup>
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Map Satellite">
                <LayerGroup>
                  <TileLayer
                    maxZoom={20}
                    attribution="Google Maps Satellite"
                    url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                  />
                  {dataMap && dataMap.map((item) => {
                    let data = _.get(item, 'polygons.total', []).map((polygon) => {
                      return polygon.map((obj) => [obj.latitude, obj.longitude])
                    })
                    return (
                      <LayerGroup>
                        <Polygon
                          positions={data}
                          value={item.id}
                        >
                        </Polygon>
                      </LayerGroup>
                    )
                  })}
                  <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                </LayerGroup>
              </LayersControl.BaseLayer>
            </LayersControl>
            {dataMap.map((item) => {
              return item.location ? (
                <Marker position={[item.location.latitude, item.location.longitude]} icon={iconMarker}>
                  <Popup>{[item.code]}<br />{item.sub_district_detail}</Popup>
                </Marker>
              ) : <></>
            })}
          </MapContainer>
        </div>
      </Col>
    </Row>
  )
}