import { Form, Input, message, Modal } from "antd";
import React from "react";
import Header from "./Header";
import { useTranslation } from 'react-i18next';

export default function AddPolygonModal(props) {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const getArrayDepth = (value) => {
    return Array.isArray(value) ? 1 + Math.max(0, ...value.map(getArrayDepth)) : 0
  }

  const arrangeArray = (polygon) => {  // The polygon should be nested array with 4 depths
    if(getArrayDepth(polygon) < 4) {
      return arrangeArray([polygon])
    }
    else {
      return polygon
    }
  }

  const handleCreatePolygon = async() => {
    try {
      const data = await form.validateFields();
      props.createPolygon(arrangeArray(JSON.parse(data.polygon))[0])
      props.onClose()
    } catch (error) {
      message.error(t("please_enter_new_coordinates"))
    }
  }

  React.useEffect(() => {
    if(props.open){
      form.resetFields();
    }
  },[props.open])

  return(
    <Modal
      okText={t("ok")}
      cancleText={t("cancel")}
	    width={'50%'}
      open={props.open}
      onOk={handleCreatePolygon}
      onCancel={props.onClose}
			>
        <Header title={t("coordinates")}></Header>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off">
        
        <Form.Item
          label={t("current_polygon_coordinates")}
        >
        <Input.TextArea label={t("current_coordinates")} rows={10} readOnly defaultValue={props.data}/>
        </Form.Item>


        <Form.Item
          name={"polygon"}
          label={t("add_polygon_using_coordinates")}
        >
          <Input.TextArea  rows={10} />
        </Form.Item>
        </Form>
      </Modal>
  )
}