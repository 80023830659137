import React from "react";
import { Button, Card, Col, Divider, Input, InputNumber, Modal, Row, Space, theme, Typography } from "antd";
import Header from "../common/Header";
import { formatComma } from "../../frameworks/Util";
import { EditOutlined } from '@ant-design/icons';
import { POST } from "../../frameworks/HttpClient";
import { URL_WEIGHT } from "../../constants/urls";
import Cookies from "js-cookie";
import ErrorMessages from "../common/ErrorMessages";
import { useTranslation } from 'react-i18next';


export default function ScaleCardEditPaymentModal(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [editValue, setEditValue] = React.useState(null);
  const cashierID = Cookies.get('id')
  const { t, i18n } = useTranslation();


  const {
      open,
      onClose,
      target,
      onUpdate
    } = props;

    const handleForceUpdate = async (ticket, field_name) => {
      setErrorMessages(null);
      if(editValue){
        try {
          await POST(URL_WEIGHT.TICKET_FORCE_UPDATE, {
            ticket, field_name, new_value: editValue, cashier : cashierID,
          });
          onUpdate();
        } catch (error) {
          setErrorMessages(error.errorMessages);
        }
      } else {
        setErrorMessages(t("please_enter_the_new_payment_amount"));
      }
    }

  return(
    <Modal
      width={400}
      footer={[]}
      open={open}
      onCancel={onClose}
      title={t("edit_the_actual_payment_amount")}
      centered={true}
    >               
    <ErrorMessages message={errorMessages}/>
      <Row>
        <Space.Compact block style={{ marginTop : 10, width : '100%'}}>
        <InputNumber 
          addonBefore={t("actual_payment_amount")} 
          defaultValue={target && formatComma(target.paid_amount)} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
          value={editValue} 
          onChange={(value) => setEditValue(value)}
        />
          <Button 
            type="primary" 
            icon={<EditOutlined />}
            onClick={() => handleForceUpdate(target.id, 'paid_amount')}>
              {t("edit")}
          </Button>
        </Space.Compact>
      </Row>
    </Modal>
  )
}