import { Button, Card, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import * as _ from 'lodash';
import { CUSTOMER_TYPE, CUSTOMER_TYPE_TRANSLATION } from '../../constants/string';
import { TYPE_COLLECTINGCENTER, TYPE_CUSTOMER, TYPE_FACTORY, TYPE_FARMER } from '../../constants/color';
import { EditOutlined } from '@ant-design/icons';


export default function CustomerCard(props) {
  const {
    data,
    onClick,
    onEdit,
  } = props;

  const blockCardClickEvent = e => {
    if (typeof(_.get(e, 'stopPropagation', null) == 'function')) {
      e.stopPropagation();
    }
  }

  const footerColor = {
    [CUSTOMER_TYPE.GENERAL]: TYPE_CUSTOMER,
    [CUSTOMER_TYPE.FARMER]: TYPE_FARMER,
    [CUSTOMER_TYPE.COLLECTING_CENTER]: TYPE_COLLECTINGCENTER,
    [CUSTOMER_TYPE.FACTORY]: TYPE_FACTORY,
  }

  return (
    <div>
      <Card className='card-footer' hoverable onClick={onClick}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space direction='vertical'>
            <Typography.Title level={4} style={{ margin: 0 }}>{data.full_name ? data.full_name : ''}</Typography.Title>
            <Typography.Text type='secondary'>ประเภท: {_.get(CUSTOMER_TYPE_TRANSLATION, data.type, '-')}</Typography.Text>
            <Typography.Text type='secondary'>ID: {data.code ? data.code : '-'}</Typography.Text>
          </Space>
          <Button shape='circle' size='large' icon={<EditOutlined/>} onClick={(e) => {
            blockCardClickEvent(e);
            onEdit();
          }}/>
        </div>
        <footer style={{ backgroundColor: _.get(footerColor, data.type, '')}}/>
      </Card>
    </div>
  )
}