import React from 'react'
import propTypes from "prop-types";
import { Header } from 'antd/es/layout/layout'
import { Button, Space, Typography, theme } from 'antd';
import { LeftOutlined, CloseOutlined } from '@ant-design/icons';
import "./../../styles.css"
import { useNavigate } from 'react-router-dom';
import { BG_LAYOUT_COLOR } from '../../constants/color';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import LanguageMenu from './LanguageMenu';

export default function HeaderScreen(props) {
	const { lessThanOrEqual } = useWindowDimensionContext();
	const isOnMobile = lessThanOrEqual('md');
	const {
		title,
		showBackIcon,
		showCloseIcon,
		element,
		onClick,
	} = props;

	const navigate = useNavigate();

	const {
    token: { colorPrimary },
  } = theme.useToken();

	const goBack = () => navigate(-1)
	const goClose = () => { window.close(); navigate(-1) }
	const closeWindow = () => {
		window.opener = null;
		window.open('', '_self');
		window.close();
		}

	const isCanBack = window.history.length > 1;
	// const previousURL = document.referrer;



	return (
		<Header 
			style={{ 
				position: 'sticky', 
				top: 0, 
				zIndex: 1, 
				width: '100%', 
				background: BG_LAYOUT_COLOR, 
				color: "#fff", 
				paddingInline: '0',	
			}}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Space direction='horizontal' align='baseline'>
					{ showBackIcon && (
						<Button
							style={{ color: colorPrimary }}
							type='text'
							size='large'
							icon={<LeftOutlined/>}
							onClick={onClick ? onClick : isCanBack === true ? goBack : closeWindow} />
							// onClick={() => {
							// 	console.log(isCanBack)
							// }} />
					)}
					{ showCloseIcon && (
						<Button
							style={{ color: colorPrimary }}
							type='text'
							size='large'
							icon={<CloseOutlined/>}
							onClick={onClick ? onClick : goClose} />
					)}
					<Typography.Title style={{ margin: 0, color: colorPrimary }} level={5}>{title}</Typography.Title>
					{element}
				</Space>
				 
				<div style={{display: "inline-block", float: "right", marginRight: isOnMobile ? 0 : 16}}>
                	<LanguageMenu/>
				</div>
			</div>
		</Header>
	)
}

HeaderScreen.defaultProps = {
	title: null,
	showBackIcon: true,
	showCloseIcon: false,
	element: null,
	onClick: undefined
}

HeaderScreen.propTypes = {
	title: propTypes.string,
	showBackIcon: propTypes.bool,
	showCloseIcon: propTypes.bool,
	element: propTypes.element,
	onClick: propTypes.func,
}