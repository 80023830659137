import React from "react";
import { Button, Col, Modal, Popconfirm, theme, Tooltip,  } from 'antd'
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Header from "../common/Header";
import { SECONDARY_COLOR } from "../../constants/color";
import TSTable from "../common/TSTable";
import { URL_PLANTATIONS } from "../../constants/urls";
import WorkerModal from "./WorkerModal";
import { DELETE } from "../../frameworks/HttpClient";
import { WORKER_TYPE, WORKER_TYPE_TEXT } from "../../constants/string";
import { useTranslation } from 'react-i18next';


export default function WorkerListModal(props){
  const [openWorkerModal, setOpenWorkerModal] = React.useState();
  const [editId, setEditId] = React.useState(null);
  const [target, setTarget] = React.useState(null);
  const tableRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  const {
		token: { colorWarning, colorError },
	} = theme.useToken();


  const columns = [
    {
      title: t("contractor_name"),
      dataIndex: "full_name",
      searcher: true,
    },
    {
      title: t("type_of_labor"),
      dataIndex: "type",
      render: (value) =>  (WORKER_TYPE_TEXT[value]),
    },
    {
      title: "Action",
      width: 160,
      dataIndex: "id",

      render: (value, original) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Tooltip title={t("actions.edit")}>
              <Button
                type={"primary"}
                style={{ margin: 4, background: colorWarning }}
                icon={<EditOutlined />}
                onClick={() => setTarget(original)}
              />
            </Tooltip>
            
            <Popconfirm
              title={t("confirm_deletion_message")}
              onConfirm={() => handleDeleteWorker(value)}
              okButtonProps={{ danger: true }}
              okText={t("delete")}
              cancelText={t("cancel")}>
                <Button
                  type={"primary"}
                  style={{ margin: 4, background: colorError }}
                  icon={<DeleteOutlined />}/>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch();
    }
  };

  const handleDeleteWorker = async (id) => {
    await DELETE(`${URL_PLANTATIONS.WORKER}${id}`);
    refreshData();
  };


  const onCloseWorkerModal = () => {
    if (target)
      setTarget(null)
    if (openWorkerModal)
      setOpenWorkerModal(false);
  };

  return(
    <Modal
    width={"80%"}
    open={props.open}
    footer={null}
    onCancel={props.onClose}>
      <Header title={t("list_of_contractors")}/>
      <div style={{textAlign: "right", marginTop: 16, marginBottom: 8}}>
        <Button
          type={"primary"}
          icon={<PlusCircleOutlined />}
          style={{
            background: SECONDARY_COLOR,
          }}
          onClick={() => setOpenWorkerModal(true)}>
          {t("add_contractor")}
        </Button>
      </div>

      <Col md={24} sm={24} xs={24}>
        <TSTable
          ref={tableRef}
          columns={columns}
          url={`${URL_PLANTATIONS.WORKER}?plantation=${props.plantId}`}
          rowKey={"id"}
          size={"small"}
          pageSize={10}
          style={{ marginBottom: 20 }}
          hidePagination={true}
        />
      </Col>

      <WorkerModal
        open={(openWorkerModal || target) ? true : false}
        data={target}
        plantId={props.plantId}
        onClose={() => onCloseWorkerModal()}
        onUpdated={() => {
          onCloseWorkerModal();
          refreshData();
        }}/>
    </Modal>
  )
}