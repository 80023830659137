import { Space, Upload, message } from 'antd';
import React from 'react';
import ColorButton from './ColorButton';
import { CloudUploadOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import propTypes from 'prop-types';
import { POST } from '../../frameworks/HttpClient';


const CustomUpload = React.forwardRef((props, ref) => {
  const {
    url,
    target,
    color,
    text,
    value,
    readOnly,
    field,
    manual,
    onSuccess,
    description,
    onRemove,
    showRemoveIcon,
  } = props;

  const [fileList, setFileList] = React.useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const handleUploadFile = async ({ file, ...args }) => {
    if (manual) {
      let newFile = { name: _.get(file, 'name', 'untitled'), file: file, status: 'uploading' }
      setFileList([newFile])
    } else {
      try {
        await POST(`${url}${target}/`, { [field]: file }, true);
        messageApi.success('อัพโหลดสำเร็จ');
        args.onSuccess();
        onSuccess();
      } catch (error) {
        messageApi.error(error.errorMessages);
        args.onError();
      }
    }
  }

  const handleRemoveFile = async() => {
    try {
      await POST(`${url}${target}/`, { [field]: [] });
      messageApi.success('ลบข้อมูลสำเร็จ');
      onRemove();
      setFileList();
    } catch (error) {
      messageApi.error(error.errorMessages);
    }
  }

  const handleUploadHoldingFile = async (id) => {
    try {
      await POST(`${url}${id ? id : target}/`, { [field]: _.get(fileList, '0.file', null) }, true);
      messageApi.success('อัพโหลดสำเร็จ');
    } catch (error) {
      messageApi.error(error.errorMessages);
      setFileList([]);
    }
  }

  React.useImperativeHandle(ref, () => ({
    upload: handleUploadHoldingFile,
    fileList: fileList, 
  }))

  React.useEffect(() => {
    if (value) {
      setFileList([{
        name: value ? value.split('/').at(-1) : 'Untitled',
        url: value,
        status: 'done' 
      }])
    }
  }, [value])

  return (
    <div>
      { contextHolder }
      <Upload listType='picture' customRequest={handleUploadFile} showUploadList={{ showRemoveIcon: showRemoveIcon }} fileList={fileList} onRemove={handleRemoveFile}>
        <Space>
          <ColorButton type='primary' disabled={readOnly} override={color} icon={<CloudUploadOutlined />}>
            {text}
          </ColorButton>
          { description }
        </Space>
      </Upload>
    </div>
  )
})

export default CustomUpload;

CustomUpload.defaultProps = {
  url: '',
  target: '',
  color: '',
  text: 'Upload',
  value: '',
  readOnly: true,
  field: 'attachment',
  manual: true,
  onSuccess: () => null,
  onRemove: () => null,
  showRemoveIcon: true,
  description: '',
};

CustomUpload.propTypes = {
  url: propTypes.string,
  target: propTypes.string,
  color: propTypes.string,
  text: propTypes.string,
  value: propTypes.any,
  readOnly: propTypes.bool,
  field: propTypes.string,
  manual: propTypes.bool,
  onSuccess: propTypes.func,
  onRemove : propTypes.func,
  showRemoveIcon : propTypes.bool,
  description: propTypes.string,
};


// const handleUploadFile = async ({ file, onSuccess, onError }) => {
//   if (isCreate) {
//     console.log('create')
//   } else {
//     try {
//       await POST(
//         `${URL_WEIGHT.WEIGHT_TICKET_DRIVER_ATTACHMENT}${_.get(ticketData, 'driver.id')}/`,
//         { attachment: file }, true
//       );
//       messageApi.success('อัพโหลดสำเร็จ');
//       fetchTicket(id);
//       onSuccess();
//     } catch (error) {
//       messageApi.error(error.errorMessages);
//       onError();
//     }
//   }
// }

