
import React from 'react';
import { Col, Form, Row, Select, Spin, message, } from 'antd';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, LayersControl, Polygon, LayerGroup ,Popup} from "react-leaflet";
import { URL_DASHBOARD } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';
import { DEFAULT_LOCATION, FARMER_STATUS, PLANTATION_STATUS } from '../../constants/string';
import { useTranslation } from 'react-i18next';

export default function DashBoardMapOffice() {
  const [fetching, setFetching] = React.useState(false);
  const [dataMap, setDataMap] = React.useState([]);
  const [targetStatus, setTargetStatus] = React.useState("");
  const { t, i18n } = useTranslation();

  const iconMarker = new L.Icon({
    iconUrl: require('../../assets/marker-1.png'),
    iconRetinaUrl: require('../../assets/marker-1.png'),
    iconAnchor: [13, 40],
    iconSize: [25, 40],
  });

  const iconMarkerNotApproved = new L.Icon({
    iconUrl: require('../../assets/search-marker.png'),
    iconRetinaUrl: require('../../assets/search-marker.png'),
    iconAnchor: [13, 40],
    iconSize: [25, 40],
  });

  const fetchDataMap = async () => {
    setFetching(true);
    let params = {}
    if (targetStatus)
      params["status"] = targetStatus ? targetStatus : "";

    try {
      const response = await GET(URL_DASHBOARD.OVERALL_PLANTATION, params);
      setDataMap(response.data.results)
    } catch (error) {
      message.error(error.errorMessage);
    } finally {
      setFetching(false);
    }
  }

  React.useEffect(() => {
    fetchDataMap();
  },[targetStatus]);

  return (
    <Row gutter={16} >
      <Col md={8} sm={24} xs={24}>
        <Form>
          <Form.Item label={t("status")}>
            <Select
              autoComplete="off"
              filterOption={false}
              value={targetStatus}
              onChange={(value) => setTargetStatus(value)}
              options={[
                {key: "all", value: "", label: t("status_map.all")},
                {key: PLANTATION_STATUS.DRAFT, value: PLANTATION_STATUS.DRAFT, label: t("status_map.draft")},
                {key: PLANTATION_STATUS.CHECKING, value: PLANTATION_STATUS.CHECKING, label: t("status_map.checking")},
                {key: PLANTATION_STATUS.WAITING, value: PLANTATION_STATUS.WAITING, label: t("status_map.waiting")},
                {key: PLANTATION_STATUS.APPROVED, value: PLANTATION_STATUS.APPROVED, label: t("status_map.approved")},
                {key: PLANTATION_STATUS.REJECTED, value: PLANTATION_STATUS.REJECTED, label: t("status_map.rejected")},
              ]}/>
          </Form.Item>
        </Form>
      </Col>

      <Col md={24} sm={24} xs={24} >
        <Spin tip="Loading" spinning={fetching} size="large">
        <div style={{height: "800px", width:"100%"}}>
          <MapContainer zoom={8} maxZoom={20} minZoom={5} center={DEFAULT_LOCATION} attributionControl={false} >
            <LayersControl>
              <LayersControl.BaseLayer checked name="Google Map">
                <LayerGroup>
                  <TileLayer
                    maxZoom={20}
                    attribution="Google Maps"
                    url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                  />
                  { dataMap && dataMap.map((item)=> {
                    let data = []
                    if (item.polygons && item.polygons.total) {
                      data = item.polygons.total.map((polygon) => {
                        return polygon.map((obj) => [obj.latitude,obj.longitude])
                      })
                    }
                    return(
                      <LayerGroup>
                        <Polygon 
                        positions={data} 
                        value={item.id}
                        >
                        </Polygon>
                      </LayerGroup>
                    )
                  })}
                </LayerGroup>
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Google Map Satellite">
                <LayerGroup>
                  <TileLayer
                    maxZoom={20}
                    attribution="Google Maps Satellite"
                    url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                  />
                  { dataMap && dataMap.map((item)=> {
                    let data = []
                    if (item.polygons && item.polygons.total) {
                      data = item.polygons.total.map((polygon) => {
                        return polygon.map((obj) => [obj.latitude,obj.longitude])
                      })
                    }
                    return(
                      <LayerGroup>
                        <Polygon 
                        positions={data} 
                        value={item.id}
                        >
                        </Polygon>
                      </LayerGroup>
                    )
                  })}
                  <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"/>
                </LayerGroup>
              </LayersControl.BaseLayer>
            </LayersControl>

            {dataMap.map((item) => {
              return item.location ? (
                <Marker position={[item.location.latitude,item.location.longitude]} icon = {item.code === "" ? iconMarkerNotApproved : iconMarker }>
                  <Popup>{[item.code]}<br/>{item.sub_district_detail}</Popup>
                </Marker>
              ) : <></>
            })}
          </MapContainer>
        </div>   
        </Spin>        
      </Col>
    </Row>  
  )
}