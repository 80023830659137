import React from 'react';
import propTypes from 'prop-types';
import { Button, Card, ConfigProvider, Typography, theme } from 'antd';
import { formatComma } from '../../frameworks/Util';
import { RedoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';



const ScaleWindow = React.forwardRef((props, ref) => {
  const {
    style,
  } = props;

  const { token: { colorPrimary } } = theme.useToken();
  const [weight, setWeight] = React.useState(0);
  const idx = React.useRef();

  const refresh = async () => {
    // TODO reconnect to weight module
    setWeight(0);
    clearInterval(idx.current);
    idx.current = setInterval(() => {
      setWeight(Math.floor(Math.random() * 5000.00) + 1)
    }, 1000);
  }
  const { t, i18n } = useTranslation();


  React.useImperativeHandle(ref, () => ({ weight }));

  React.useEffect(() => {
    return () => clearInterval(idx.current)
  }, [])

  return (
    <div style={style}>
      <Card
        title={<Typography.Title style={{ margin: 0, color: 'white' }} level={4}>{t("weight")}</Typography.Title>}
        extra={<Button type='text' style={{ color: 'white' }} icon={<RedoOutlined />} onClick={refresh}/>}
        bordered={false}
        headStyle={{ background: colorPrimary, textAlign: 'center' }}
        bodyStyle={{ background: '#212121', textAlign: 'center' }}>
        <Typography.Title style={{ margin: 0, color: '#00FF00' }}>
          {formatComma(weight)}
        </Typography.Title>
      </Card>
    </div>
  )
});

export default ScaleWindow;

ScaleWindow.defaultProps = {
  style: {}
};

ScaleWindow.propTypes = {
  style: propTypes.object,
};