import React from 'react';
import { Alert, Button, theme, Tooltip, Card, Form, Row, Col, Input, Radio, Checkbox, Select } from 'antd';
import { PATH } from '../../routes/CustomRoutes';
import { URL_PLANTATIONS } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';
import { useTranslation } from 'react-i18next';


const PlantationLatexView = React.forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [treeTypeOptions, setTreeTypeOptions] = React.useState([]);
  const [pestTypeOptions, setPestTypeOptions] = React.useState([]);

  const isUsingChemicalFertilizer = Form.useWatch('is_using_chemical_fertilizer', form)
  const isUsingChemicalHerbicide = Form.useWatch('is_using_chemical_herbicide', form)
  const { t, i18n } = useTranslation();


  React.useImperativeHandle(ref, () => ({
    getData : () => {return handleRegister()}
  }))

  const handleRegister = async () => {
    setErrorMessages(null);

    try{
      let data = await form.validateFields();
      return data
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { 
      form.resetFields();
    }
  }

  const fetchTreeTypeOptions = async () => {
    try {
      const response = await GET(URL_PLANTATIONS.RUBBER_TREE_TYPE);
      setTreeTypeOptions(response.data.results.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  const fetchPestTypeOptions = async () => {
    try {
      const response = await GET(URL_PLANTATIONS.PEST_TYPE);
      setPestTypeOptions(response.data.results.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

    React.useEffect(() => {
      if (props.data) {
        form.setFieldsValue(props.data);
      }

    },[props.data]);

    React.useEffect(() => {
      // Fetch Options when open app
      fetchTreeTypeOptions();
      fetchPestTypeOptions();
    }, [])

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
      <Row gutter={16}>

      <Col md={12} sm={24} xs={24}>
        <Form.Item name={"tree_types"} label={t("dashboard.rubber_tree_varieties")}>
          <Select options={treeTypeOptions} mode={"multiple"}/>
        </Form.Item>
      </Col>

      <Col md={6} sm={24} xs={24}>
      <Form.Item
          name={"tapping_duration"}
          label={t("consecutive_tapping_interval")}>
          <Input autoComplete="off" style={{ maxWidth: 290 }}/>
      </Form.Item>

      </Col>

      <Col md={6} sm={24} xs={24}>
      <Form.Item
          name={"tapping_wait"}
          label={t("rest_period_between_tappings")}>
          <Input autoComplete="off" style={{ maxWidth: 290 }}/>
      </Form.Item>

      </Col>
      
      <Col md={24} sm={24} xs={24}>
      <Form.Item  
          name={"tapping_type"}
          label={t("tapping_system")}>
            <Radio.Group>
              <Radio value={'1/2'}>{t("half_tapping")}</Radio>
              <Radio value={'1/3'}>{t("1_3_tapping")}</Radio>
              <Radio value={'1/4'}>{t("1_4_tapping")}</Radio>
            </Radio.Group>
      </Form.Item>
      </Col>

      <Col md={24} sm={24} xs={24}>
      <Form.Item  
          name={"product_type"}
          label={t("products_sold")}>
            <Radio.Group>
              <Radio value={'latex'}>{t("latex")}</Radio>
              <Radio value={'lump'}>{t("cup_lump_rubber")}</Radio>
              <Radio value={'raw_sheet'}>{t("raw_rubber_sheets")}</Radio>
            </Radio.Group>
      </Form.Item>
      </Col>

      <Col md={24} sm={24} xs={24}>
      <Form.Item
          name={"is_using_chemical_fertilizer"}
          label={t("use_of_chemical_fertilizer")}>
            <Radio.Group>
              <Radio value={false}>{t("do_not_use")}</Radio>
              <Radio value={true}>{t("use")}</Radio>
            </Radio.Group>
      </Form.Item>
      </Col>

      {isUsingChemicalFertilizer && 
        <>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              name={"fertilizer_formular"}
              label={t("formula")}>
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              name={"fertilizer_usage_quantity"}
              label={t("quantity_used_per_round_per_time")}>
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </>
      }

      <Col md={24} sm={24} xs={24}>
        <Form.Item
          name={"is_using_acid"}
          label={t("acid_tapped_latex")}>
          <Radio.Group>
            <Radio value={false}>{t("do_not_use")}</Radio>
            <Radio value={true}>{t("use")}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      

      <Col md={24} sm={24} xs={24}>
      <Form.Item
        name={"is_using_chemical_herbicide"}
        label={t("weed_control")}>
          <Radio.Group>
            <Radio value={false}>{t("do_not_use_chemicals")}</Radio>
            <Radio value={true}>{t("use_chemicals")}</Radio>
          </Radio.Group>
      </Form.Item>
      </Col>

      {isUsingChemicalHerbicide &&
      <>
        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name={"chemical_herbicide"}
            label={t("chemicals_used_for_weed_control")}>
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name={"herbicide_usage_frequency"}
            label={t("frequency_of_chemical_weed_control")}>
            <Input autoComplete="off" />
          </Form.Item>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name={"herbicide_usage_quantity"}
            label={t("quantity_of_herbicide_used")}>
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </>
      }
      </Row>
      </Form>
    </Card>
    )
  }
)

PlantationLatexView.defaultProps = {}

PlantationLatexView.propTypes = {}

export default React.memo(PlantationLatexView);