import { Select, Space } from 'antd';
import React from 'react';
import propTypes from 'prop-types';
import useDropdownItem from '../../hooks/useDropdownItem';
import * as _ from 'lodash';
import { GET } from '../../frameworks/HttpClient';


const CustomSearchSelect = React.forwardRef((props, ref) => {
  const {
    // default props for form item child. 
    // do not use as different approach at any cost.
    value,
    onChange,

    // fields props
    style,
    allowClear,
    placeholder,
    readOnly,
    extra,

    // dropdown props
    url,
    label,
    labelRenderer,
    params,
    filterOption,

    // state control props
    handleChange
  } = props;

  const dropdown = useDropdownItem({
    url: url, manual: true, params: params, pagination: true,
    label: label, label_renderer: labelRenderer
  })
  const [loading, setLoading] = React.useState(false);

  const debounceSearch = React.useCallback(
    _.debounce((search) => { dropdown.fetchOptions({ search }) }, 800),
    []
  );

  const initialItem = async (value) => {
    const found = dropdown.options.find(option => value === option.value)
    if (found) {
      handleChange(found);
      return;
    }

    setLoading(true);
    try {
      const response = await GET(`${url}${value}`);
      dropdown.addOption(response.data)
      handleChange(response.data)
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (readOnly == null || readOnly == false || params != null) {
      dropdown.fetchOptions()
    }
  }, [readOnly, params])

  React.useImperativeHandle(ref, () => ({
    initial: initialItem
  }));

  React.useEffect(() => {
    if (value) {
      initialItem(value);
    }
  }, [value]);

  return (
    <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
      <Space.Compact style={{ width: '100%' }}>
        <Select
          showSearch={!readOnly}
          style={style}
          allowClear={allowClear}
          disabled={loading || readOnly}
          loading={loading || dropdown.loading}
          options={dropdown.options}
          open={readOnly ? false : undefined}
          filterOption={filterOption}
          optionFilterProp={label}
          placeholder={placeholder}
          value={value}
          onSearch={(search) => {
            if (readOnly) { return }
            debounceSearch(search)
          }}
          onSelect={(v, __) => onChange(v)}
          onClear={() => onChange()}
          onChange={(__, option) => {
            if (readOnly) { return }
            handleChange(option)
          }}/>
        { extra }
      </Space.Compact>
    </div>
  )
})

export default CustomSearchSelect

CustomSearchSelect.defaultProps = {
  value: null,
  onChange: () => null,

  style: {},
  readOnly: null,
  allowClear: false,
  placeholder: '',
  extra: null,

  url: '',
  label: '',
  labelRenderer: null,
  params: null,
  filterOption: true,

  handleChange: () => null,
};

CustomSearchSelect.propTypes = {
  value: propTypes.any,
  onChange: propTypes.func,

  style: propTypes.object,
  readOnly: propTypes.bool,
  allowClear: propTypes.bool,
  placeholder: propTypes.string,
  extra: propTypes.element,

  url: propTypes.string,
  label: propTypes.string,
  labelRenderer: propTypes.func,
  params: propTypes.object,
  filterOption: propTypes.bool,

  handleChange: propTypes.func,
};
