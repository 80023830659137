import React from "react"
import propTypes from "prop-types";
import { Spin, Alert, Empty, Modal, Button, Avatar, Pagination } from "antd"
import { useNavigate } from 'react-router-dom';
import { GET } from "../../frameworks/HttpClient";
import { URL_CUSTOMER } from "../../constants/urls";
import * as _ from 'lodash';
import CustomerFilterHeader from "../collectingcenter/CustomerFilterHeader";
import Header from "../common/Header";
import { List } from "antd-mobile";
import farmerIcon from "../../assets/farmer.png"
import ccIcon from "../../assets/cc.png"
import customerIcon from "../../assets/customer.png"
import { USER_TYPE } from "../../constants/string";
import { useTranslation } from 'react-i18next';




export default function CustomerListModal(props) {
  const navigate = useNavigate();

  const headerRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [searchParams, setSearchParams] = React.useState({});
  const [datas, setDatas] = React.useState([]);
  // Handle pagination
  const [total, setTotal] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { t, i18n } = useTranslation();


  const fetchData = async () => {
    setLoading(true);
    const params = {
      ...searchParams, 
      direction: props.direction,
      page_size: 10, 
      page: currentPage
    }
    try {
      const response = await GET(URL_CUSTOMER.CUSTOMER, params);
      setDatas(_.get(response, 'data.results', []))
      setTotal(response.data.total)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (props.open)
      fetchData();
  }, [props.open, currentPage, searchParams])

  return (
    <Modal
      open={props.open}
      onCancel={props.onClose}
      width={760}
      footer={[ <Button onClick={props.onClose}>{t("cancel")}</Button> ]}>

      <Header title={t("customer")}/>

      <CustomerFilterHeader 
        style={{marginTop: 16}}
        ref={headerRef} 
        loading={loading} 
        readOnly={true} 
        refresh={(search) => {
          setSearchParams(search)
          setCurrentPage(1)
        }}/>
        
      { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/>}
      <div style={{ marginTop: '1rem' }}>
        { !datas.length && <Empty description={t("none")}/> }
        <Spin spinning={loading}>
          <List>
            {datas.map((item) => (
              <List.Item 
                key={item.id}
                prefix={<Avatar src={(item.type === USER_TYPE.FARMER) 
                  ? farmerIcon 
                  : (item.type === USER_TYPE.COLLECTING_CENTER) 
                  ? ccIcon 
                  : customerIcon}/>}
                onClick={() => props.onSelected(item.id)}>
                  {item.full_name ? item.full_name : ''}
              </List.Item>
            ))}
          </List>
          <Pagination 
            style={{textAlign: "center", marginTop: 16}}
            pageSize={10}
            total={total}
            current={currentPage}
            onChange={(page) => setCurrentPage(page)}/>
        </Spin>
      </div>
    </Modal>
  )
};

CustomerListModal.defaultProps = {
  open: false,
  data: null,
  direction: null,
  onSelected: (id) => {},
  onClose: () => {},
}

CustomerListModal.propTypes = {
  open: propTypes.bool,
  data: propTypes.object,
  direction: propTypes.string,
  onSelected: propTypes.func,
  onClose: propTypes.func,
}