import React from 'react';
import { Card, Form, Row, Col, Input, Radio, Checkbox, Button, List } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { DELETE } from '../../frameworks/HttpClient';
import { URL_PLANTATIONS } from '../../constants/urls';
import { useTranslation } from 'react-i18next';


const PlantationAreaView = React.forwardRef((props,ref) => {
  const [form] = Form.useForm();
  const [woodType, setWoodType] = React.useState([]);
  const [animalType, setAnimalType] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [river, setRiver] = React.useState(false);
  const [preservedArea, setPreservedArea] = React.useState(false);
  const [noHunting,setNoHunting] = React.useState(false);
  const [school, setSchool] = React.useState(false);
  const [hospital, setHospital] = React.useState(false);
  const [temple, setTemple] = React.useState(false);
  const [trigger, setTrigger] = React.useState(false);
  const { t, i18n } = useTranslation();


  React.useImperativeHandle(ref, () => ({
    getData : () => {return handleRegister()}
  }))

  const handleDeleteAnimal = async(id) => {
    setAnimalType(animalType.filter((animal) => animal.id !== id ))
    try { 
      await DELETE(`${URL_PLANTATIONS.ANIMAL_TYPE}${id}/`)
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  const handleDeleteWood = async(id) => {    
    setWoodType(woodType.filter((wood) => wood.id !== id ))
    try { 
      await DELETE(`${URL_PLANTATIONS.WOOD_TREE}${id}/`)
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  const onRadioChanged = (e) => {
    form.setFieldValue(e.target.name, e.target.value);
    setTrigger(!trigger);
  }

  const handleRegister = async () => {
    setErrorMessages(null)

    try {
      let data = await form.validateFields();
      // พื้นที่ติดสวนยาง
      data['is_next_to_river'] = river;
      data['is_next_to_preserved_area'] = preservedArea;
      data['is_next_to_no_hunting_area'] = noHunting;
      // สถานที่ติดสวนยาง
      data['is_next_to_school'] = school;
      data['is_next_to_hospital'] = hospital;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
      data['is_next_to_temple'] = temple;
      return data;
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally { 
      form.resetFields();
    }
  }

  React.useEffect(() => {
    if(props.data && props.wood && props.animal){
      let data = props.data
      form.setFieldsValue(data)
      // พื้นที่ติดสวนยาง
      setRiver((data.is_next_to_river != null) ? data.is_next_to_river : false)
      setPreservedArea((data.is_next_to_preserved_area != null) ? data.is_next_to_preserved_area : false)
      setNoHunting((data.is_next_to_no_hunting_area != null) ? data.is_next_to_no_hunting_area : false)
      // สถานที่ติดสวนยาง
      setSchool((data.is_next_to_school != null) ? data.is_next_to_school : false)
      setHospital((data.is_next_to_hospital != null) ? data.is_next_to_hospital : false)
      setTemple((data.is_next_to_temple != null) ? data.is_next_to_temple : false)
    }
  },[props.data])

  React.useEffect(() => {
    if (form) {
      setTrigger(!trigger)
    }
  }, [form])

  React.useEffect(() => {
    if(props.wood)
      setWoodType(props.wood)
  },[props.wood])

  React.useEffect(() => {
    if(props.animal)
      setAnimalType(props.animal)
  },[props.animal])

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
      <Row gutter={16}>

      <Col md={24} sm={24} xs={24}>
        <Form.Item name={"area_type"} label={t("area_characteristics")}>
          <Radio.Group name={"area_type"} onChange={onRadioChanged}>
            <Radio value={"plain"}>{t("flat")}</Radio>
            <Radio value={"lowland"}>{t("lowland")}</Radio>
            <Radio value={"slope"}>{t("sloping_over_35_degrees")}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Col md={24} sm={24} xs={24}>
        <Form.Item label={t("rubber_plantation_adjacent_to_the_area")}>
          <Checkbox checked={river} onChange={(e) => setRiver(e.target.checked)}>{t("river_Canal_Stream")}</Checkbox>
          <Checkbox checked={preservedArea} onChange={(e) => setPreservedArea(e.target.checked)}>{t("reservedforest_Conservationforest")}</Checkbox>
          <Checkbox checked={noHunting} onChange={(e) => setNoHunting(e.target.checked)}>{t("wildlife_sanctuary")}</Checkbox>
        </Form.Item>
      </Col>

      <Col md={24} sm={24} xs={24}>
        <Form.Item label={t("rubber_plantation_adjacent_to_the_location")}>
          <Checkbox checked={school} onChange={(e) => setSchool(e.target.checked)}>{t("school")}</Checkbox>
          <Checkbox checked={hospital} onChange={(e) => setHospital(e.target.checked)}>{t("hospital")}</Checkbox>
          <Checkbox checked={temple} onChange={(e) => setTemple(e.target.checked)}>{t("temple")}</Checkbox>
        </Form.Item>
      </Col>

      <Col md={24} sm={24} xs={24}>
        <Form.Item name={"has_monument"} label={t("areawithancestralgravespagodasorhistoricalsitesrespectedbythecommunity")}>
          <Radio.Group name={"has_monument"} onChange={onRadioChanged}>
            <Radio value={false}>{t("do_not_have")}</Radio>
            <Radio value={true}>{t("have")}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      {form.getFieldValue("has_monument") && (
        <Col md={8} sm={24} xs={24}>
          <Form.Item name={"monument"}>
            <Input placeholder={t("please_specify")}/>
          </Form.Item>
        </Col>
      )}

      <Col md={24} sm={24} xs={24}>
        <Form.Item name={"has_tribe"} label={t("are_there_indigenous_people_in_the_area")}>
          <Radio.Group name={"has_tribe"} onChange={onRadioChanged}>
            <Radio value={false}>{t("do_not_have")}</Radio>
            <Radio value={true}>{t("have")}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      
      {form.getFieldValue("has_tribe") && (
      <Col md={8} sm={24} xs={24}>
        <Form.Item name={"tribe"}>
          <Input autoComplete='off' placeholder={t("please_specify")}/>
        </Form.Item>
        </Col>
      )}

      {/* <Col md={24} sm={24} xs={24}>
        <Form.Item name={"has_other_tree"} label={"ชนิดไม้ยืนต้นนอกเหนือจากต้นยางพารา ในสวนยาง "}>
          <Radio.Group name={"has_other_tree"} onChange={onRadioChanged}>
            <Radio value={false}>ไม่มี</Radio>
            <Radio value={true}>มี</Radio>
          </Radio.Group>
        </Form.Item>
      </Col> */}

      <Col md={24} sm={24} xs={24}>
          {form.getFieldValue("has_other_tree") && (
              <div>
                
                {woodType.length > 0 && 
                <Col md={6} sm={24} xs={24}>
                <List
                  size="small"
                  locale={{ emptyText:(<></>)}}
                  bordered={true}
                  dataSource={woodType}
                  renderItem={(item) => (
                    <List.Item>
                      <Col>
                      {`${item.name}   ${item.number_of_tree} ${t("trees")}`}
                      </Col>
                      <Button onClick={() => { handleDeleteWood(item.id) }} icon={<CloseOutlined/>} danger></Button>
                    </List.Item>
                      
                  )}
                />
                </Col>}
                
                <Form.List name="wood_type">
                  {(fields, { add, remove }) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Col md={6} sm={24} xs={24}>
                        <Button onClick={() => add()} type={"primary"} style={{ marginBottom : 16, marginTop : 16}}>
                          {t("Add trees")}
                        </Button>
                      </Col>
                      {fields.map((field) => (
                      <div>
                        <Row>
                          <Col md={6} sm={24} xs={24}>
                            <Form.Item name={[field.name , 'name']}>
                              <Input placeholder={t("please_specify")}/>
                            </Form.Item>
                          </Col>

                          <Col md={6} sm={24} xs={24}>
                            <Form.Item name={[field.name , 'number_of_tree']}>
                              <Input style={{ marginLeft : 10 }} type='number' placeholder={t("quantity_trees")}/>
                            </Form.Item>
                          </Col>

                          <Col md={4} sm={24} xs={24}>
                            <Button onClick={() => { remove(field.name) }} style = {{ marginLeft : 20 }} icon={<CloseOutlined/>} danger></Button>
                          </Col>
                        </Row>
                      </div>
                      ))}    
                    </div>
                  )}
              </Form.List>
            </div>
          )}

      </Col>

      <Col md={24} sm={24} xs={24}>
        <Form.Item name={"has_animal_habitat"} label={t("types_plantations")}>
          <Radio.Group name={"has_animal_habitat"} onChange={onRadioChanged}>
            <Radio value={false}>{t("do_not_have")}</Radio>
            <Radio value={true}>{t("have")}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
        <Col md={24} sm={24} xs={24}>
          {form.getFieldValue("has_animal_habitat") && (
              <div>
                {animalType.length > 0 && 
                  <Col md={6} sm={24} xs={24}>
                    <List
                      size="small"
                      locale={{ emptyText:<></>}}
                      bordered={true}
                      dataSource={animalType}
                      renderItem={(item) => (
                        <List.Item>
                          <Col>
                          {`${item.name}`}
                          </Col>
                          <Button onClick={() => { handleDeleteAnimal(item.id) }} icon={<CloseOutlined/>} danger></Button>
                        </List.Item>
                          
                      )}
                    />
                  </Col>
                }

                <Form.List name="animal_type" >
                  {(fields, { add, remove }) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Col md={6} sm={24} xs={24}>
                        <Button onClick={() => add()} type={"primary"} style={{ marginBottom : 16, marginTop : 16}}>
                          {t("add_animals")}
                        </Button>
                      </Col>
                      {fields.map((field) => (
                      <div>
                        <Row>
                          <Col md={6} sm={24} xs={24}>
                            <Form.Item name={[field.name , 'name']}>
                              <Input placeholder={t("please_specify")}/>
                            </Form.Item>
                          </Col>
                          <Col md={4} sm={24} xs={24}>
                            <Button onClick={() => {remove(field.name)}} style = {{ marginLeft : 10 }} icon={<CloseOutlined/>} danger></Button>
                          </Col>
                        </Row>
                      </div>
                      ))}    
                    </div>
                  )}
              </Form.List>
            </div>
          )}
        </Col>
      </Row>
      </Form>
    </Card>
    )
  }
)


PlantationAreaView.defaultProps = {}

PlantationAreaView.propTypes = {}

export default React.memo(PlantationAreaView);
