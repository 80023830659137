import React from "react";
import propTypes from "prop-types";
import { Modal, Form, Col, Input, Row, theme, Alert, Button, Image, Select } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import ImageEditorModal from '../common/ImageEditorModal';
import Header from "../common/Header";
import { POST, PATCH } from "../../frameworks/HttpClient";
import { URL_CUSTOMER } from "../../constants/urls";
import ImgCrop from 'antd-img-crop';
import userImg from "../../assets/user.png"
import { useTranslation } from 'react-i18next';


export default function DriverModal(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [imageData, setImageData] = React.useState(null);
  const [uploadFile, setUploadFile] = React.useState(null)
  const uploadRef = React.useRef(null);
  const { t, i18n } = useTranslation();


  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();

  const handleOK = async() => {
    setLoading(true);
    let response = null;
    try {
      const data = await form.validateFields();
      if (imageData != null && !imageData.includes("http")) {
        data['card'] = imageData;
      }
      
      if (props.target) {
        response = await PATCH(`${URL_CUSTOMER.DRIVER}${props.target.id}/`, data);
      } else {
        response = await POST(URL_CUSTOMER.DRIVER, data);
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }

    if (response)
      props.onUpdated();
  }

  React.useEffect(() => {
    if (props.open) {
      const data = props.target
      if (data) {
        form.setFieldsValue(data);
        setImageData(data.card)
      }
    } else {
      form.resetFields();
      setImageData(null)
      setErrorMessages(null);
    }
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      width={760}
      confirmLoading={loading}
      okText={props.target ? t("edit_Data") : t("add_Data") }
      okButtonProps={{ style: {background: props.target ? colorWarning : colorPrimary}}}
      onOk={handleOK}
      cancelText={t("cancel")}
      onCancel={props.onClose}>
      
      <Header title={t("driver")}/>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: "12px", textAlign: "left"}}/>
      }

      <Form
        form={form}
        style={{marginTop: 16}}
        layout="vertical"
        autoComplete="off">
        <Row gutter={16}>
          <Col md={24} sm={24} xs={24} style={{marginBottom: 6}}>
            <div style={{ textAlign: "center" }} className='section-wrapper'>
              <Image
                src={imageData ? imageData : (props.data && props.data.card) ? props.data.card : userImg}
                height={120} 
                width={"auto"} />
              <div>
                <Button 
                  style={{marginTop: 8}}
                  icon={<UploadOutlined />} 
                  onClick={() => {
                    uploadRef.current.click()
                  }}>
                    {t("upload_image")}
                </Button>
              </div>
            </div>
          </Col>

          <Col md={6} sm={24} xs={24}>
            <Form.Item
              name={"title"}
              label={t("farmers.title")}
              rules={[{ required: true }]}>
                <Select
                  style={{ width: '100%' }}
                  options={[
                    { value: 'นาย', label: t("prefix.mr") },
                    { value: 'นาง', label: t("prefix.mrs") },
                    { value: 'นางสาว', label: t("prefix.ms")},
                    { value: 'เด็กชาย', label: t("prefix.boy") },
                    { value: 'เด็กหญิง', label: t("prefix.girl") },
                  ]}
                />
            </Form.Item>
          </Col>

          <Col md={9} sm={24} xs={24}>
            <Form.Item
              name={"first_name"}
              label={t("farmers.first_name")}
              rules={[{ required: true }]}>
                <Input autoComplete="off" />
            </Form.Item>
          </Col>

          <Col md={9} sm={24} xs={24}>
            <Form.Item
              name={"last_name"}
              label={t("farmers.last_name")}
              rules={[{ required: true }]}>
                <Input autoComplete="off" />
            </Form.Item>
          </Col>

          <Col md={10} sm={24} xs={24}>
            <Form.Item
              name={"phone"}
              label={t("farmers.phone"," :")}
              rules={[{ required: true }]}>
                <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <ImageEditorModal
          open={uploadFile ? true : false}
          image={uploadFile}
          onUpdated={(image) => {
            setImageData(image)
            setUploadFile(null)
          }}
          onClose={() => setUploadFile(null)}/>

      <ImgCrop aspect={16 / 9} aspectSlider>
          <input id="imgSelect"
            type="file"
            accept="image/*"
            ref={uploadRef}
            style={{ display: 'none' }}
            onChange={async (evt) => {
              if (evt.target.files.length > 0) {
                setUploadFile(URL.createObjectURL(evt.target.files[0]))
                evt.target.value = null  // clear reference to enable select same file
              }
            }}/>
      </ImgCrop>
        
    </Modal>
  )
}

DriverModal.defaultProps = {
    open: false,
    data: null,
    onUpdated: () => {},
    onClose: () => {},
  }
  
  DriverModal.propTypes = {
    open: propTypes.bool,
    data: propTypes.object,
    onUpdated: propTypes.func,
    onClose: propTypes.func,
  }