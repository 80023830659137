import React from 'react';
import { Tag, Tabs, theme } from 'antd';
import DashBoardCCView from '../components/dashboard/DashBoardCCView';
import DashBoardMapCC from '../components/dashboard/DashBoardMapCC';
import { useUserContext } from '../hooks/UserSettingProvider';

export default function DashboardTabCcView() {
  const { user } = useUserContext();
  const [activeKeyCC, setActiveKeyCC] = React.useState("1");
  const onTabsChangeCC = (key) => setActiveKeyCC(key);

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const TabCC = [
    {
      key: "1", 
      label: <span>Dashboard</span>, 
      children: <DashBoardCCView id={user.cc ? user.cc : null }/>
    },
    {
      key: "2", 
      label: <span>Map</span>,
      children: <DashBoardMapCC id={user.cc ? user.cc : null }/> 
    },
]

  React.useEffect(() => {
    console.log(user)
  },[])
  return (
    <Tabs
      activeKey={activeKeyCC}
      onChange={onTabsChangeCC}
      size='large'
      items={TabCC}
      tabBarExtraContent={
        <Tag 
          color={colorPrimary} 
          style={{fontSize: 16, fontWeight: "bold", padding:8,}}>
            {user ? user.display_name : "Unknown"}
        </Tag>
      }/> 
  )
}
