import { Row, Col, Button, Popconfirm, theme, message, Space, Input, Tooltip } from "antd";
import { List } from 'antd-mobile'
import React, { useRef } from "react";
import { PlusSquareOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { SECONDARY_COLOR } from "../../constants/color";
import CarRegisterModal from "./CarRegisterModal";
import TSTable from "../common/TSTable";
import { URL_CUSTOMER } from "../../constants/urls";
import { DELETE, GET } from "../../frameworks/HttpClient";
import _ from 'lodash';
import ColorButton from "../common/ColorButton";
import { useWindowDimensionContext } from "../../hooks/WindowDimensionProvider";
import { HUMAN_DATETIME_FORMAT, DATETIME_FORMAT } from "../../constants/string";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';


const ProductListView = React.forwardRef((props, ref) => {
  const tableRef = useRef();
  const [openCarModal, setOpenCarModal] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [target, setTarget] = React.useState(null);
  const [data, setData] = React.useState(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const { t } = useTranslation();

  const {
    token: { colorWarning }
  } = theme.useToken();

  const fetchData = async() =>{
    const response = await GET(`${URL_CUSTOMER.VEHICLE}?search=${search}&page_size=999`)
    setData(response.data.results)
  }

  const refreshData = () => {
    if(isOnMobile){
      fetchData();
    }else if(tableRef && tableRef.current){
      tableRef.current.fetchWithoutResetPage();
    }
  }

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("vehicle_list"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        // landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("vehicle_list"),
        subtitle: `${t("data_as_of")}  ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("vehicle_list")}  ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_CUSTOMER.VEHICLE}${id}/`)
      message.success(t("data_deleted_successfully"))
      if(tableRef){
        tableRef.current.fetch();
      }
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  const columns = [
    {
      title: t("vehicle_type"),
      dataIndex: "type",
      render: (value) => t(value)
    },
    {
      title: t("license_plate"),
      dataIndex: 'license_plate_number',
      searcher: true,
    },
    {
      title: t("province_registration"),
      dataIndex: 'license_plate_province_name',
      searcher: true,
    },
    {
      title: t("vehicle_owner"),
      dataIndex: 'owner_full_name',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: 100,
      skipReport: true, 
      render: (value, original) =>  (
        <div style={{textAlign: "center"}}>
          <Space direction='horizontal'>
            <Tooltip title={t("actions.edit")}>
            <ColorButton 
              type='primary'
              override={colorWarning}
              icon={<EditOutlined />}
              onClick={() => setTarget(original)}/>
              </Tooltip>
              <Tooltip title={t("actions.delete_data")}>
            <Popconfirm
              title= {t("Confirm-deletion-of-the-product?")}
              onConfirm={() => handleDelete(value)}
              okText={t("delete")}
              okButtonProps={{ danger: true }}
              cancelText={t("cancel")}
              placement='topRight'
            >
              <Button 
                type='primary'
                danger
                icon={<DeleteOutlined />}/>
            </Popconfirm>
          </Tooltip>
          </Space>
        </div>
      )
    },
  ]

  React.useEffect(() => {
    if(isOnMobile){
      fetchData();
    }
  },[])
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={12} sm={16} xs={24}>
          <Space.Compact style={{ width: '100%' }} size='middle'>
            <Input placeholder={t("search_vehicle_owner")} onPressEnter={() => refreshData()} onChange={e => setSearch(_.get(e, 'target.value', ''))}/>
            <Button icon={<SearchOutlined/>} onClick={() => refreshData()}>{t("search")}</Button>
          </Space.Compact>
        </Col>

        <Col md={12} sm={8} xs={24} style={{ textAlign: 'end', float : 'right'}}>
          <ColorButton
            type='primary'
            style={{ width :(isOnMobile ? '100%' : 'auto'), height : isOnMobile ? 45 : 'auto', marginBottom : (isOnMobile ? 10 : ''), float : "right", marginLeft : 10}}
            onClick={() => setOpenCarModal(true)}
            icon={<PlusSquareOutlined />}
            override={SECONDARY_COLOR}>
            {t("add_vehicle")}
          </ColorButton>
          { !isOnMobile &&
            <ColorButton
              icon={<FileExcelOutlined />}
              style={{float: "right", marginLeft : 10}}
              onClick={() => exportTable("excel")}>
              Excel
            </ColorButton>
          }
          { !isOnMobile && 
            <ColorButton
              icon={<FilePdfOutlined />}
              style={{float: "right", marginLeft : 10}}
              onClick={() => exportTable("pdf")}>
              PDF
            </ColorButton>
          }
        </Col>
      </Row>

      {isOnMobile ? (
        <List>
        {data && data.map(item => (
          <List.Item
            key={item.id}
            description={`${item.type} / ${item.owner_full_name ? item.owner_full_name : "-"}`}
            onClick={() => setTarget(item)}>
            {item.license_plate}
          </List.Item>
        ))}
      </List>
      ) : (
        <TSTable
          ref={tableRef}
          style={{ marginTop: '1rem' }}
          columns={columns}
          url={`${URL_CUSTOMER.VEHICLE}?search=${search}&page_size=10`}
          rowKey={"id"}
          size={"small"}
          pageSize={10}/>
      )}

      <CarRegisterModal
        open={(openCarModal || target) ? true : false}
        target={target}
        onClose={() => {
          if (openCarModal)
            setOpenCarModal(false)
          if (target)
            setTarget(null);
        }}
        onUpdated={(data) => {
          if (openCarModal)
            setOpenCarModal(false)
          if (target)
            setTarget(null);

            refreshData();
        }}
        />
    </div>
  )
})

export default React.memo(ProductListView);