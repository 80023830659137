import { Form, Row, Col, Button, theme, Popconfirm, message, Space, Input, Tooltip } from "antd";
import React from "react";
import { PlusSquareOutlined, DeleteOutlined, EditOutlined, SearchOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { List } from 'antd-mobile'
import { SECONDARY_COLOR } from "../../constants/color";
import TSTable from "../common/TSTable";
import { URL_CUSTOMER } from "../../constants/urls";
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from "../../constants/string";
import * as _ from 'lodash';
import { DELETE, GET } from "../../frameworks/HttpClient";
import ProductModal from "./ProductModal";
import ColorButton from "../common/ColorButton";
import { useWindowDimensionContext } from "../../hooks/WindowDimensionProvider";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';



const ProductListView = React.forwardRef((props, ref) => {
  const [target, setTarget] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [data, setData] = React.useState(null);
  const tableRef = React.useRef(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const { t } = useTranslation();

  const {
    token: { colorWarning }
  } = theme.useToken();

  const fetchData = async() =>{
    const response = await GET(`${URL_CUSTOMER.PRODUCT}?search=${search}`)
    setData(response.data.results)
  }

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch();
    }
  }

  const updateData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage({search : search});
    }
  }

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_CUSTOMER.PRODUCT}${id}/`)
      tableRef.current.fetch();
      message.success(t("data_deleted_successfully"))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("product_list"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        // landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title:  t("product_list"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("product_list")} ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const columns = [
    {
      title: t("profiles.name"),
      dataIndex: "name",
      sorter: true,
      searcher: true,
      width: 400
    },
    {
      title: t("Opening_market"),
      dataIndex: 'price_per_unit',
      sorter: true,
    },
    {
      title: t("unit"),
      dataIndex: 'unit',
      sorter: true,
      render: (value) => t(value)
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: 100,
      skipReport: true,
      render: (value, original) =>  (
        <div style={{textAlign: "center"}}>
          <Space direction='horizontal'>
            <Tooltip title={t("edit")}>
            <ColorButton 
              type='primary'
              override={colorWarning}
              icon={<EditOutlined />}
              onClick={() => setTarget(original)}/>
              </Tooltip>
            <Tooltip title={t("actions.delete_data")}>
            {!original.ref && 
            <Popconfirm
              title={t("Confirm-deletion-of-the-product?")}
              onConfirm={() => handleDelete(value)}
              okText={t("delete")}
              okButtonProps={{ danger: true }}
              cancelText={t("cancel")}
              placement='topRight'
            >
              <Button 
                type='primary'
                danger
                icon={<DeleteOutlined />}/>
                
            </Popconfirm>}
            </Tooltip>
          </Space>
        </div>
      )
    },
  ]

  React.useEffect(() => {
    fetchData();
  },[])

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={12} sm={16} xs={24}>
          <Space.Compact style={{ width: '100%' }} size='middle'>
            <Input placeholder= {t("search_for_products")} onPressEnter={refreshData} onChange={e => setSearch(_.get(e, 'target.value', ''))}/>
            <ColorButton 
              icon={<SearchOutlined/>}  
              onClick={() => {
                refreshData();
                fetchData();
              }}>
              {t("search")}
            </ColorButton>
          </Space.Compact>
        </Col>

        <Col md={12} sm={24} xs={24} style={{ float : 'right'}}>
              <ColorButton
                type='primary'
                style={{ width :(isOnMobile ? '100%' : 'auto'), height : isOnMobile ? 45 : 'auto', marginBottom : (isOnMobile ? 10 : ''), float : "right", marginLeft : 10}}
                onClick={() => setTarget('add')}
                icon={<PlusSquareOutlined />}
                override={SECONDARY_COLOR}>
                {t("add_product")}
              </ColorButton>
          
              {!isOnMobile &&
                <ColorButton
                  icon={<FileExcelOutlined />}
                  style={{float: "right", marginLeft : 10}}
                  onClick={() => exportTable("excel")}>
                  Excel
                </ColorButton>}
              {!isOnMobile && 
                <ColorButton
                  icon={<FilePdfOutlined />}
                  style={{float: "right", marginLeft : 10}}
                  onClick={() => exportTable("pdf")}>
                  PDF
                </ColorButton>}
        </Col>
        
      </Row>

      {isOnMobile ? (
        <List>
          {data && data.map(item => (
            <List.Item
              key={item.id}
              description={`${item.price_per_unit} / ${item.unit}`}
              onClick={() => setTarget(item)}
            >
              {item.name}
            </List.Item>
          ))}
        </List>
      ) : (
        <TSTable
          style={{ marginTop: '1rem' }}
          ref={tableRef}
          columns={columns}
          url={`${URL_CUSTOMER.PRODUCT}?search=${search}`}
          rowKey={"id"}
          size={"small"}
          pageSize={10} />
      )}
      
      <ProductModal
        open={target != null}
        onClose={() => {
          setTarget(null);
          fetchData();
          updateData();
        }}
        target={target}/>
    </div>
  )
})

export default React.memo(ProductListView);