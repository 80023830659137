import React from 'react';
import propTypes from 'prop-types';
import { Card, Col, Tooltip, Statistic, Row} from 'antd';
import { PRIMARY_COLOR } from '../../constants/color';
import * as _ from 'lodash';

export default function StaticCard(props) {
  const { 
    title, 
    value, 
    explantText,
    titleFontSize, 
    titleColor, 
    valueFontSize, 
    valueColor, 
    width,
    height, 
    style,
    icon,
    info, 
    precision
  } = props;
    return (
      <Card 
        bordered={false} 
        hoverable
        style={{
          width: width, 
          height: height, 
          boxShadow: '1px 1px 6px #DADADA', 
          borderRadius: 0 , 
          ...style,}}>
        <Row>
          <Col md={24} sm={24} xs={24}> 
            <Tooltip 
              placement={"topLeft"} 
              color={"orange"} 
              title={explantText ? explantText : null}>
              <div 
                style={{ 
                  fontSize : titleFontSize, 
                  color : titleColor, 
                  fontWeight: 'bold', 
                }}>{title}</div>
            </Tooltip>
          </Col>

          <Col md={24} sm={24} xs={24}>
            <Statistic
              value={value}
              precision={precision}
              valueStyle={{ 
                fontSize: valueFontSize, 
                textAlign: 'center', 
                fontWeight: 'bold', 
                color : valueColor }}
              prefix={icon}> 
            </Statistic>
          </Col>

          <Col md={24} sm={24} xs={24}>
            <div 
              style={{ 
                fontSize : titleFontSize, 
                color : titleColor, 
                fontWeight: 'bold', 
                textAlign:'center',
              }}>{info}</div>
          </Col>
        </Row>
      </Card>
    )
}

StaticCard.defaultProps = {
  title: "",
  titleFontSize: 20,
  titleColor: PRIMARY_COLOR,
  value: "",
  info:'',
  valueFontSize: 40,
  valueColor: 'black',
  width: 250,
  height:200,
  icon: null,
  precision : 0,
  explantText: null,
}

StaticCard.propTypes = {
  title: propTypes.string,
  titleFontSize: propTypes.number,
  titleColor: propTypes.string,
  value: propTypes.oneOfType([propTypes.number, propTypes.string]),
  info: propTypes.string,
  valueFontSize: propTypes.number,
  valueColor: propTypes.string,
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  height: propTypes.oneOfType([propTypes.number, propTypes.string]),
  icon: propTypes.object,
  precision : propTypes.number,
  explantText: propTypes.string,
}