import React from "react";
import { Form, Button, Popconfirm, theme, message, Tooltip, Space } from "antd";
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { SECONDARY_COLOR } from "../../constants/color";
import TSTable from "../common/TSTable";
import DriverModal from "./DriverModal";
import { URL_CUSTOMER } from "../../constants/urls";
import { DELETE } from "../../frameworks/HttpClient";
import ColorButton from "../common/ColorButton";
import { useTranslation } from 'react-i18next';

export default function DriverListView(props) {
  const [form] = Form.useForm();
  const tableRef = React.useRef(null);
  const [openDriverModal, setOpenDriverModal] = React.useState(false);
  const [target, setTarget] = React.useState(false);
  const { t, i18n } = useTranslation();


  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_CUSTOMER.DRIVER}${id}/`)
      tableRef.current.fetch();
      message.success(t("data_deleted_successfully"))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  const {
    token: { colorError, colorWarning }
  } = theme.useToken();

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
    }
  }

  const columns = [
    {
      title: t("profiles.full_name"),
      dataIndex: "full_name",
      sorter: true,
      searcher: true,
      width: 400
    },
    {
      title: t("profiles.phone"),
      dataIndex: 'phone',
      sorter: true,
    },
    {
      title: 'Actions',
      width: 140,
      dataIndex: 'id',
      render: (value, original) => (
        <div style={{ textAlign: "center" }}>
          <Space>
          <Tooltip title={t("actions.edit")}>
            <ColorButton
              type='primary'
              override={colorWarning}
              icon={<EditOutlined />}
              onClick={() => setTarget(original)}
            />
          </Tooltip>
          <Tooltip title={t("actions.delete_data")}>
          <Popconfirm
            title={t("delete_Driver")}
            description={t("Do_you_want_to_delete_this_driver")}
            onConfirm={() => handleDelete(value)}
            okText={t("actions.delete_data")}
            okButtonProps={{ danger: true }}
            cancelText={t("cancel")}
            placement="topRight">
            <ColorButton
              type='primary'
              override={colorError}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
          </Tooltip>
          </Space>
        </div>
      )
    },
  ]

  return (
    <div>
      <div>
        <ColorButton
          type={"primary"}
          icon={<PlusCircleOutlined />}
          onClick={() => setOpenDriverModal(true)}
          override={SECONDARY_COLOR}
          style={{ marginBottom: 8, float: "right" }}>
            {t("add_driver")}
        </ColorButton>
      </div>

      <TSTable
        ref={tableRef}
        columns={columns}
        data={""}
        url={URL_CUSTOMER.DRIVER}
        rowKey={"id"}
        size={"small"}
        pageSize={10} />

      <DriverModal
        open={(openDriverModal || target) ? true : false}
        target={target}
        onUpdated={() => {
          if (openDriverModal)
            setOpenDriverModal(false)
          if (target)
            setTarget(null)

          refreshData();
        }}
        onClose={() => {
          if (openDriverModal)
            setOpenDriverModal(false)
          if (target)
            setTarget(null)
        }}
      />
    </div>
  )
}