import { Button, Popconfirm, theme, message, Space, Spin, Tooltip, Modal, Row, Col } from "antd";
import { List } from 'antd-mobile'
import React from "react";
import { DeleteOutlined, EditOutlined, QrcodeOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import TSTable from "../common/TSTable";
import { URL_CUSTOMER } from "../../constants/urls";
import { DELETE, GET } from "../../frameworks/HttpClient";
import CustomerFilterHeader from "./CustomerFilterHeader";
import ColorButton from "../common/ColorButton";
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { CUSTOMER_TYPE, DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from "../../constants/string";
import QRCodeModal from "../farmer/QRCodeModal";
import { CYAN_COLOR } from "../../constants/color";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const CustomerListView = React.forwardRef((props, ref) => {
  const headerRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const [isMobileLoading, setIsMobileLoading] = React.useState(false)
  const [searchParams, setSearchParams] = React.useState({});
  const [mobileData, setMobileData] = React.useState(null);
  const [isRefresh, setIsRefresh] = React.useState(false)
  const [targetQR, setTargetQR] = React.useState(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const [messageApi, contextHolder] = message.useMessage();
  const { t, i18n } = useTranslation();
  
  const {
    token: { colorWarning }
  } = theme.useToken();

  const fetchDataSearch = async(params, update) => {
    try {
      if(update === true){
        setSearchParams(params)
        setIsRefresh("update")
      } else {
        setSearchParams(params)
        setIsRefresh(true)
      }
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error['errorMessages'],
      });
      console.log(error)
    } finally {
    }

  }

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("customer_list"),
        subtitle: `${t("data_as_of")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        // landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("customer_list"),
        subtitle: `${t("data_as_of")}  ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("customer_list")}  ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const refreshData = async() => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch(searchParams);
    }
    else if(isOnMobile) {
      try {
        setIsMobileLoading(true)
        const response = await GET(`${URL_CUSTOMER.CUSTOMER}`, searchParams)
        setMobileData(response.data.results)
      } catch(error) {
        messageApi.open({
          type: 'error',
          content: error['errorMessages'],
        });
        console.log(error)
      } finally { setIsMobileLoading(false) }
    }
  }

  const refreshWithoutResetPage = async() => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchSearchWithoutResetPage(searchParams);
    }
  }

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_CUSTOMER.CUSTOMER}${id}/`)
      refreshData();
      message.success(t("data_deleted_successfully"))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  const columns = [
    {
      title: t("profiles.name"),
      dataIndex: 'full_name',
      searcher: true,
      sorter: true,
    },
    {
      title: t("farmers.address"),
      dataIndex: 'sub_district_detail',
      sorter: true,
      render: (value, original) => (<div>{`${original.address ? original.address : ''} ${value ? value : ''}`}</div>),
      renderReport: (value) => (value)
    },
    {
      title: t("type"),
      dataIndex: 'type',
      sorter: true,
      width: 120,
      render: (value) => t(value)
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: 100,
      skipReport: true,
      render: (value, original) => {
        return ( 
          <div style={{ textAlign: 'center' }}>
            {/* Farmer customer */}
            {(original.type === CUSTOMER_TYPE.FARMER) && (
              <Space direction='horizontal'>
                <Tooltip title="Line QR Code">
                  <ColorButton
                    type={"primary"} 
                    override={CYAN_COLOR}
                    icon={<QrcodeOutlined />}
                    onClick={() => setTargetQR(original)}/>
                </Tooltip>
              </Space>
            )}
            
            {/* General customer */}
            {(original.type === CUSTOMER_TYPE.GENERAL) && (
              <Space direction='horizontal'>
                <Tooltip title={t("actions.edit")}>
                <ColorButton
                  type='primary'
                  override={colorWarning}
                  icon={<EditOutlined />}
                  onClick={() => {
                    if (headerRef.current) {
                      headerRef.current.onEdit(original)
                    }
                  }} />
                  </Tooltip>
                <Tooltip title={t("actions.delete_data")}>
                <Popconfirm
                  title= {t("confirm_delete_customer")}
                  onConfirm={() => handleDelete(value)}
                  okText={t("delete")}
                  okButtonProps={{ danger: true }}
                  cancelText={t("cancel")}
                  placement='topRight'>
                  <Button
                    type='primary'
                    danger
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
                </Tooltip>
              </Space>
            )
          }
        </div>
        )
      }
    },
  ]

  React.useEffect(() => {
    if (isRefresh === true){
      refreshData();
      setIsRefresh(false)
    } else if (isRefresh === "update"){
      refreshWithoutResetPage();
      setIsRefresh(false)
    }
  },[isRefresh])

  return (
    <div>
      <CustomerFilterHeader ref={headerRef} refresh={fetchDataSearch}/>
      {contextHolder}
      <div style={{ marginTop: '1rem' }}>
        { isOnMobile ? (
          <Spin spinning={isMobileLoading}>
            <List>
              {mobileData && mobileData.map(item => (
                <List.Item
                  key={item.id}
                  description={`${item.type}`}
                  onClick={() =>  {
                    if (headerRef.current) {
                      headerRef.current.onEdit(item)
                  }}}
                >
                  {item.full_name}
                </List.Item>))}
            </List>
          </Spin>
        ) : (
          <>
          <Space style={{ float: 'right', marginBottom : '1em' }}>
            <Row gutter={8}>
              <Col>
                <ColorButton
                  icon={<FilePdfOutlined />}
                  style={{float: "right"}}
                  onClick={() => exportTable("pdf")}>
                  PDF
                </ColorButton>
              </Col>
              <Col>
                <ColorButton
                  icon={<FileExcelOutlined />}
                  style={{float: "right"}}
                  onClick={() => exportTable("excel")}>
                  Excel
                </ColorButton>
              </Col>
            </Row>
          </Space>
          <TSTable
            ref={tableRef}
            columns={columns}
            data={""}
            url={`${URL_CUSTOMER.CUSTOMER}`}
            rowKey={"id"}
            size={"small"}
            pageSize={10} />
          </>
        )}
        </div>

      <QRCodeModal
        open={targetQR ? true : false}
        memberId={targetQR ? targetQR.farmer : null}
        memberName={targetQR ? i18n.language === 'th' ? targetQR.full_name : targetQR.full_name_en : null}
        onClose={() => setTargetQR(null)} />
    </div>

  )
})

export default React.memo(CustomerListView);