import React from 'react';
import { Content } from 'antd/es/layout/layout'
import { theme, Card, Modal, ConfigProvider} from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { Avatar} from 'antd';
import LoginCashierModal from '../components/collectingcenter/LoginCashierModal';
import { GET } from '../frameworks/HttpClient';
import { URL_CUSTOMER } from '../constants/urls';
import { useUserContext } from '../hooks/UserSettingProvider';
import LogoutModal from '../components/user/LogoutModal';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';
import {List as ListAntd} from "antd";
import { Button as ButtonAntd} from "antd";
import { List , Button} from 'antd-mobile'


export default function UserCashier(props) {
  const [openLoginCashierModal, setopenLoginCashierModal] = React.useState(false);
  const [cashier, setCasheir] = React.useState([]);
  const [target, setTarget] = React.useState(null);
  const [openLogout, setOpenLogout] = React.useState(false);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');


  const user = useUserContext();
  const fetchData = async () => {
    try {
      const response = await GET(`${URL_CUSTOMER.CASHIER}`)
      setCasheir(response.data.results)
    } catch (error) { }  
  }
  const gridStyle = {
    width: 'auto',
    textAlign: 'center',
  };
  const isChecked = (checked) => {
    if(checked === true)
      props.onClose();
  };
  const cashierIsActive = cashier.filter((cashier)=>cashier.is_active === true)
  React.useEffect(() => {
    fetchData();
  },[user])

  // React.useEffect(() => {
  //   console.log(cashier)
  // },[cashier])

  return (
    
      <Modal                       
        open={props.open} 
        onCancel={props.onClose}
        width={1000} 
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        closable= {false}
        maskClosable ={false}
        keyboard={false}
        maskStyle={{ backdropFilter: 'blur(10px)' }}
      >

       {/* <HeaderScreen 
       onClick={() => setOpenLogout(true)} title={"Logout"} /> */}
          {isOnMobile?(
            <div>
          <List header='USER CASHIER'>
            {cashierIsActive.map(item => (
            <List.Item
              key={item.id}
              onClick={() => 
                setTarget(item)}
              prefix={
                <Avatar 
                  size={32} 
                  icon={<UserOutlined />} 
                  style={{ backgroundColor: '#87d068' }}
                /> 
              }
            >
            {item.full_name}
            </List.Item>
            
          ))}
          </List>
          <br/>
          <Button block color='danger' size='large' onClick={() => setOpenLogout(true)}  >Logout</Button>
          </div>
          ) : (
            <Content  
            style={{  
            margin: "0px 24px 24px 24px", 
            padding: "16px 24px",
            textAlign: "center",  
            minHeight: 280,
            width:"auto",
             }}
             
            >
        <Card title="USER CASHIER">
        <ListAntd
            grid={{ gutter: 10, column: 4}}
            dataSource={cashierIsActive}
            renderItem={(item, index) => (
              <ListAntd.Item>
                <Card.Grid 
                style={{gridStyle , width:"200px"}} 
                onClick={() => 
                setTarget(item)} 
                >  
                <Avatar 
                  size={128} 
                  icon={<UserOutlined />} 
                  style={{ backgroundColor: '#87d068' }}
                /> 
                <div> {item.full_name} </div>
                </Card.Grid>
            
                  </ListAntd.Item>
                )}
              />
              </Card>
              <ButtonAntd
                  style={{marginTop:"20px", textAlign:'center'}}
                  textAlign="center"
                  type="primary"
                  onClick={() => setOpenLogout(true)} 
                  title={"Logout"} 
                  danger> 
                  Logout 
              </ButtonAntd>
              </Content>
              
              )}
    
          
          
        <LoginCashierModal
        open={(openLoginCashierModal || target) ? true : false}
        target={target}
        onUpdated={() => {
          setopenLoginCashierModal(false)
          setTarget(null);
          props.onClose();
        }}
        onSave={(id)=>{
            props.onSaveID(id)
        }}
        onClose={() => {
          if (openLoginCashierModal)
          setopenLoginCashierModal(false);
          if (target)
            setTarget(null);  
          }}
        checked={isChecked}/>
        <LogoutModal
          open={openLogout}
          onClose={() => setOpenLogout(false)}/>
         </Modal>

  )
}