import React from 'react';
import { Tabs } from 'antd';
import PlantationsVerifyView from './PlantationsVerifyView';
import PlantationsListView from './PlantationsListView';
import { FileSearchOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function PlantationManagerView() {
  const [activeKey, setActiveKey] = React.useState("CHECKING");
  const onTabsChange = (key) => setActiveKey(key);
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onChange={onTabsChange}
        destroyInactiveTabPane={true}
        items={[
          {
            key: "CHECKING", 
            label: <span><FileSearchOutlined />{t("plantations.pending_review")}</span>, 
            children: <PlantationsVerifyView />,
          },
          {
            key: "ALL", 
            label: <span><FolderOpenOutlined />{t("plantations.all_plantation")}</span>, 
            children: <PlantationsListView />,
          }
        ]} />
    </div>
  )
}