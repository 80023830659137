import React from 'react'
import propTypes from "prop-types";
import { Modal } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import { URL_AUTH } from '../../constants/urls';
import { POST } from '../../frameworks/HttpClient';
import { useUserContext } from '../../hooks/UserSettingProvider';
import Cookies from 'js-cookie';

export default function LogoutModal(props) {
	const navigate = useNavigate();
	const user = useUserContext();

	const handleLogout = async () => {
		try {
			await POST(URL_AUTH.SIGN_OUT, { is_liff: props.isLiff ? true : false })
		} catch (error) {
			console.log(error)
		}
		finally {
			user.onLogout();
			navigate(PATH.LOGIN);
			props.onClose();
			Cookies.remove('id');
		}
	}

	return (
		<Modal 
			title={"Logout"}
			okText="Logout"
			centered={false}
			icon={<LogoutOutlined />}
			open={props.open}
			onOk={handleLogout}
			onCancel={props.onClose}>
				
				<p>{"Are you sure you want to logout?"}</p>
		</Modal>
	)
}

LogoutModal.defaultProps = {
	open: false,
	onClose: () => {},
	isLiff: false,
}

LogoutModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	isLiff: propTypes.bool,
}