import React from 'react'
import propTypes from "prop-types";
import { Alert, Button, Checkbox, Modal } from "antd";
import Header from '../common/Header';
import SignaturePad from 'react-signature-canvas';
import { PATH } from '../../routes/CustomRoutes';
import { useNavigate } from "react-router-dom";
import ConsentModalTHView from './ConsentModalTHView';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { AutoCenter } from 'antd-mobile'

export default function ConsentModal(props) {
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [width, setWidth] = React.useState(0);
	const padRef = React.useRef(null);
	const signatureRef = React.useRef(null);
	const [isAgree, setIsAgree] = React.useState(false);
  const navigate = useNavigate();
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');

	const handleClear = () => {
		if (signatureRef && signatureRef.current) {
			signatureRef.current.clear();
		}
	}

	const handleConsentForm = async () => {
		let data = {}
		let signatureError = "Signature is empty!"
		if (signatureRef && signatureRef.current && isAgree === true) {
			const ref = signatureRef.current;
			if (!ref.isEmpty()) {
				data["signature"] = ref.getTrimmedCanvas().toDataURL("image/png");
				signatureError = null;
			}
		} 

		if (signatureError) {
			setErrorMessages(signatureError);
			return;
		}
		setErrorMessages(null);
		props.onUpdated(data);
  }

	React.useEffect(() => {
		// Initial width for signature pad
		if (props.open && padRef && (width === 0)) {
			setWidth(padRef.current.offsetWidth);
		}

		// Clear signature after close modal
		if (!props.open) {
			handleClear();
			setErrorMessages(null);
		}

	}, [props.open, padRef])


  return (
    <Modal
      okText='Accept'
	  style={{top: isOnMobile && 20}}
	  width={isOnMobile? '100%':'80%'}
      open={props.open}
      onOk={handleConsentForm}
      keyboard={false}
      closable= {false}
		maskClosable ={false}
      onCancel={props.onClose}
			footer={[
				<Button key={"1"} onClick={() => navigate(PATH.MEMBERS)}>{"Cancel"}</Button>,
				<Button key={"2"} type={"primary"} ghost onClick={handleClear}>{"Clear"}</Button>,
				<Button key={"3"} type={"primary"} onClick={() => isAgree === true ? handleConsentForm() : setErrorMessages("plese click the agree button")}>{"Confirm"}</Button>
			]}
			>
      <Header title={"ความยินยอมของผู้สมัคร (Consent Form)"}></Header>
	  {isOnMobile ?(
		<div>
			<div style={{ width: 'auto', height: '500px', overflow: 'auto' }}>
			<AutoCenter> <ConsentModalTHView/></AutoCenter>
			</div>
			<br />
        {errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
        }
        <Checkbox onChange={e => setIsAgree(e.target.checked)}>{'ฉันยอมรับข้อกำหนดและเงื่อนไขการเป็นสมาชิก FSC'}</Checkbox>
					<p style={{fontWeight: 'bold', marginTop:10}} >{"Signature"}</p>
					<div className="section-wrapper" ref={padRef}>
						<SignaturePad 
							penColor={"blue"} 
							ref={signatureRef} 
							canvasProps={{width: (width ? (width - 32) : 0), height: 120}}/>
					</div>
		</div>
	  ):(
      <div style={{margin: 16}}>
				{/* Consent form */}
        <ConsentModalTHView/>

        <br />
        {errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
        }
        <Checkbox onChange={e => setIsAgree(e.target.checked)}>{'ฉันยอมรับข้อกำหนดและเงื่อนไขการเป็นสมาชิก FSC'}</Checkbox>
					<p style={{fontWeight: 'bold', marginTop:10}} >{"Signature"}</p>
					<div className="section-wrapper" ref={padRef}>
						<SignaturePad 
							penColor={"blue"} 
							ref={signatureRef} 
							canvasProps={{width: (width ? (width - 32) : 0), height: 120}}/>
					</div>
      </div>)}
    </Modal>
  )
}

ConsentModal.defaultProps = {
  open: false,
	onUpdated: (sign) => {},
  onClose: () => {}
}

ConsentModal.propTypes = {
  open: propTypes.bool,
	onUpdated: propTypes.func,
  onClose: propTypes.func,
}