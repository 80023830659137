import React from "react";
import propTypes from "prop-types";
import { Alert, Col, Input, Modal, Row, theme, } from "antd";
import Form from "antd/es/form/Form";
import { PATCH, POST } from "../../frameworks/HttpClient";
import { URL_CUSTOMER } from "../../constants/urls";
import Header from "../common/Header";
import { runes } from 'runes2';
import { useTranslation } from 'react-i18next';



export default function CashierModal(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const { t, i18n } = useTranslation();

  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();

  const validatePin = (_, value) => {
    if (value.length !== 4) {
      return Promise.reject(new Error(t('please_enter_a_4_digit_PIN')));
    } else {
      return Promise.resolve();
    }
  };

  const handleOK = async() => {
    setLoading(true)
    setErrorMessages(null)
    try {
      let data = await form.validateFields();
      const cashierID = props.target ? props.target.id : null
      if(cashierID){
        await PATCH(`${URL_CUSTOMER.CASHIER}${cashierID}/`, data);
      } else {
        await POST(URL_CUSTOMER.CASHIER, data);
      }
      props.onUpdated();
        
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (props.open) {
      if (props.target) {
        form.setFieldsValue(props.target)
      }
    } else {
      setErrorMessages(null);
      form.resetFields();
    }
  }, [props.open])



  return (
    <Modal
      open={props.open}
      width={760}
      confirmLoading={loading}
      okText={props.target ? t("edit_Data") : t("add_Data")}
      okButtonProps={{ style: {background: props.target ? colorWarning : colorPrimary}}}
      onOk={handleOK}
      cancelText={t("cancel")}
      onCancel={props.onClose}>
      <Header title={t("profiles.username")}/>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: "16px", textAlign: "left"}}/>
      }
      
      <Form
        form={form}
        style={{marginTop: 16}}
        layout="vertical"
        autoComplete="off">
        <Row gutter={16}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              name={"first_name"}
              label={t("profiles.first_name")}
              rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>
          </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item
              name={"last_name"}
              label={t("profiles.last_name")}
              rules={[{ required: true }]}>
              <Input autoComplete="off"/>
            </Form.Item>
          </Col>
          {!props.target && (
            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name={"pin"}
                label={"PIN"}
                rules={[{ validator : validatePin  }]}>
                <Input
                  autoComplete="off" 
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Input a number"
                  maxLength={4}
              />
              </Form.Item>
            </Col>
          )}
          <Col md={16} sm={24} xs={24}>
            <Form.Item
              name={"email"}
              label={t("email")}
              rules={[{ required: false }]}>
              <Input autoComplete="off"/>
            </Form.Item>
          </Col>
        </Row>    
      </Form>
    </Modal>
  )
}

CashierModal.defaultProps = {
	open: false,
	target: null,
  onClose: () => {},
  onUpdated: () => {}
}

CashierModal.propTypes = {
	open: propTypes.bool, 
	target: propTypes.object,
	onClose: propTypes.func,
  onUpdated: propTypes.func,
}