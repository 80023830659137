import React from 'react';
import { Tabs, Layout,} from 'antd';
import DriverListView from '../collectingcenter/DriverListView';
import CashierListView from './CashierListView';
import AdminCCDetailView from './AdminCCDetailView';
import MechineSettingView from './MechineSettingView';
import { CarOutlined, DesktopOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { useTranslation } from 'react-i18next';


export default function AdminView() {
    const [activeKey,setActiveKey] = React.useState('DETAIL');
    const { lessThanOrEqual } = useWindowDimensionContext();
    const isOnMobile = lessThanOrEqual('md');

    const onTabsChange = (key) => setActiveKey(key);
    const { t, i18n } = useTranslation();


    return(
      <div>
        {!isOnMobile ? (
        <Tabs
          activeKey={activeKey}
          onChange={onTabsChange}
          // size='large'
          items={[
            {key: "DETAIL", label: <span><ShopOutlined />{t("menu.collecting_center")}</span>, children:<AdminCCDetailView/> },
            // {key: "MECHINE", label: <span><DesktopOutlined />เครื่องให้บริการ</span>, children:<MechineSettingView/> },
            {key: "CASHIER", label: <span><UserOutlined />{t("profiles.username")}</span>, children: <CashierListView />},
            {key: "DRIVER", label: <span><CarOutlined />{t("driver")}</span>, children: <DriverListView />},
          ]} />
          ) : (
          <AdminCCDetailView/>
        )}
        
      </div>
    )} 