import React from "react"
import { Layout, theme, Alert, Tabs, Col, Row, Card } from 'antd'
import HeaderScreen from "../components/common/HeaderScreen";
import { Content } from 'antd/es/layout/layout'
import { useParams } from 'react-router-dom';
import { PATH } from "../routes/CustomRoutes";
import ScaleCustomerView from "../components/collectingcenter/ScaleCustomerView"
import ScaleCardView from "../components/collectingcenter/ScaleCardView"
import ScaleWindow from "../components/scale/ScaleWindow";
import { ProfileOutlined, UserOutlined } from '@ant-design/icons';
import SideScale from "../components/scale/SideScale";

export default function ScaleView() {
  
  return (
    <Row gutter={16}>
      <Col lg={18} md={12} sm={24}>
        <ScaleCustomerView/>
      </Col>
      <Col lg={6} md={12} sm={0} style={{ height: '100%' }}>
        <SideScale/>
      </Col>
    </Row>

  )
}