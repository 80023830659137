import React from 'react';
import { Alert, Button, Col, Empty, Input, Row, Space, Spin, message, Modal } from 'antd';
import { SECONDARY_COLOR } from '../../constants/color';
import { EyeOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { GET, POST } from '../../frameworks/HttpClient';
import { URL_STOCK } from '../../constants/urls';
import StockModal from './StockModal';
import * as _ from 'lodash';
import StockCard from './StockCard';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import { INPUT_TYPE } from '../../constants/string';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { useTranslation } from 'react-i18next';

export default function StockHistory() {
  const navigate = useNavigate();
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');

  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1)
  const [hasMore, setHasMore] = React.useState(false);

  const [target, setTarget] = React.useState(null);
  const [targetWeight, setTargetWeight] = React.useState(null);

  const { t, i18n } = useTranslation();


  const fetchData = async (options) => {
    setLoading(true);
    setErrorMessages(null);
    let hasRefetch = _.get(options, 'refetch', false);
    try {
      const response = await GET(URL_STOCK.STOCK, { is_closed: true, search: search, page: hasRefetch === true ? page + 1 : null })    
      let newData = _.get(response, 'data.results', [])
      let currentPage = _.get(response, 'data.current_page', 1)
      setData(prev => hasRefetch ? [...prev, ...newData] : newData)
      setPage(currentPage)
      setHasMore(currentPage < _.get(response, 'data.pages', 1))
    } catch (error) {
      messageApi.error(error.errorMessages)
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleClose = async (id) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(`${URL_STOCK.STOCK}${id}/close/`)
      fetchData();
      messageApi.success(t("lot_closed_successfully"))
    } catch (error) {
      messageApi.error(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [])

  return (
    <div style={{ marginTop: '1rem', marginBottom : 48 }}>
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col md={12} sm={16} xs={24}>
          <Space.Compact style={{ width: '100%' }} size='middle'>
            <Input placeholder={t("search_for_lot")} onPressEnter={fetchData} onChange={e => setSearch(_.get(e, 'target.value', ''))}/>
            <Button icon={<SearchOutlined/>} onClick={fetchData}>{t("search")}</Button>
          </Space.Compact>
        </Col>
      </Row>

      { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/>}
      <div style={{ marginTop: '1rem' }}>
        { !data.length && <Empty description={t("none")}/> }

        <Spin spinning={loading}>
          <Row gutter={[16, 16]}>
            { data.map((item, idx) => (
              <Col key={`stock-${idx}`} xl={8} lg={12} md={24} style={{ width: '100%' }}>
                <StockCard
                  data={item}
                  loading={loading}
                  onEdit={() => {
                    setTarget(item.id)
                    setTargetWeight({importWeight : item.imported_weight, remainWeight : item.weight })
                  }}
                  onTransaction={direction => {
                    navigate({
                      pathname: `${PATH.SCALE}/add/`,
                      search: createSearchParams({
                        stock: item.id,
                        product: item.product,
                        input_type: INPUT_TYPE.MANUAL,
                        direction: direction
                      }).toString(0)
                    })
                  }}/>
              </Col>
            )) }
          </Row>
        </Spin>
      </div>
      <div style={{ marginTop: '1rem', textAlign: 'center' }}>
        { hasMore && <Button size='large' icon={<EyeOutlined />} onClick={() => fetchData({ refetch: true })}>{t("load_more")}</Button> }
      </div>

      <StockModal
        open={target != null}
        target={target}
        isClose={true}
        stockWeight={targetWeight}
        onClose={() => {
          setTarget(null);
          fetchData();
        }}/>

    </div>
  )
}