import { Card, Divider, List, Spin, Typography, theme } from 'antd';
import React from 'react';
import ScaleWindow from './ScaleWindow';
import { GET } from '../../frameworks/HttpClient';
import { URL_WEIGHT } from '../../constants/urls';
import * as _ from 'lodash';
import ErrorMessages from '../common/ErrorMessages';
import CustomCardList from '../common/CustomCardList';
import { UserOutlined, CalendarOutlined, CarOutlined, SwapOutlined, DashboardOutlined } from '@ant-design/icons';
import moment from 'moment';
import { HUMAN_DATETIME_FORMAT, UNIT_TRANSLATION, WEIGHT_TICKET_DIRECTION_TRANSLATION } from '../../constants/string';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import { formatComma } from '../../frameworks/Util';
import { useTranslation } from 'react-i18next';



export default function SideScale() {

  const { token: { colorInfo } } = theme.useToken();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [dataList, setDataList] = React.useState([]);
  const { t, i18n } = useTranslation();


  const fetchInProgress = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_WEIGHT.WEIGHT_TICKET, { pending: true, page_size: 3 })
      setDataList(_.get(response, 'data.results', ''))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const details = {
    header: { dataIndex: 'customer_name', icon: <UserOutlined style={{ color: colorInfo }}/> },
    descriptions: [
      {
        title: t("date"), dataIndex: 'created', icon: <CalendarOutlined/>,
        render: (value) => moment(value).format(HUMAN_DATETIME_FORMAT)
      },
      { title: t("vehicle_registration_number"), dataIndex: 'vehicle_license_plate', icon: <CarOutlined/> },
      { title: t("ticket_type"), dataIndex: 'direction', icon: <SwapOutlined />,
        render: (value) => _.get(WEIGHT_TICKET_DIRECTION_TRANSLATION, value, '-')
      },
      { title: t("gross_weight"), dataIndex: 'net_weight', icon: <DashboardOutlined />,
        render: (value, original) => `${formatComma(value)} ${_.get(UNIT_TRANSLATION, original.net_unit, '')}`
      },
    ],
    onClick: (item) => navigate(`${PATH.SCALE}/${item.id}`)
  }

  React.useEffect(() => {
    fetchInProgress();
  }, [])

  return (
    <Card>
      <ScaleWindow/>
      <ErrorMessages message={errorMessages}/>
      <Spin spinning={loading}>
        <Divider>{t("scale_ticket_list")}</Divider>
        <CustomCardList
          url={URL_WEIGHT.WEIGHT_TICKET}
          data={dataList}
          details={details}
          pagination={false}
          footer/>
      </Spin>
    </Card>
  )
}