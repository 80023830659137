import React from 'react';
import propTypes from "prop-types";
import {theme, Alert, Card, List } from 'antd';
import { URL_PLANTATIONS } from '../constants/urls';
import {  GET } from '../frameworks/HttpClient';
import { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';


export default function FarmerPlantationListView(props) {
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [data, setData] = React.useState([]);
  const { t, i18n } = useTranslation();



  const FARMER_STATUS = {
    draft: t("farmer_status.draft"), 
    checking: t("farmer_status.checking"), 
    waiting: t("farmer_status.waiting"), 
    approved: t("farmer_status.approved"), 
    rejected: t("farmer_status.rejected"), 
  }

  const {
    token: { colorPrimary }
  } = theme.useToken();

  // Fetch plantation list
  const fetchPlantationList = async (farmer) => {
    if (!farmer) {
      setErrorMessages(t("member_information_not_found"))
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PLANTATIONS.PLANTATIONS}?farmer=${farmer}`)
      setData(response.data.results);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if (props.user && props.user.farmer)
      fetchPlantationList(props.user.farmer)

  }, [props.user]);

  return (
    <Content>
      {errorMessages && 
        <Alert showIcon
          style={{textAlign: "left", marginBottom: 16}} 
          type="error" 
          message={errorMessages}/>
      }

      <List
        dataSource={data}
        renderItem={(item) => (
          <Card 
            style={{marginBottom: 16}}
            key={item.id} 
            title={item.code ? item.code : t("plot_id_not_assigned_yet")} >
            <div>{`${t("plantations.deed_type")} : ${item.title_deed_type_name}`}</div>
            <div>{`${t("plantations.deed_code")} : ${item.title_deed_no}`}</div>
            <div>{`${t("status")} : ${item.status ? FARMER_STATUS[item.status] : t("unknown")}`}</div>
          </Card>
        )}
      />
    </Content>
  )

}

FarmerPlantationListView.defaultProps = {
  user: null,
}

FarmerPlantationListView.propTypes = {
  user: propTypes.object,
}