import React, { useState } from 'react'
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons';
import { Button, Form, Input, Image, Spin, Alert } from 'antd';
import Segment from '../components/common/Segment';
import { POST } from "../frameworks/HttpClient";
import { PATH } from "../routes/CustomRoutes";
import { URL_AUTH } from '../constants/urls';
import { COOKIE_AUTH } from '../constants/cookies';
import "./../styles.css"
import { useUserContext } from '../hooks/UserSettingProvider';
import * as _ from 'lodash'

export default function LoginScreen() {
  const navigate = useNavigate();
	const user = useUserContext();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  // Login
  const handleLogin = async (values) => {
    setErrorMessages(null);
    setIsLoading(true);
    let response = null;
    try {
      response = await POST(URL_AUTH.API_TOKEN_AUTH, {...values})
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }

    if (response)
      onFinish(response.data);
  }

  const onFinish = async (data) => {
    if (!data) return;
    
    // Set data into cookies
    user.onLogin(data)

    // Goto target page after loged-in
    if (_.get(data, 'user', false)) {
      navigate(PATH.MAIN, {replace: true});
    }
  };

  return(
    <div className='center-screen'>
      <div style={{width: "90%", maxWidth: 420}}>
        <Image 
          style={{textAlign: "center", width: "100%", maxWidth: 120}}
          src={"/logo_txt.png"} 
          preview={false} />
        
        <Segment 
          style={{marginTop: 24, padding: 24}}
          detail={
            <Spin spinning={isLoading}>
              {errorMessages && 
                <Alert
                  style={{marginBottom: "12px", textAlign: "left"}}
                  message={errorMessages}
                  type="error"
                  showIcon 
                />}
              <Form
                style={{marginTop: 8}}
                // name="normal_login"
                className="login-form"
                form={form}
                onFinish={handleLogin}
                autoComplete={"off"}>
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Please enter your username!' }]}>
                  <Input 
                    autoCapitalize='none'
                    prefix={<UserOutlined className="site-form-item-icon" />} 
                    placeholder="Username" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please enter your password!' }]}>
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"/>
                </Form.Item>

                <Form.Item style={{margin: 0}}>
                  <Button 
                    style={{width: "100%"}}
                    type={"primary"} 
                    htmlType="submit" 
                    shape="round" 
                    icon={<LoginOutlined />}>{"Login"}</Button>
                </Form.Item>
              </Form>
            </Spin>
          }/>
      </div>
    </div>
  )
}