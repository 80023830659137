import { Content } from "antd/es/layout/layout";

export default function ConsentModalTHView (props) {
  return (
    <Content>
      <div style={{ textAlign: 'left', marginTop: 0,fontSize: 15 }}>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;ผู้สมัครเข้าร่วมเป็นสมาชิกกับทางบริษัท อกรีแอค โกลบอล (“บริษัท”) รับทราบและยินยอมให้บริษัทฯ จัดเก็บ รวบรวม ใช้ เข้าถึง 
          และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าในกรณี ดังต่อไปนี้ได้เก็บ รวบรวม และใช้ ข้อมูลส่วนบุคคลต่าง ๆ ที่ข้าพเจ้าได้กรอกในใบสมัครของบริษัทฯ 
          และข้อมูลที่ปรากฏอยู่ในเอกสารประกอบการสมัครทั้งหมดที่ข้าพเจ้าได้แนบไว้กับใบสมัครของข้าพเจ้า เพื่อใช้ในการดำเนินงานของบริษัท
          เก็บ รวบรวม และใช้ ข้อมูลส่วนบุคคลของบุคคลภายนอกที่เป็นสมาชิกในครอบครัว ญาติ หรือบุคคลอ้างอิงของข้าพเจ้า ตามที่ข้าพเจ้าได้ให้ข้อมูลไว้ในใบสมัครหรือเอกสารอื่น ๆ 
          เท่าที่จําเป็น โดยข้าพเจ้าได้ดําเนินการแจ้งให้แก่บุคคลเหล่านั้นทราบถึงการจัดเก็บ รวบรวม และใช้ ข้อมูลส่วนบุคคลดังกล่าวของบริษัทฯ แล้ว
        </p>

        <p>ทั้งนี้ ข้าพเจ้าได้อ่านและรับทราบข้อมูลโดยละเอียดจากนโยบายคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy) ที่ https://www.agriac.com/copy-of-pdpa หรือ link ด้านล่าง</p>

        <h3>ข้อตกลงระหว่าง ข้าพเจ้าและการจัดการสวนป่ายั่งยืน อกรีแอค โกลบอล จำกัด</h3>
        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; การตกลงนี้ เป็นการตกลงระหว่าง บริษัท อกรีแอคโกลบอล จำกัด และ ข้าพเจ้าสวนป่ายั่งยืนของบริษัท อกรีแอค โกลบอล จำกัด 
        ต้ังอยู่ที่ 187/17 ถนนประชาธิปัตย์ ต หาดใหญ่ อ หาดใหญ่ จ สงขลา 90110 กับข้าพเจ้าและข้าพเจ้ายินดีท่ีจะปฏิบัตตามเงื่อนไขที่ได้กำหนด 
        สำหรับการเข้าร่วมโครงการสวนป่ายั่งยืน กล่าวคือข้าพเจ้ายินดีเข้าร่วมโครงการรับรองตามมาตรฐานสวนป่ายั่งยืนด้วยความสมัครใจ 
        โดยมีอายุการรับรองคราวละ 5ปี ซี่งในระหว่างที่ใบรับรองมีผลบังคับ 
        <ul>
          <li>ข้าพเจ้าจะปฏิบัติตามหน้าที่สมาชิก อย่างเคร่งครัด</li>
          <li>ข้าพเจ้าจะเข้าร่วมอบรม การส่งเสริมความรู้ ความเข้าใจ ตามข้อกำหนด  </li>
          <li>ข้าพเจ้าจะไม่ทำการเผาหรือใช้ สารเคมีร้ายแรง รวมถึงการปฏิบติตามหลักการและข้อกำหนด ของ FSC อย่างเคร่งครัด ความผิดปรกติ หรือการดำเนินการท่ีไม่เป็นไปตามหลักการน้ีจะรีบแจ้ง ผู้จัดการสวนป่าในทันที</li>
          <li>ผลผลิตจากสวนป่าที่ได้รับการรับรอง มาตรฐานป่าไม้ยั่งยืนของข้าพเจ้านั้น จะไม่มีการรวมปะปนกับ พื้นที่ที่ไม่ได้รับการรับรอง และไม่ใช่ผลผลิตของข้าพเจ้า</li>
          <li>ข้าพเจ้าต้องชําระค่าธรรมเนียมที่ดินตามที่กฎหมายกำหนด รวมถึงรวบรวมเก็บเอกสารไว้ เพื่อการตรวจสอบ</li>
          <li>ข้าพเจ้ายินดีปฎิบัติ ตามข้อกำหนด ประเทศที่ร่วมลงนามในสนธิสัญญาระหว่างประเทศ เช่น CITES, ILO conventions, ITTA และ การประชุมในเรื่องความหลากหลายทางชีวภาพ</li>
          <li>ข้าพเจ้าจะไม่รุกล้ำเก็บเกี่ยวผลผลิตยางพาราในที่ผิดกฏหมายหรือ ที่ไม่ได้รับการอนุญาต</li>
          <li>ข้าพเจ้าต้องมีหลักฐานในการการถือครองและการใช้สิทธิ์ในระยะยาวบนที่ดิน (เช่น ชื่อที่ดิน, สิทธิตามจารีตประเพณี, หรือสัญญาเช่า เป็นต้น)</li>
          <li>ข้าพเจ้าจะปฎิบัติตาม คู่มือ อาชีวอนามัยและความปลอดภัย เพื่อความปลอดภัยของข้าพเจ้าเองและผู้จ้างเหมาช่วง</li>
          <li>ข้าพเจ้ามี มีการแจ้งผู้จัดการป่าไม้เมื่อเกิดอุบัติเหตุจากการทำงาน</li>
          <li>ข้าพเจ้าจะอบรม ผู้รับเหมาช่วงเกี่ยวกับความปลอดภัยในการดําเนินกิจกรรมภายใต้การบริหารจัดการป่า</li>
          <li>ข้าพเจ้าต้องเผาชีวมวลหลังจากการเก็บเกี่ยวให้น้อยที่สุด และต้องปฏิบัติให้สอดคล้องกับข้อกําหนดและ/หรือ แนวทางในการ ดําเนินงานทางกฏหมายที่เกี่ยวข้อง และข้อกําหนดเกี่ยวกับการป้องกันไฟป่าของประเทศไทย</li>
          <li>ข้าพเจ้าต้องไม่มีการล่าสัตว์ในแปลง รวมถึงการเก็บสะสม ทำลาย พืชของสายพันธุ์หายาก หรือสายพันธุ์ทีใกล้ จะสูญพันธุ์</li>
          <li>ข้าพเจ้าในกลุ่ม ต้องไม่ใช้งานสารเคมีทีอยู่ในรายการสารเคมีต้องห้าม สารเคมีที่มีเป็นอันตรายรุนแรงยา ฆ่า แมลง</li>
          <li>ข้าพเจ้าไม่มีการเผา ทำลาย ขยะในพื้นที่สวนยาง</li>
          <li>ข้าพเจ้าไม่มีการใช้ ปลูก พืชหรือสิ่งมีชีวิตทีผ่านการตัดแต่งพันธุกรรมในเชิงการค้า</li>
          <li>ข้าพจะทำการบันทึก ข้อมูลต่างๆดังนี้ ในสมุดสมาชิก</li>
            <ol>
              <li>บันทึกการเจริญเติบโตของต้นยาง</li>
              <li>บันทึกการใส่ปุ๋ย</li>
              <li>บันทึกการใช้สารเคมี</li>
              <li>บันทึกโรคพืชและแมลงที่พบ</li>
              <li>บันทีกการรับเหมาช่วงเก็บเกี่ยวผลผลิต</li>
              <li>บันทีกการรับเหมาช่วงทำไม้</li>
              <li>บันทีกการรับเหมาช่วงอื่นๆ (ระบุ)</li>
            </ol>
          <li>ข้าพเจ้าจะแจ้งให้บริษัททราบทันทีก่อนการโค่นไม้</li>
        </ul>
        <p>ทั้งนี้บริษัทจะไม่มีการเรียกร้องรับค่า บริการ ค่าสมาชิก ค่าดำเนินการใดจากข้าพเจ้า ถ้าพบว่ามี พฤติกรรมดังกล่าวให้ แจ้งมายัง Agriacinfo@gmail.com ทันที</p>
        <p>บริษัท มีสิทธิ์ในการเพิกถอนพื้นที่ออกจากระบบการรับรอง หากพบว่า การดำเนินการไม่เป็นไปตามข้อ กำหนดของ FSC หรือได้รับการร้องเรียน ทั้งนี้ข้าพเจ้าจะไม่ เรียกร้องสิทธิใดๆ จาก บริษัท</p>


      </div>
    </Content>
  )
}