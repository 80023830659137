import {
  AutoComplete, Button, Card, Col, Divider, Form, 
  Input, InputNumber, Layout, Modal, Row, Select,
  Space, Spin, Typography, theme, Progress, Popover,
  Tag
} from 'antd';
import React from 'react';
import HeaderScreen from '../components/common/HeaderScreen';
import Header from '../components/common/Header';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { URL_CUSTOMER, URL_DELIVERY, URL_PLANTATIONS, URL_STOCK, URL_WEIGHT } from '../constants/urls';
import {
  DeleteOutlined,
  DollarOutlined,
  FormOutlined,
  PlusOutlined,
  PrinterOutlined,
  SaveOutlined,
  DownloadOutlined,
  UploadOutlined,
  CloseOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { DELETE, GET, PATCH, POST, PUT } from '../frameworks/HttpClient';
import * as _ from 'lodash';
import ColorButton from '../components/common/ColorButton';
import {
  CHEMICAL_TYPE_OPTIONS, CUSTOMER_TYPE, INPUT_TYPE, MANUAL_TICKET_DIRECTION_TRANSLATION, 
  PLANTATION_TRACEABILITY, 
  PRODUCT_TRACEABILITY, UNIT, UNIT_TRANSLATION, VEHICLE_TYPE_TRANSLATION, 
  WEIGHT_TICKET_DIRECTION, WEIGHT_TICKET_DIRECTION_TRANSLATION
} from '../constants/string';
import CustomSearchSelect from '../components/common/CustomSearchSelect';
import { useUserContext } from '../hooks/UserSettingProvider';
import { PATH } from '../routes/CustomRoutes';
import CarRegisterModal from '../components/collectingcenter/CarRegisterModal';
import { BG_LAYOUT_COLOR, TYPE_COLLECTINGCENTER, TYPE_FARMER } from '../constants/color';
import ScalePaymentModal from '../components/scale/ScalePaymentModal';
import ErrorMessages from '../components/common/ErrorMessages';
import { formatComma } from '../frameworks/Util';
import CustomUpload from '../components/common/CustomUpload';
import ScaleField from '../components/scale/ScaleField';
import ScaleWindow from '../components/scale/ScaleWindow';
import moment from 'moment';
import useDropdownItem from '../hooks/useDropdownItem';
import { moreThanZero } from '../constants/validators';
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import "../index.css"

const weightInputStyle = { height: 80, fontSize: 80, fontFamily: "Digital7", color: "#FDF5E6", textAlign: "center", textShadow: "0 0 3px #FFFFE0, 0 0 5px #FFFFFF" }
const INITIAL_DRC = 30

export default function ScaleScreen () {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, __] = useSearchParams();
  const { user } = useUserContext();
  const { token: { colorInfo, colorSuccess, colorPrimary, colorError } } = theme.useToken();
  const [form,] = Form.useForm();
  const drc = Form.useWatch(['extra_details', 'drc'], form);
  const { t, i18n } = useTranslation();

  const scaleWindowRef = React.useRef();
  const vehicleRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  // ticket status
  const [ticketData, setTicketData] = React.useState({});
  const isManual = _.get(ticketData, 'input_type') == INPUT_TYPE.MANUAL;
  const ticketDirection = _.get(ticketData, 'direction', null);
  const manualInputWeightFields = ticketDirection == WEIGHT_TICKET_DIRECTION.BUY ? 'in_weight' : 'out_weight'
  const isFinished = _.get(ticketData, 'is_finished', false);
  const isPaid = _.get(ticketData, 'is_paid', false);
  const isCreate = id == 'add';
  const editable = !isPaid;
  const delRef = searchParams.get('del_ref') || _.get(ticketData, 'del_ref', null)
  const ticketRef = searchParams.get('ref') || _.get(ticketData, 'ref', null)

  // modal state
  const [addNoteOpen, setAddNoteOpen] = React.useState(false);
  const [addVehicleOpen, setAddVehicleOpen] = React.useState(false);
  const [paymentOpen, setPaymentOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  // for dynamic form rendering
  const [customer, setCustomer] = React.useState(null);
  const customerType = _.get(customer, 'type', null);
  const [isCustomerInitial, setIsCustomerInitial] = React.useState(false);
  // const isCustomerInitial = searchParams.get('customer') ? true : false;
  const [customerFilterParams, setCustomerFilterParams] = React.useState(null)
  const [productFilterParams, setProductFilterParams] = React.useState(null)
  const [product, setProduct] = React.useState(null);
  const productNeedTraced = _.get(product, 'traceability', false) ? true : false;
  const [isProductInitial, setIsProductInitial] = React.useState(false);
  // const isProductInitial = searchParams.get('product') ? true : false;
  const [vehicle, setVehicle] = React.useState(null);
  const [isVehicleInitial, setIsVehicleInitial] = React.useState(false);
  // const [isVehicleInitital] = searchParams.get('vehicle') ? true : false;
  const [note, setNote] = React.useState('');
  const [plantationDetail, setPlantationDetail] = React.useState(null);
  const [isLatexOver, setIsLatexOver] = React.useState(false)
  // For Re-Render
  const [trigger, setTrigger] = React.useState(false);

  // state when plantation changes
  const plantationWatch = Form.useWatch('plantation_sources', form)

  // stock state
  const [stockOptions, setStockOptions] = React.useState([]);
  const stockRefsWatch = Form.useWatch('stock_refs', form)
  const [closeStockConfirmTarget, setCloseStockConfirmTarget] = React.useState(null)
  const cashierID = Cookies.get('id')

  // driver
  const driversOptions = useDropdownItem({
    url: URL_CUSTOMER.DRIVER, pagination: true,
    key: 'id', value: 'full_name', label: 'full_name',
  })
  const debounceDriverSearch = React.useCallback(
    _.debounce((search) => { driversOptions.fetchOptions({ search }) }, 800),
    []
  );

  const goBack = () => navigate(-1)
  const closeWindow = () => {
		window.opener = null;
		window.open('', '_self');
		window.close();
		}

	const isCanBack = window.history.length > 1;

  const finishScreen = () => {
    if (isCanBack) {
      goBack();
    } else {
      closeWindow();
    }
  }

  const productLabelRender = (item) => {
    const traceability = _.get(item, 'traceability', '');
    return (
      <span style={{
        fontWeight: traceability ? 'bolder' : 'normal',
        color: traceability ? TYPE_FARMER : null
      }}>
        {traceability ? `[${traceability.toUpperCase()}] - ` : ''}{_.get(item, 'name', 'Unknown')}
      </span>
    )
  }

  const getCustomerParams = (direction) => {
    if (direction == WEIGHT_TICKET_DIRECTION.BUY) {
      return { type: [CUSTOMER_TYPE.COLLECTING_CENTER, CUSTOMER_TYPE.FARMER, CUSTOMER_TYPE.GENERAL] }
    } else if (direction == WEIGHT_TICKET_DIRECTION.SELL) {
      return { type: [CUSTOMER_TYPE.COLLECTING_CENTER, CUSTOMER_TYPE.FACTORY, CUSTOMER_TYPE.GENERAL] }
    }
    return null
  }

  const fetchStock = async (product_id) => {
    setStockOptions([]);
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_STOCK.STOCK, { is_closed: false, product: product_id })
      setStockOptions(_.get(response, 'data.results', []).map(el => ({
        ...el, value: el.id, label: el.code,
      })))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  const fetchPlantation = async (trac) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PLANTATIONS.PLANTATIONS}`, {
        customer: _.get(customer, 'id', null),
        status: 'approved',
        traceability : trac === PRODUCT_TRACEABILITY.FSC ? PLANTATION_TRACEABILITY.FSC : null,
        page_size: 50,
      })
      const results = _.get(response, 'data.results', [])
      if (!plantationDetail) {
        setTicketData({
          ...ticketData,
          plantation_sources: results.map(plantation => ({
            plantation: _.get(plantation, 'id', null),
            plantation_code: _.get(plantation, 'code', null),
            overall_area: _.get(plantation, 'overall_area', null),
            rubber_area_rai: _.get(plantation, 'rubber_area_rai', null),
            used_dry_rubber_weight_in_year: _.get(plantation, 'used_dry_rubber_weight_in_year', 0),
            avg_dryweight_per_year : _.get(plantation, 'avg_dryweight_per_year', 0)
          }))
        })
      } else {
        let newSources = plantationDetail.plantation_source.map(source => {
          const found = results.find(result => result.id === source.id)
          let newData = {
            ...source,
            plantation: null,
            plantation_code: '',
            overall_area: 0,
            rubber_area_rai: 0,
            used_dry_rubber_weight_in_year: 0,
            avg_dryweight_per_year : 0,
          }
          if (found) {
            // ค่า used_dry_rubber_weight_in_year เปลี่ยนไปเมื่อ save ใหม่เสมอ
            // ดังนั้น ต้องลบกับ น้ำหนักจาก plantation source ปัจจุบันที่เป็นจะทำให้คำนวนไม่เพี้ยน
            const currDryWeight = _.get(ticketData, 'extra_details.drc', 0) * _.get(source, 'weight', 0) / 100
            newData = {
              ...newData,
              plantation: _.get(found, 'id', null),
              plantation_code: _.get(found, 'code', ''),
              overall_area: _.get(found, 'overall_area', 0),
              rubber_area_rai: _.get(found, 'rubber_area_rai', 0),
              used_dry_rubber_weight_in_year: _.get(found, 'used_dry_rubber_weight_in_year', 0) - currDryWeight,
              avg_dryweight_per_year: _.get(found, 'avg_dryweight_per_year', 0),
            }
          }
          return newData
        })
        setTicketData({
          ...ticketData,
          plantation_sources: newSources
        })
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const fetchTicket = async (id) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_WEIGHT.WEIGHT_TICKET}${id}`);
      // Prepare data before set fields
      let data = response.data
      if (data && (data.in_weight !== null) && (data.in_weight !== ""))
        data["in_weight"] = parseFloat(data.in_weight).toFixed(2);
      if (data && (data.out_weight !== null) && (data.out_weight !== ""))
        data["out_weight"] = parseFloat(data.out_weight).toFixed(2);
      form.setFieldsValue(response.data)
      setTicketData({...response.data})
      setPlantationDetail({plantation_source : _.get(response, 'data.plantation_sources', []).map(plantation => ({
          'id': _.get(plantation, 'plantation', null),
          'cutting_days': _.get(plantation, 'cutting_days', 1),
          'weight': _.get(plantation, 'weight', 0),
        }))})
      setCustomerFilterParams({...getCustomerParams(_.get(response, 'data.direction', null))})
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const fetchRef = async (id) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_WEIGHT.WEIGHT_TICKET_INCOMING}${id}`);
      const { customer_ref, product_ref, vehicle_ref } = _.get(response, 'data', {});
      form.setFieldValue('customer', customer_ref)
      form.setFieldValue('product', product_ref)
      form.setFieldValue('vehicle', vehicle_ref)
      setIsCustomerInitial(customer_ref ? true : false);
      setIsProductInitial(product_ref ? true : false);
      setIsVehicleInitial(vehicle_ref ? true: false);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const fetchDELRef = async (id) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_DELIVERY.DELIVERY_OUTGOING}${id}`);
      const { customer_ref, product_ref, vehicle_ref, driver_name, driver_phone, vehicle_license_plate } = _.get(response, 'data', {});
      form.setFieldValue('customer', customer_ref)
      form.setFieldValue('product', product_ref)
      if (vehicle_ref) {
        form.setFieldValue('vehicle', vehicle_ref)
        setIsVehicleInitial(vehicle_ref ? true: false);
      } else {
        form.setFieldValue(['driver', 'license_plate_number'], vehicle_license_plate)
      }
      form.setFieldValue(['driver', 'driver_name'], driver_name)
      form.setFieldValue(['driver', 'phone'], driver_phone)
      setIsCustomerInitial(customer_ref ? true : false);
      setIsProductInitial(product_ref ? true : false);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const cleanData = (data) => {
    // clean invalid data such as stockrefs when empty
    let newData = data;
    newData['stock_refs'] = _.get(data, 'stock_refs', []).filter(
      ref => _.get(ref, 'stock', null) ? true : false
    )
    newData['plantation_sources'] = _.get(data, 'plantation_sources', []).filter(e => {
      const found = _.get(ticketData, 'plantation_sources', []).find(source => source.plantation == e.plantation)
      if (found && getSourceInvalid(found)) {
        return false
      }
      return true
    })
    return newData
  }

  const validateWeight = (data) => {
    if (ticketDirection == WEIGHT_TICKET_DIRECTION.BUY && _.get(data, 'in_weight', 0) <=0 ) {
      return false
    } 
    return true
  }

  const submitTicket = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const data = await form.validateFields();
      if(cashierID)
        data['cashier'] = cashierID
      _.get(data, 'plantation_sources', []).map((plant,idx) => {
        if(!plant.cutting_days){
          data.plantation_sources[idx]["cutting_days"] = 1;
        }
        if(!plant.weight){
          data.plantation_sources[idx]["weight"] = 0;
        }
      })
      if(validateWeight(data)){
        if (id == 'add') {
          const response = await POST(URL_WEIGHT.WEIGHT_TICKET, cleanData(data));
          navigate(`${PATH.SCALE}/${_.get(response, 'data.id', '')}`, { replace: true });
        } else {
          data['note'] = note ? note : null
          await PUT(`${URL_WEIGHT.WEIGHT_TICKET}${id}/`, cleanData(data));
          setAddNoteOpen(false)
        }
        window.location.reload();
      } else {
        setErrorMessages(t("scale.no_weight_warning"))
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  // const addTicketNote = async () => {
  //   setLoading(true);
  //   setErrorMessages(null);
  //   try {
  //     const response = await PATCH(`${URL_WEIGHT.WEIGHT_TICKET}${id}/`, { note: note });
  //     setAddNoteOpen(false)
  //     fetchTicket(id);
  //   } catch (error) {
  //     setErrorMessages(error.errorMessages);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const deleteTicket = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await DELETE(`${URL_WEIGHT.WEIGHT_TICKET}${id}/`)
      setDeleteOpen(false)
      navigate(PATH.SCALE);
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleScale = (direction) => {
    form.setFieldValue(`${direction}_weight`, _.get(scaleWindowRef, 'current.weight', 0));
    form.setFieldValue(`${direction}_datetime`, moment().format());
  }

  // price product change handler
	const handleRecalculateDRC = (pricePerUnit, grossPricePerUnit) => {
    let result;
    if (pricePerUnit <= 0) {
      result = 100;
    } else {
      result = ((grossPricePerUnit * 100) / (pricePerUnit > 0 ? pricePerUnit : 0)).toFixed(2);
    }
    form.setFieldValue(['extra_details', 'drc'], isNaN(result) ? 0 : result)
	}

	const handleRecalculateGrossPricePerUnit = (pricePerUnit, drc) => {
    if (!_.get(product, 'need_drc', false)) {
      return;
    }
    const result = ((pricePerUnit * drc / 100)).toFixed(2);
    form.setFieldValue('gross_price_per_unit', isNaN(result) ? 0 : result);
	}
  // end of price product change handler

  // if customer is update and is farmer then fetch plantation
  React.useEffect(() => {
    // if (!isPaid && ticketDirection == WEIGHT_TICKET_DIRECTION.BUY && product) {
    //   fetchPlantation(product.traceability);
    // }

    // console.log(customerType, ticketDirection)
    if(!isPaid)
      if(
        (customerType == CUSTOMER_TYPE.GENERAL) ||
        (
          customerType != CUSTOMER_TYPE.FARMER &&
          ticketDirection == WEIGHT_TICKET_DIRECTION.BUY &&
          !ticketRef
        ) ||
        (
          customerType == CUSTOMER_TYPE.FACTORY &&
          ticketDirection == WEIGHT_TICKET_DIRECTION.SELL &&
          !delRef
        )
      ){
        setProductFilterParams({traceability : PRODUCT_TRACEABILITY.NONE })
        if(product && product.traceability !== null ){
          form.setFieldValue('product', null)
        }
      } else if (customerType == CUSTOMER_TYPE.FARMER && customer.is_fsc == false) {
        setProductFilterParams({traceability : [PRODUCT_TRACEABILITY.CT, PRODUCT_TRACEABILITY.NONE] })
        if(product && product.traceability === PRODUCT_TRACEABILITY.FSC ){
          form.setFieldValue('product', null)
        }
      } 
      else {
        setProductFilterParams(null)
      }

    if(isPaid)
      setProductFilterParams(null)
  }, [customer])

  /** Handle plantation prgress bar **/
  const handelPlantaionOver = () => {
    //============== Temporary disable for backward insert data ==============
    // if (!ticketData || !ticketData.plantation_sources) 
    //   return;
    
    const isOver = ticketData.plantation_sources
      .filter(plant => !getSourceInvalid(plant))
      .some(plant => plant.isOver)
    setIsLatexOver(isOver)
  }

  const handleChangePercent = (value, targetPlant, idx, type) => {
    if (!ticketData || !ticketData.plantation_sources) 
      return;

    let plantation = ticketData.plantation_sources.find(plant => plant.plantation === targetPlant);
    if (plantation) {
      if (type == "cutting_days") {
        plantation["cutting_days"] = value ? value : 1
      } 
      else if (type == 'weight') {
        plantation["weight"] = value ? value : 0
      }
    }

    if (product && product.need_drc) {
      const percenData = getPlantationPercentage(idx)
      plantation["percentage"] = percenData.percentage
      plantation["isOver"] = percenData.isOver
      plantation["maxDry"] = percenData.maxDry
      plantation["totalDry"] = percenData.totalDry
    }
    // Re-render
    handelPlantaionOver();
    setTrigger(!trigger)
  }
  
  // value Percentage
  const getPlantationPercentage = (idx) => {
    const plant = _.get(ticketData, `plantation_sources.${idx}`, null)
    const currentDRC = parseFloat(drc ? drc : 0)
    const plantWeight = parseFloat(_.get(plant, "weight", 0))
    const maxDry = parseFloat(_.get(plant, "avg_dryweight_per_year", 0))
    const currentDry = (plantWeight != 0) ? (plantWeight * currentDRC / 100) : 0
    const yearDry = parseFloat(_.get(plant, "used_dry_rubber_weight_in_year", 0))
    const totalDry = yearDry + currentDry
    const percentage = (maxDry != 0) ? (totalDry * 100 / maxDry) : 0
    return { 
      percentage : parseFloat(percentage).toFixed(0) , 
      isOver : percentage > 100 ? true : false, 
      maxDry: parseFloat(maxDry).toFixed(2), 
      totalDry: parseFloat(totalDry).toFixed(2) 
    }
  }

  const reloadPlantationLimit = () => {
    if (!ticketData || !ticketData.plantation_sources) 
        return;

    if (product && product.need_drc) {
      ticketData.plantation_sources.map((plantation, idx) => {
        const percenData = getPlantationPercentage(idx)
        plantation["percentage"] = percenData.percentage
        plantation["isOver"] = percenData.isOver
        plantation["maxDry"] = percenData.maxDry
        plantation["totalDry"] = percenData.totalDry
      });
      // Re-render
      handelPlantaionOver();
      setTrigger(!trigger)
    }
  }

  const getSourceInvalid = (source) => {
    return Number(_.get(source, 'used_dry_rubber_weight_in_year', 0)) > Number(_.get(source, 'avg_dryweight_per_year'))
  }
  /** END: plantation prgress bar **/

  const getStockWeight = (name) => {
    let target = stockOptions.find(st => st.id == form.getFieldValue(['stock_refs', name, 'stock']))
    if (WEIGHT_TICKET_DIRECTION.SELL) {
      return `ปัจจุบัน: ${formatComma(_.get(target, 'weight'))} ${t(UNIT.KILOGRAM)}`
    }
  }

  const getMaxWeight = (name) => {
    let target = stockOptions.find(st => st.id == form.getFieldValue(['stock_refs', name, 'stock']))
    return ticketDirection == WEIGHT_TICKET_DIRECTION.SELL ? _.get(target, 'weight', Number.MAX_SAFE_INTEGER) : Number.MAX_SAFE_INTEGER
  }

  const printReceipt = async (ticket) => {
    if (window.JSInterface) {
      await window.JSInterface.printReceipt(
        ticket.code,
        ticket.direction === "buy" ? "รับซื้อสินค้า" : "ขายสินค้า",
        ticket.product_name,
        ticket.customer_name,
        `${ticket.vehicle_license_plate} (${_.get(VEHICLE_TYPE_TRANSLATION, _.get(vehicle, 'type', ''))})`,
        user.display_name,
        JSON.stringify(ticket.plantation_sources.map(ps => ({
          "code": ps.plantation_code,
          "weight": formatComma(ps.weight),
        }))),
        formatComma(ticket.net_weight),
        formatComma(ticket.extra_details['drc']),
        formatComma(ticket.dry_weight),
        formatComma(ticket.gross_price_per_unit),
        formatComma(ticket.price)
      )
    }
  }

  // if product is updated then update form for price per unit and some extra details
  React.useEffect(() => {
    if (product) {
      const pricePerUnit = _.get(product, 'price_per_unit', 0)
      if (!form.getFieldValue('price_per_unit')) {
        form.setFieldValue('price_per_unit', pricePerUnit);
      }
      // set default gross price per unit when there is drc
      if (!form.getFieldValue('gross_price_per_unit')) {
        if (_.get(product, 'need_drc', false)) {
          handleRecalculateGrossPricePerUnit(pricePerUnit, INITIAL_DRC)
        } else {
          form.setFieldValue('gross_price_per_unit', pricePerUnit)
        }  
      }
      form.setFieldValue('traceability', _.get(product, 'traceability', null));
      setTicketData({
        ...ticketData,
        traceability: _.get(product, 'traceability', null)
      })

      // fetch ongoing stock base on product
      fetchStock(product.id)
    }
  }, [product])

  React.useEffect(() => {
    if (customer && product) {
      fetchPlantation(_.get(product, 'traceability', null))
    }
  }, [product, customer])

  // if vehicle is updated then fetch customer
  React.useEffect(() => {
    if (_.get(vehicle, 'owner', null) && !searchParams.get('customer') && !ticketRef && !delRef && isCreate) {
      form.setFieldValue('customer', _.get(vehicle, 'owner', null))
    }
    if (ticketDirection == WEIGHT_TICKET_DIRECTION.SELL && customerType == CUSTOMER_TYPE.FACTORY) {
      form.setFieldValue(['driver', 'vehicle_license_plate'], _.get(vehicle, 'license_plate', ''))
    }
  }, [vehicle])

  React.useEffect(() => {
    // if form is in create mode then inital data base on menu is selected
    if (isCreate) {
      if (searchParams.get('customer')) {
        form.setFieldValue('customer', searchParams.get('customer'))
        setIsCustomerInitial(true);
      }
      if (searchParams.get('product')) {
        form.setFieldValue('product', searchParams.get('product'))
        setIsProductInitial(true)
      }
      if (searchParams.get('ref')) {
        form.setFieldValue('ref', searchParams.get('ref'))
        fetchRef(searchParams.get('ref'))
      }
      if (searchParams.get('del_ref')) {
        form.setFieldValue('del_ref', searchParams.get('del_ref'))
        fetchDELRef(searchParams.get('del_ref'))
      }
      if (searchParams.get('stock')) {
        form.setFieldValue('stock_ref', [searchParams.get('stock')])
      }
      // set initial mode based on query string
      form.setFieldValue('direction', searchParams.get('direction'))
      form.setFieldValue('input_type', searchParams.get('input_type') ? searchParams.get('input_type') : INPUT_TYPE.WEIGHT)
      setTicketData({
        ...ticketData,
        direction: searchParams.get('direction'),
        input_type: searchParams.get('input_type'),
      });
      setCustomerFilterParams(getCustomerParams(searchParams.get('direction')))
    } else {
      fetchTicket(id);
    }
  }, [id])

  React.useEffect(() => {
    if (drc !== undefined)
      reloadPlantationLimit();

  }, [drc]);

  React.useEffect(() => {
    // Display plantations limit
    reloadPlantationLimit();
    setNote(_.get(ticketData, 'note', ''))
    if (!_.get(ticketData, 'stock_refs', []).length) {
      form.setFieldValue('stock_refs', [{stock: null, amount: 0}])
    }
  }, [ticketData])

  React.useEffect(() => {
    // auto complete other fields when in mode buy farmer
    let totalWeight = 0
    if (Array.isArray(plantationWatch)) {
      totalWeight = plantationWatch.reduce((prev, curr) => prev + Number(_.get(curr, 'weight', 0)), Number(0))
    }
    if (totalWeight => 0) {
      form.setFieldValue(manualInputWeightFields, totalWeight);
      form.setFieldValue(['stock_refs', 0, 'weight'], totalWeight);
    }
  }, [plantationWatch])

  React.useEffect(() => {
    let totalWeight = 0
    if (Array.isArray(stockRefsWatch)) {
      totalWeight = stockRefsWatch.reduce((prev, curr) => prev + Number(_.get(curr, 'weight', 0)), Number(0))
    }
    if (totalWeight > 0) {
      form.setFieldValue(manualInputWeightFields, totalWeight);
    }
  }, [stockRefsWatch])

  return (
    <Layout style={{ minHeight: '100vh', background: BG_LAYOUT_COLOR }}>
      <HeaderScreen title={isManual ? t('scale.manage_product') : t('scale.ticket')}/>
      <Spin spinning={loading}>
        <Row gutter={[16]}>
          <Col style={{ padding: '1rem', background: BG_LAYOUT_COLOR }} lg={12} md={24}>
            <Card style={{ minHeight: '100%' }}>
              { (!isCreate && editable) && 
                <Space style={{ float: 'right' }}>
                  <Button icon={<FormOutlined/>} onClick={() => setAddNoteOpen(true)}>{t("scale.note")}</Button>
                  {/* <Button icon={<HistoryOutlined/>} onClick={() => setAddNoteOpen(true)}>ประวัติ</Button> */}
                  <Button danger icon={<DeleteOutlined/>} onClick={() => setDeleteOpen(true)}>{t("scale.delete")}</Button>
                </Space>
              }
              <Header title={isManual ? t(`scale.form_${ticketDirection}`) : t('scale.form')}/>
              <ErrorMessages message={errorMessages}/>
              
              <Form form={form} style={{ marginTop: '1rem' }} layout='vertical'>
                <Form.Item hidden name='station' initialValue={user.id}/>
                <Form.Item hidden name='ref'/>
                <Form.Item hidden name='del_ref'/>
                <Form.Item hidden name='input_type'/>
                <Form.Item hidden name='direction'/>
                <Form.Item hidden name='traceability'/>
                <Row gutter={[16]}>
                  <Col xl={12} xs={24}>
                    <Form.Item name='product' label={t('product')} rules={[{ required: true }]}>
                      <CustomSearchSelect
                        url={URL_CUSTOMER.PRODUCT}
                        params={productFilterParams}
                        label='name'
                        readOnly={!editable || isProductInitial}
                        labelRenderer={productLabelRender}
                        handleChange={setProduct}/>
                    </Form.Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Form.Item name='vehicle' label={t('license_plate')} rules={[{ required: true }]}>
                      <CustomSearchSelect
                        ref={vehicleRef}
                        url={URL_CUSTOMER.VEHICLE}
                        label='license_plate'
                        extra={
                          <Space.Compact style={{ width: '100%' }} >
                            <Input
                              disabled
                              value={_.get(VEHICLE_TYPE_TRANSLATION, _.get(vehicle, 'type', ''), '-')} />
                            <ColorButton disabled={isPaid} onClick={() => setAddVehicleOpen(true)}><PlusOutlined/></ColorButton>
                          </Space.Compact>
                        }
                        readOnly={!editable || isVehicleInitial}
                        handleChange={setVehicle}/>
                    </Form.Item>
                  </Col>
                  <Col md={8} xs={24}>
                    <Form.Item name='price_per_unit' label={t('Opening_market')}>
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        min={0}
                        addonAfter={`${t("baht")} / ${t("kilo_gram")}`}
                        onFocus={evt => evt.target.select()}
                        onChange={(value) => {
                          handleRecalculateGrossPricePerUnit(value, form.getFieldValue(['extra_details', 'drc']))
                        }}/>
                    </Form.Item>
                  </Col>
                  <Col md={8} xs={24}>
                    <Form.Item name='gross_price_per_unit' label={t('scale.real_price_buy')}>
                      <InputNumber
                        readOnly={!editable}
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        addonAfter={`${t("baht")} / ${t("kilo_gram")}`}
                        min={0}
                        onFocus={evt => evt.target.select()}
                        onChange={(value) => handleRecalculateDRC(form.getFieldValue('price_per_unit'), value)}/>
                    </Form.Item>
                  </Col>
                  { _.get(product, 'need_drc', false) && (
                    <Col xl={8} xs={24}>
                      <Form.Item name={['extra_details', 'drc']} label='%DRC' initialValue={INITIAL_DRC}>
                        <InputNumber
                          readOnly={!editable}
                          style={{ width: '100%' }}
                          max={100}
                          min={0}
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          addonAfter='%'
                          onFocus={evt => evt.target.select()}
                          onChange={(value) => {
                            handleRecalculateGrossPricePerUnit(form.getFieldValue('price_per_unit'), value)
                          }}/>
                        </Form.Item>
                    </Col>
                  )}
                </Row>

                <Divider>{t('scale.customer_detail')}</Divider>
                <Row gutter={[16]}> 
                  <Form.Item hidden name='customer'/>
                  <Col xl={10} xs={24}>
                    <Form.Item label={t('customer_name')} name='customer' rules={[{ required: true }]}>
                      <CustomSearchSelect
                        url={URL_CUSTOMER.CUSTOMER}
                        params={customerFilterParams}
                        label='full_name'
                        readOnly={!editable || isCustomerInitial}
                        handleChange={setCustomer} />
                    </Form.Item>
                  </Col>
                  <Col xl={14} xs={24}>
                    <Form.Item label={t('address')}>
                      <Input disabled value={`${_.get(customer, 'address', '')} ${_.get(customer, 'sub_district_detail', '')}`}/>
                    </Form.Item>
                  </Col>
                </Row>
                { (productNeedTraced 
                  && ticketDirection == WEIGHT_TICKET_DIRECTION.SELL 
                  && (product && product.need_chemical )) && (
                  <div>
                    <Divider>{t("scale.chemicals_used_to_maintain_rubber_condition")}</Divider>
                    <Row gutter={[16]} align='middle'>
                      <Col md={4} xs={24}>{t("ammonia")}</Col>
                      <Col md={10} xs={24}>
                        <Form.Item label={t('scale.concentration')} name={['chemical', 'nh3_percentage']} rules={[{ required: true }]}>
                          <Select
                            readOnly={!editable}
                            options={[
                              { key: '12', value: 12, label: '12%' },
                              { key: '15', value: 15, label: '15%' },
                              { key: '20', value: 20, label: '20%' },
                            ]}/>
                        </Form.Item>
                      </Col>
                      <Col md={10} xs={24}>
                        <Form.Item name={['chemical', 'nh3_amount']} label={t('weight')} rules={[{ required: true }]}>
                          <InputNumber
                            readOnly={!editable}
                            style={{ width: '100%' }}
                            onFocus={evt => evt.target.select()}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            addonAfter={t(UNIT.KILOGRAM)}/>
                        </Form.Item>
                      </Col>
                      <Col md={4} xs={24}>{t("chemicals")}</Col>
                      <Col md={10} xs={24}>
                        <Form.Item label={t('type')} name={['chemical', 'chemical_type']} rules={[{ required: true }]}>
                          <Select
                            readOnly={!editable}
                            options={CHEMICAL_TYPE_OPTIONS}/>
                        </Form.Item>
                      </Col>
                      <Col md={10} xs={24}>
                        <Form.Item name={['chemical', 'chemical_amount']} label={t('weight')} rules={[{ required: true }]}>
                          <InputNumber
                            readOnly={!editable}
                            style={{ width: '100%' }}
                            onFocus={evt => evt.target.select()}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            addonAfter={t(UNIT.KILOGRAM)}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}
                { (productNeedTraced && ticketDirection == WEIGHT_TICKET_DIRECTION.BUY && customerType == CUSTOMER_TYPE.FARMER) && (
                  <div>
                    <Divider>{t("plantation")}</Divider>
                    { _.get(ticketData, 'plantation_sources', []).map((plantation_source, idx) => (
                      <Row gutter={[16, 8]} key={`plantation-sources-${idx}`}>
                        <Form.Item
                          hidden
                          name={['plantation_sources', idx, 'plantation']}
                          initialValue={_.get(plantation_source, 'plantation', null)}/>
                        <Col xl={8} xs={24}>
                          <Space.Compact style={{ width: '100%' }}>
                            <Input
                              style={{textAlign: "right"}}
                              disabled={getSourceInvalid(plantation_source)}
                              addonBefore={_.get(plantation_source, 'plantation_code', '-')}
                              addonAfter={t("rai")}
                              value={`${formatComma(_.get(plantation_source, 'rubber_area_rai', 0), 4)}`}/>
                          </Space.Compact>
                        </Col>
                        <Col xl={8} xs={24}>
                          <Form.Item name={['plantation_sources', idx, 'weight']}>
                            <InputNumber
                              readOnly={!editable}
                              disabled={getSourceInvalid(plantation_source)}
                              style={{ width: '100%' }}
                              onFocus={evt => evt.target.select()}
                              onChange={(value) => {
                                handleChangePercent(value, plantation_source.plantation, idx, 'weight')
                              }}
                              placeholder={t("weight")}
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              addonAfter={t(UNIT.KILOGRAM)}/>
                          </Form.Item>
                        </Col>
                        {product && product.need_cutting_days && (
                          <Col xl={8} xs={24}>
                            <Form.Item name={['plantation_sources', idx, 'cutting_days']}>
                              <InputNumber
                                readOnly={!editable}
                                disabled={getSourceInvalid(plantation_source)}
                                style={{ width: '100%' }}
                                onFocus={evt => evt.target.select()}
                                onChange={(value) => {
                                  handleChangePercent(value, plantation_source.plantation, idx, 'cutting_days')
                                }}
                                placeholder={t("scale.cutting_days")}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                addonAfter={t('days')}/>
                            </Form.Item>
                          </Col>
                        )} 
                        {product && product.need_drc && (
                          <Col span={24} style={{ marginBottom : 16}} >
                            <Progress 
                              percent={plantation_source && plantation_source.percentage ? plantation_source.percentage : 0}
                              status={plantation_source && plantation_source.isOver ? 'exception' : 'normal'}
                              strokeColor={{from: colorSuccess, to: colorError}}
                              size={['100%', 18]} />
                            
                            {plantation_source && plantation_source.totalDry && plantation_source.maxDry && (
                              <div style={{textAlign: "center", fontSize: 14}}>
                                {`${t("scale.dry_rubber")} ${formatComma(plantation_source.totalDry, 4)} Kgs / ${formatComma(plantation_source.maxDry, 4)} Kgs (${t("per_years")}) `}
                                <Popover title={t("scale.calculation_title")} content={
                                  <div>
                                    {t("scale.calculation_content_1")}
                                    <p>
                                      {t("scale.calculation_content_2")}
                                      <ul>
                                        <li>BPM 24 132.59 14 13 1.59% = 312 Kg/year</li>
                                        <li>GT 1 5.4275 1 1 0.07% = 276 Kg/year</li>
                                        <li>PB 235 111.335 15 12 1.34% = 376 Kg/year</li>
                                        <li>RRIM 600 7405.8875 785 476 88.89% = 289 Kg/year</li>
                                        <li>RRIT 251 676.6325 80 61 8.12% = 457 Kg/year</li>
                                      </ul>
                                    </p>
                                  </div>
                                }>
                                  <QuestionCircleOutlined />
                                </Popover>
                              </div>
                            )}
                          </Col>
                        )}
                      </Row>)
                    )}
                  </div>
                ) }
                { (ticketDirection == WEIGHT_TICKET_DIRECTION.SELL && customerType == CUSTOMER_TYPE.FACTORY) && (
                  <div>
                    <Divider>{t("scale.driver_detail")}</Divider>
                    <Row gutter={[16]}>
                      <Col sm={12} xs={24}>
                        <Form.Item label={t("scale.driver_name")} name={['driver', 'driver_name']} rules={[{ required: true }]}>
                          <AutoComplete
                            readOnly={!editable}
                            options={driversOptions.options}
                            onSearch={debounceDriverSearch}
                            onSelect={(__, option) => form.setFieldValue(['driver', 'phone'], _.get(option, 'phone', null))}>
                            <Input/>
                          </AutoComplete>
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item name={['driver', 'phone']} label={t('scale.driver_phone')} rules={[{ required: true }]}>
                          <Input readOnly={!editable} style={{ width: '100%' }}/>
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          name={['driver', 'license_plate_number']}
                          initialValue={_.get(vehicle, 'license_plate', '')}
                          label={t('scale.plate_no')} rules={[{ required: true }]}
                        >
                          <Input readOnly={!editable} style={{ width: '100%' }}/>
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item name={['driver', 'other_license_plate_number']} label={t('scale.plate_no_other')}>
                          <Input readOnly={!editable} style={{ width: '100%' }}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ) }
                { (product && !productNeedTraced && isManual) && (
                  <div>
                    <Divider>{(ticketDirection == WEIGHT_TICKET_DIRECTION.SELL) ? t('scale.sell_weight') : t('scale.buy_weight')}</Divider>
                    { (ticketDirection == WEIGHT_TICKET_DIRECTION.SELL) ? (
                        <div>
                          <Form.Item name={"out_weight"}>
                            <Input
                              type={"number"}
                              min={0}
                              addonAfter={t(UNIT.KILOGRAM)}
                              disabled={isPaid ? true : false}/>
                          </Form.Item>
                          <Form.Item hidden name={"in_weight"} initialValue={0} />
                        </div>
                      ) : (
                        <div>
                          <Form.Item name={"in_weight"}>
                            <Input
                              type={"number"}
                              min={0}
                              addonAfter={t(UNIT.KILOGRAM)}
                              disabled={isPaid ? true : false}/>
                          </Form.Item>
                          <Form.Item hidden name={"out_weight"} initialValue={0} />
                        </div>
                    ) }
                  </div>
                ) }
                { productNeedTraced && (
                  <div>
                    <Divider>{ticketDirection == WEIGHT_TICKET_DIRECTION.BUY ? t('scale.import_lot') : t('scale.export_lot') }</Divider>
                    <Form.List name='stock_refs' initialValue={[{ stock: null, amount: 0 }]}>
                      { (fields, { add, remove }) => (
                        <div>
                          { fields.map(({ key, name, ...restField }) => (
                            <Row gutter={16} key={key}>
                              <Col xl={12} xs={24}>
                                <Form.Item  {...restField} hidden name={[name, 'stock']}/>
                                <Form.Item label='' name={[name, 'stock_code']} rules={[{ required: true }]}>
                                  <AutoComplete
                                    options={stockOptions.filter(option => {
                                      // exclude selected value
                                      const selectedList = form.getFieldValue(['stock_refs']).map(e => _.get(e, 'stock', null))
                                      return !selectedList.includes(_.get(option, 'value', null)) 
                                    })}
                                    placeholder={t('scale.choose_lot')}
                                    onSearch={() => {
                                      form.setFieldValue(['stock_refs', name, 'stock'], null);
                                    }}
                                    onSelect={(value, option) => {
                                      form.setFieldValue(['stock_refs', name, 'stock'], value);
                                      form.setFieldValue(['stock_refs', name, 'stock_code'], _.get(option, 'code'));
                                      if (ticketDirection == WEIGHT_TICKET_DIRECTION.SELL) {
                                        setCloseStockConfirmTarget(name)
                                      }
                                    }}
                                    onBlur={() => {
                                      if (!form.getFieldValue(['stock_refs', name, 'stock'])) {
                                        form.setFieldValue(['stock_refs', name, 'stock_code'], null);
                                      }
                                    }}>
                                      <Input suffix={<DownOutlined />} addonAfter={editable ? getStockWeight(name) : undefined}/>
                                    </AutoComplete>
                                </Form.Item>
                              </Col>
                              <Col xl={(name > 0 && editable) ? 10 : 12} xs={24}>
                                <Form.Item
                                  {...restField}
                                  label=''
                                  name={[name, 'weight']}
                                  rules={[{ required: true }, { validator: moreThanZero }]}>
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={
                                      productNeedTraced && 
                                      ticketDirection == WEIGHT_TICKET_DIRECTION.BUY && 
                                      customerType == CUSTOMER_TYPE.FARMER 
                                    }
                                    max={getMaxWeight(name)}
                                    min={0}
                                    readOnly={!editable}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    onFocus={evt => evt.target.select()}
                                    addonBefore={
                                      (form.getFieldValue(['stock_refs', name, 'auto_close']) && editable) ? (
                                        <Popover content={t('scale_close_the_lot_upon_completion')}>
                                          <Tag color='warning' icon={<ExclamationCircleOutlined/>} />
                                        </Popover>
                                      ) : undefined                                    }
                                    addonAfter={t(UNIT.KILOGRAM)}
                                    initialValue={0}/>
                                </Form.Item>
                              </Col>
                              { editable && <Col span={2}>
                                { name != 0 && <Button style={{ alignItem: 'center' }} danger icon={<CloseOutlined/>} onClick={() => remove(name)}/> }
                              </Col> }
                            </Row>
                          )) }
                          <div style={{ textAlign: 'center' }}>
                            { (ticketDirection == WEIGHT_TICKET_DIRECTION.SELL && fields.length < stockOptions.length) && (
                              <Button icon={<PlusOutlined/>} onClick={() => add()}>{t('scale.add')}</Button>
                            )}
                          </div>
                        </div>
                      ) } 
                    </Form.List>
                  </div>
                ) }
              </Form>
            </Card>
          </Col>
          <Col style={{ padding: '1rem', background: BG_LAYOUT_COLOR }} lg={12} md={24}>
            <Card style={{ height: '100%' }}>
              <Header title={t('scale.weight_information')}/>
                <Form form={form} layout='vertical' style={{ marginTop: '1rem' }}>
              { !isManual && (
                <div>
                    {/*  ใน client electron เปิด component ด้วย ref scaleWindowRef */}
                    {/* <ScaleWindow ref={scaleWindowRef} style={{ marginBottom: '1rem' }}/> */}
                    <ScaleField
                      // showButton // ใน electron เอา props ขึ้นมา
                      direction='in'
                      title={t('incoming_weighing')}
                      form={form}
                      icon={<DownloadOutlined/>}
                      handleScale={() => handleScale('in')}
                      handleTimeStamp={() => form.setFieldValue('in_datetime', moment().format())}/>
                    <ScaleField
                      // showButton // ใน electron เอา props ขึ้นมา
                      direction='out'
                      title={t('export_weighing')}
                      icon={<UploadOutlined/>}
                      handleScale={() => handleScale('out')}
                      handleTimeStamp={() => form.setFieldValue('out_datetime', moment().format())}/>
                </div>
              )}
              {isManual && (
                <Card 
                  title={`${t("scale.total_weight")} (${t(UNIT.KILOGRAM)})`} 
                  style={{marginTop: 15, textAlign: "center"}}
                  headStyle={{background: colorPrimary, color: "#fff"}}
                  bodyStyle={{background: "#000", padding: 16}}>
                  {(ticketDirection === WEIGHT_TICKET_DIRECTION.SELL) ? (
                    <div>
                      <Form.Item name={"out_weight"} style={{marginBottom: 0}}>
                      <Input 
                          type={"number"}
                          min={0}
                          readOnly
                          bordered={false}
                          onWheel={(e) => e.target.blur()}
                          onFocus={(e) => e.target.select()}
                          style={weightInputStyle}
                          disabled={isPaid ? true : false}/>
                      </Form.Item>
                      <Form.Item hidden name={"in_weight"} initialValue={0} />
                    </div>
                  ) : (
                    <div>
                      <Form.Item name={"in_weight"} style={{marginBottom: 0}}>
                        <Input 
                          type={"number"}
                          min={0}
                          readOnly
                          bordered={false}
                          onWheel={(e) => e.target.blur()}
                          onFocus={(e) => e.target.select()}
                          style={weightInputStyle}
                          disabled={isPaid ? true : false}/>
                      </Form.Item>
                      <Form.Item hidden name={"out_weight"} initialValue={0} />
                    </div>
                  )}
                </Card>
              )}
              </Form>

              <Divider>{isManual ? t("scale.summary") : t("scale.weighing_summary")}</Divider>
              { _.get(ticketData, 'direction', '') && (
                <div style={{ textAlign: 'center' }}>
                  <Typography.Title level={4}>
                    {t("type")}: {isManual ? t(`scale.product_${ticketDirection}`) : t(`scale.weighting_${ticketDirection}`)}
                    <span style={{ color: TYPE_FARMER }}>
                      { _.get(ticketData, 'traceability', null) ? ` [${_.get(ticketData, 'traceability', '').toUpperCase()}] `: '' }
                    </span>
                  </Typography.Title>
                </div>
              ) }
              { _.get(ticketData, 'code', false) && (
                <div style={{ textAlign: 'center' }}>
                  <Typography.Title level={4} style={{ marginTop: 0, color: colorInfo }}>
                   {t("scale.code")}: {_.get(ticketData, 'code', false)}
                  </Typography.Title>
                </div>
              )}
              <Form style={{ marginTop: '1rem' }} form={form} layout='horizontal' labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
                <Form.Item label={t('scale.gross_weight')} name='net_weight'>
                  <InputNumber
                    style={{ width: '100%' }}
                    readOnly
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonBefore={
                      <Popover content={t('scale.weight_reading_from_the_scale')}>
                        <InfoCircleOutlined />
                      </Popover>
                    }
                    addonAfter={t(UNIT.KILOGRAM)}/>
                </Form.Item>
                <Form.Item label={t('scale.dry_weight')} hidden={!_.get(product, 'traceability', null)} name='dry_weight'>
                  <InputNumber
                    style={{ width: '100%' }}
                    readOnly
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonBefore={
                      <Popover content={t('scale.dry_weight_popup')}>
                        <InfoCircleOutlined />
                      </Popover>
                    }
                    addonAfter={t(UNIT.KILOGRAM)}/>
                </Form.Item>
                <Form.Item label={t('net_price')} name='price'>
                  <InputNumber
                    style={{ width: '100%' }}
                    readOnly
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    addonBefore={
                      <Popover content={t('scale.net_price_popup')}>
                        <InfoCircleOutlined />
                      </Popover>
                    }
                    addonAfter={t('baht')}/>
                </Form.Item>
              </Form>
              <div style={{ marginTop: '1rem' }}>
                <Row justify='center' gutter={[16, 16]}>
                  {isFinished && isPaid && window.JSInterface && ticketData &&
                    <Col lg={8} sm={12} xs={24}>
                      <ColorButton
                        block
                        type='primary'
                        override='#FFC300'
                        onClick={() => printReceipt(ticketData)}
                        icon={<PrinterOutlined />}>
                        {t("print_receipt")}
                      </ColorButton>
                    </Col>
                  }
                  { isFinished && 
                    <Col lg={8} sm={12} xs={24}>
                      { isPaid ? (
                        <ColorButton
                          block
                          type='primary'
                          override='#1677ff'
                          onClick={finishScreen}
                          icon={<ArrowLeftOutlined/>}>
                          {t("prev")}
                        </ColorButton>                    
                      ) : (
                        <ColorButton
                          block
                          type='primary'
                          override='#1677ff'
                          icon={<DollarOutlined/>}
                          onClick={() => setPaymentOpen(true)}
                          disabled={isLatexOver}>
                          {t("paid")}
                        </ColorButton>
                      )}
                    </Col>
                  }
                  { !isPaid && (
                    <Col lg={8} sm={12} xs={24}>
                      <ColorButton
                        block
                        type='primary'
                        override={colorSuccess}
                        onClick={submitTicket}
                        icon={<SaveOutlined/>}
                        disabled={isLatexOver}>
                        {t("save")}
                      </ColorButton>
                    </Col>
                  ) }
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Spin>

      <ScalePaymentModal
        open={paymentOpen}
        onClose={() => setPaymentOpen(false)}
        ticketData={ticketData}
        onSuccess={async () => {
          setPaymentOpen(false);
          await printReceipt(ticketData)
          finishScreen();
        }}/>

      <CarRegisterModal
        open={addVehicleOpen}
        onUpdated={(data) => {
          setAddVehicleOpen(false);
          form.setFieldValue('vehicle', _.get(data, 'id', null))
        }}
        onClose={() => {
          setAddVehicleOpen(false);
        }}/>

      <Modal open={deleteOpen} onCancel={() => setDeleteOpen(false)} onOk={deleteTicket} okText={t('delete')} okButtonProps={{ danger: true, icon: <DeleteOutlined/> }}>
        <Header title={t('scale.delete_ticket')}/>
        <ErrorMessages message={errorMessages}/>
        <div style={{ marginTop: '1rem' }}>
          {t('scale.confirm_delete_ticket')}
        </div>
      </Modal>

      <Modal
        open={closeStockConfirmTarget != null}
        // onOk={() => {
        //   form.setFieldValue(['stock_refs', closeStockConfirmTarget, 'auto_close'], true)
        //   setCloseStockConfirmTarget(null)
        // }}
        onCancel={() => {
          form.resetFields(['stock_refs', closeStockConfirmTarget, 'auto_close'], false)
          setCloseStockConfirmTarget(null)
        }}
        // okText='ปิดล็อต'
        // okButtonProps={{ danger: true }}
        // cancelText='ไม่ปิดล็อต'
        footer={null}
      >
        <Header title={t('scale.alert_close_lot')}/>
        <div style={{ marginTop: '1rem' }}>
          {t("scale.alert_close_lot_detail")}
        </div>
        <br/>
        <div style={{textAlign:'right'}}>
          <Space>
          <ColorButton 
          override={colorPrimary}
          onClick={() => {
          form.setFieldValue(['stock_refs', closeStockConfirmTarget, 'auto_close'], false)
          setCloseStockConfirmTarget(null)
          }}>
            {t("scale.not_close_lot")}
          </ColorButton>
          <ColorButton
            override={colorError}
            type='primary'
            onClick={()=>{form.setFieldValue(['stock_refs', closeStockConfirmTarget, 'auto_close'], true)
            setCloseStockConfirmTarget(null)}}
          >
            {t("scale.close_lot")}
          </ColorButton>
          </Space>
        </div>
      </Modal>

      <Modal open={addNoteOpen} onCancel={() => setAddNoteOpen(false)} onOk={submitTicket}>
        <Header title={t('note')}/>
        <ErrorMessages message={errorMessages}/>
        <Form.Item style={{ marginTop: '1rem' }}>
          <Input.TextArea placeholder={t('add_note')} value={note} onChange={(e) => setNote(e.target.value)}/>
        </Form.Item>
      </Modal>
    </Layout>
  )
}
