import React from "react";
import { Button, Modal, QRCode, Space, theme, Input, message } from "antd";
import { EyeOutlined } from '@ant-design/icons';
import { PATH } from "../../routes/CustomRoutes";
import ColorButton from "../common/ColorButton";
import { useTranslation } from 'react-i18next';


const url = window.location.host
export default function ShippingInfomationModa(props) {

  const { t, i18n } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();

  const {
    token: { colorError, colorWarning, colorInfo, colorPrimary }
  } = theme.useToken();

  const handleClick = () => {
    window.open(`${PATH.SHIPPING_INFOMATION}/${target}`)
    onClose();
  };
  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
    const {
        target,
        open,
        onClose,
      } = props;
      React.useEffect(()=>{
      },[target])
  return(
    <Modal
      title={"QR CODE"}
      open={open}
      footer={[]}
      onCancel={onClose}
      width={500}
      style={{textAlign:'center'}}>

      {contextHolder}
    
      <div id="myqrcode" style={{textAlign: "center", marginBottom: 16}}>
        <QRCode style={{ margin: "auto" }} icon={"/logo_txt.png"} errorLevel="H" value={`${url}${PATH.SHIPPING_INFOMATION}/${target}/`}> </QRCode>
      </div>

      
      <Input.Search 
        style={{ width: "100%", display: 'block', marginBottom: '10px' }} 
        addonBefore="URL"
        enterButton="Copy"
        onSearch={() => {
          navigator.clipboard.writeText(`${url}${PATH.SHIPPING_INFOMATION}/${target}/`)
          messageApi.open({
            type: 'success',
            content: 'URL has been copied to clipboard'
          })
        }}
        value={`${url}${PATH.SHIPPING_INFOMATION}/${target}/`} />

      <Space>
        <ColorButton
          onClick={downloadQRCode}>
            {t("download")}
        </ColorButton>
        <ColorButton 
          type='primary'
          override={colorInfo}
          icon={<EyeOutlined />}
          onClick={handleClick}>
            View
          </ColorButton>
      </Space>
    </Modal>
  )
}
