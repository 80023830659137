import { Button, ConfigProvider, theme } from 'antd';
import React from 'react';
import propTypes from "prop-types";
import { GET } from '../../frameworks/HttpClient';
import { EyeOutline } from 'antd-mobile-icons';


export default function ReportButton ({ children, override, ...props }) {
  const { token } = theme.useToken();
  const [loading, setLoading] = React.useState();
  const overrideColor = token[override] || override;
  const modifiedTheme = {
    token: {...token},
    components: {
      Button: { colorPrimary: overrideColor, algorithm: true }
    }
  }
  
  const handleDocumentDEL = async() => {
    setLoading(true);
  try {
    const response = await GET(props.url, false, true)
    if (props.url) { 
      setTimeout(() => {
        window.open(response.requestUrl);
        setLoading(false);
    })
    }
  } catch (error) {
    console.error('Error', error);
    setLoading(false);
  }
}; 

  return (
    <ConfigProvider theme={modifiedTheme}>
    { !children || (typeof children === 'function' && !children()) ? (
      <Button {...props} onClick={() => handleDocumentDEL()} loading={loading}/> 
    ) : (
      <Button {...props}  onClick={() => handleDocumentDEL()} loading={loading}>{children}</Button> 
    )}
  </ConfigProvider>
  
  // <Button onClick={() => handleDocumentDEL()} loading={loading}></Button>

  )
};
ReportButton.defaultProps = {
  children: () => null,
  override: '',
  url: '',
}

ReportButton.prototype = {
  children: propTypes.func,
  override: propTypes.string,
  url: propTypes.string,
}