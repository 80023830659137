import React from "react";
import { Button, Card, Col, Modal, Row, Input, theme, Radio, Image, Select, Spin, DatePicker, Alert } from "antd";
import Form from "antd/es/form/Form";
import { UploadOutlined } from '@ant-design/icons';
import Header from '../common/Header';
import { URL_PLANTATIONS, URL_COMMON } from "../../constants/urls";
import { POST, GET, PATCH } from "../../frameworks/HttpClient";
import { DATE_FORMAT, WORKER_TYPE, WORKER_TYPE_OPTIONS } from "../../constants/string";
import userImg from "../../assets/user.png";
import ImageEditorModal from "../common/ImageEditorModal";
import ImgCrop from 'antd-img-crop';
import _ from "lodash";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';


export default function WorkerModal(props) {
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading,setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [subdistricts, setSubdistricts] = React.useState([]);
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [uploadFile, setUploadFile] = React.useState(null)
  const [imageData, setImageData] = React.useState(null)
  const [trigger, setTrigger] = React.useState(false);
  const uploadRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  const {
    token: { colorPrimary, colorWarning },
  } = theme.useToken();

  const handleOk = async() => {
    setErrorMessages(null)
    setLoading(true)
    try {
      let data = await form.validateFields();
      if (props.plantId)
        data['plantation'] = props.plantId
      if (data.birthdate)
        data["birthdate"] = data.birthdate.format(DATE_FORMAT);
      if (data.sub_district) 
        data['sub_district'] = data.sub_district.value;
      if (imageData)
        data['card'] = imageData
      
      const userId = props.data ? props.data.id : null
      if (!userId) {
        // Create
        await POST(URL_PLANTATIONS.WORKER, data)
      } else {
        // Update
        await PATCH(`${URL_PLANTATIONS.WORKER}${userId}/`, data);
      }
      props.onUpdated();

    } catch (error) {
      setErrorMessages(error.errorMessages)
    } 
    finally {
      setLoading(false)
    }
  }

  const debounceSubdistrict = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );

  const fetchSubdistrict = async () => {
    setFetching(true);
    try {
      const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}`)
      setSubdistricts(response.data.results.map((item) => {
        return {
          ...item,
          label: item.display_name,
          value: item.subdistrict_id
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  // Manage UI after worker type changed
  const handleTypeChange = (e) => {
    const value = e.target.value
    if (value !== WORKER_TYPE.FOREIGN) {
      form.setFieldValue("nationality", "ไทย")
    } else {
      form.setFieldValue("nationality", "")
    }

    setTrigger(!trigger);
  }

  React.useEffect(() => {
    if (debouncedCode)
      fetchSubdistrict();
  }, [debouncedCode]);

  React.useEffect(() => {
    if (props.open) {
      const data = props.data;
      if (data) {
        form.setFieldsValue({
          ...data,
          birthdate: data.birthdate ? dayjs(data.birthdate) : null,
          sub_district: data.sub_district ? {value : data.sub_district, label : data.sub_district_detail} : null
        });
      } else {
        form.resetFields();
        form.setFieldValue("type", WORKER_TYPE.FOREIGN)
        setImageData(null)
      }
      setErrorMessages(null);
      setTrigger(!trigger)
    }
  }, [props.open])

  return(
    <Modal
      width={'80%'}
      open={props.open}
      confirmLoading={loading}
      okText={props.data ? t("edit") : t("add_contractor")}
      okButtonProps={{ style: {background: props.data ? colorWarning : colorPrimary}}}
      onOk={handleOk} 
      cancelText={t("cancel")}
      onCancel={props.onClose}>
      <Header title={t("rubber_tapping_contractor_information")}></Header>
      {errorMessages && 
        <Alert
          style={{marginTop: 16, textAlign: "left"}}
          message={errorMessages}
          type="error"
          showIcon/>
      }

      <Card style={{marginTop: 16}}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off">

          <Row gutter={16}>
            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name={"area_rai"}
                label={"จำนวนไร่ที่กรีด"}
                rules={[{ required: true }]} >
                <Input autoComplete='off'/>
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name={"owner_shared_amount"}
                label={"ส่วนแบ่งเจ้าของสวน"}>
                <Input autoComplete='off'/>
              </Form.Item>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name={"worker_shared_amount"}
                label={"ส่วนแบ่งคนกรีดยาง"} >
                <Input autoComplete='off'/>
              </Form.Item>
            </Col>

            <Col md={3} sm={24} xs={24}>
              <p>ประเภทแรงงาน</p>
            </Col>

            <Col md={21} sm={24} xs={24}>
              <Form.Item  
                name={"type"}
                style={{ marginTop : 10}}
                rules={[{ required: true }]} >
                <Radio.Group options={WORKER_TYPE_OPTIONS} onChange={handleTypeChange}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={8} sm={24} xs={24}>
              <Col md={24} sm={24} xs={24}>
                <Form.Item  
                  name={"nationality"}
                  label="สัญชาติ"
                  rules={[{ required: true }]} >
                    <Input autoComplete="off" disabled={(form.getFieldValue("type") !== WORKER_TYPE.FOREIGN)}/>
                </Form.Item>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <div style={{ textAlign: "center" }} className='section-wrapper'>
                  <Image
                    src={imageData ? imageData : (props.data && props.data.card) ? props.data.card : userImg}
                    height={180} 
                    width={"auto"} />
                  <div>
                    <Button 
                      style={{marginTop: 8}}
                      icon={<UploadOutlined />} 
                      onClick={() => uploadRef.current.click()}>
                        อัพโหลดรูป
                    </Button>
                  </div>
                </div>
              </Col>
            </Col>
            <Col md={16} sm={24} xs={24}>
              <Row gutter={16}>
                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"title"}
                    label="คำนำหน้า"
                    rules={[{ required: true }]} >
                    <Select
                      style={{ width: '100%' }}
                      options={[
                        { value: 'นาย', label: 'นาย' },
                        { value: 'นาง', label: 'นาง' },
                        { value: 'นางสาว', label: 'นางสาว' },
                        { value: 'เด็กชาย', label: 'เด็กชาย' },
                        { value: 'เด็กหญิง', label: 'เด็กหญิง' },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"first_name"}
                    label="ชื่อ"
                    rules={[{ required: true }]} >
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={9} sm={24} xs={24}>
                  <Form.Item  
                    name={"last_name"}
                    label="สกุล"
                    rules={[{ required: true }]} >
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item  
                    name={"card_number"}
                    label="เลขบัตรประชาชน/เลขแรงงานต่างด้าว"
                    rules={[{ required: true }]} >
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"birthdate"}
                    label="วันเกิด">
                      <DatePicker style={{ width: '100%' }} format={DATE_FORMAT}/>
                  </Form.Item>
                </Col>

                <Col md={6} sm={24} xs={24}>
                  <Form.Item  
                    name={"gender"}
                    label="เพศ">
                      <Select style={{ width: '100%' }}
                        options={[
                          { value: 'm', label: 'ชาย' },
                          { value: 'f', label: 'หญิง' }
                        ]}/>
                  </Form.Item>
                </Col>

                <Col md={24} sm={24} xs={24}>
                  <Form.Item  
                    name={"address"}
                    label="ที่อยู่" 
                    rules={[{ required: true }]}>
                      <Input.TextArea />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item  
                    name={"sub_district"}
                    label="ตำบล/อำเภอ/จังหวัด - รหัสไปรษณีย์"
                    rules={[{ required: true }]}>
                  <Select 
                    initialvalues={"-"}
                    autoComplete="off" 
                    showSearch
                    labelInValue
                    filterOption={false}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceSubdistrict}
                    options={subdistricts}
                    allowClear={"-"}/>
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} xs={24}>
                  <Form.Item  
                    name={"phone"}
                    label="เบอร์โทรศัพท์"
                    rules={[{ required: true }]}>
                      <Input autoComplete="off" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

          </Row>
        </Form>
      </Card>

      <ImgCrop aspect={16 / 9} aspectSlider>
        <input id="imgSelect"
          type="file"
          accept="image/*"
          ref={uploadRef}
          style={{ display: 'none' }}
          onChange={async (evt) => {
            if (evt.target.files.length > 0) {
              setUploadFile(URL.createObjectURL(evt.target.files[0]))
              evt.target.value = null  // clear reference to enable select same file
            }
          }}/>
      </ImgCrop>
        
      <ImageEditorModal
        open={uploadFile ? true : false}
        image={uploadFile}
        onUpdated={(image) => {
          setImageData(image)
          setUploadFile(null)
        }}
        onClose={() => setUploadFile(null)}/>

    </Modal>
  )
}