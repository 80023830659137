import React from "react";
import VerificationInput from "react-verification-input";
import { Modal, Image, Card, Alert,  } from 'antd'
import propTypes from "prop-types";
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';


export default function LoginCashierModal(props) {
    const[value, setValue] = React.useState('')
    const [isOpen, setIsOpen] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState(null);  
    const { t, i18n } = useTranslation();  
    const onChange = (e) => {
      setValue(value.concat(e))
    }
    React.useEffect(() => {
      if(value && props.target){
      
        if(value == props.target.pin){
          // Cookies.set ('pin' , value , {expires : 7 });
          Cookies.set ('id' , props.target.id , {expires : 7 });
          setValue('');
          props.onUpdated();
          props.onSave(props.target.id);
          setIsOpen(false)
        }
        else if (value.length === 4 ){
          setErrorMessages(t("incorrect_PIN_code"));
          setValue('');
        }
      }
    },[value])
    
    React.useEffect(()=>{
      if(props.open === true){
        setIsOpen(true);
      }
      if(props.open === false){
        setIsOpen(false);
        setValue('')
        setErrorMessages(null);
      }
    },[props.open])
    return(
      <Modal 
        title="PIN CODE" 
        open={isOpen} 
        onCancel={props.onClose}
        okButtonProps={{ style: { display: 'none' } }}
        maskStyle={{ backdropFilter: 'blur(8px)' }}
      >
      
      <Card style={{width: 'auto',textAlign: 'center'}} >
        {errorMessages && 
        <Alert
          style={{marginBottom: "12px", textAlign: "left"}}
          message={errorMessages}
          type="error"
          showIcon 
        />}

        <Image 
          style={{textAlign: "center", width: "100%", maxWidth: 120}}
          preview = {false}
          src={"/logo_txt.png"} /> 

        <VerificationInput 
          classNames={{
            container: "pincode",
            character: "pincode",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
          length={4}
          onChange={(e) => setValue(e)}
          value={value}
          autoFocus={true}
          />
        <div className="numpad" style={{height :'auto', width:'auto'}} >
          <div className="row-numpad">
            <button className="key-numpad" onClick={() => onChange('1')}>
                1
            </button>
            <button className="key-numpad" onClick={() => onChange('2')}>
                2
            </button>
            <button className="key-numpad" onClick={() => onChange('3')}>
                3
            </button>
          </div>
          <div className="row-numpad">
            <button className="key-numpad" onClick={() => onChange('4')}>
                4
            </button>
            <button className="key-numpad" onClick={() => onChange('5')}>
                5
            </button>
            <button className="key-numpad" onClick={() => onChange('6')}>
                6
            </button>
          </div>
          <div className="row-numpad">
            <button className="key-numpad" onClick={() => onChange('7')}>
                7
            </button>
            <button className="key-numpad" onClick={() => onChange('8')}>
                8
            </button>
            <button className="key-numpad" onClick={() => onChange('9')}>
                9
            </button>
          </div>
          <div className="row-numpad">
            <button className="key-numpad" >
              OK
            </button>
            <button className="key-numpad" onClick={() => onChange('0')}>
              0
            </button>
            <button className="key-numpad" onClick={() => setValue('')}>
              Del
            </button>
          </div>
        </div>
        </Card>
      </Modal>
    )
}
LoginCashierModal.defaultProps = {
  open: false,
	target: null,
  onUpdated: () => {},
	onClose: () => { },
}

LoginCashierModal.propTypes = {
  open: propTypes.bool,
	target: propTypes.object,
  onUpdated: propTypes.func,
	onClose: propTypes.func,
}