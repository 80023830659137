import React from 'react';
import { Alert, Space, theme, Popconfirm, message, Tooltip, Row, Col } from 'antd';
import TSTable from '../components/common/TSTable';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, UnlockOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { SECONDARY_COLOR } from '../constants/color';
import UserModal from '../components/user/UserModal';
import ColorButton from '../components/common/ColorButton';
import { DELETE } from '../frameworks/HttpClient';
import { URL_MEMBER, URL_AUTH } from '../constants/urls';
import { OFFICER_PERMISSION, HUMAN_DATETIME_FORMAT, DATETIME_FORMAT } from '../constants/string';
import ChangePasswordModal from '../components/user/ChangePasswordModal';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function UserListView() {
  const [openUserModal, setOpenUserModal] = React.useState(false);
  const [targetUser, setTargetUser] = React.useState(null);
  const [targetChangePW, setTargetChangePW] = React.useState(null);
  const { t } = useTranslation();
  const tableRef = React.useRef(null);

  const {
    token: { colorError, colorWarning, colorSuccess }
  } = theme.useToken();

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
    }
  }

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_MEMBER.OFFICER}${id}/`)
      tableRef.current.fetch();
      message.success(t('data_deleted_successfully'))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  const exportTable = async(type) => {
    if(type == "pdf"){
      await tableRef.current.exportPDF({
        title: t("user.user_list"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        // landscape: true
      })
    } else {
      await tableRef.current.exportXlsx({
        title: t("user.user_list"),
        subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
        filename: `${t("user.user_list")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
      })
  }}

  const columns = [
    {
      title: t("user.full_name"),
      dataIndex: "full_name",
      sorter: true,
      searcher: true,
    },
    {
      title: t("phone_number"),
      width: 200,
      dataIndex: "phone",
      searcher: true,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      searcher: true,
    },
    {
      title: t("user.system_access"),
      dataIndex: 'groups',
      render: (value, original) => {
        let data = []
        value.map((item) => {
          if(item === OFFICER_PERMISSION.MANAGER)
            data = [...data,'Manager']
          
          if(item === OFFICER_PERMISSION.STAFF)
            data = [...data,'Staff']

          if(item === OFFICER_PERMISSION.REPORT)
            data = [...data,'Report']
        })

        return (
          `${data}`
        )
      }
    },
    {
      title: 'Action',
      width: 120,
      skipReport: true,
      dataIndex: 'position',
      render: (value, original) => {
        return (
          <div style={{textAlign: "center"}}>
            <Space direction='horizontal'>
              <Tooltip title={t('edit')}>
              <ColorButton 
                type={"primary"} 
                override={colorWarning}
                icon={<EditOutlined />}
                onClick={() => setTargetUser(original)} />
                </Tooltip>
                <Tooltip title={t('delete')}>
              <Popconfirm
                title={t('delete_User')}
                description={t("do_you_want_to_delete_this_user")}
                onConfirm={() => handleDelete(original.id)}
                okText={t("delete")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}
                placement="topRight">
                <ColorButton
                  type={"primary"}
                  override={colorError}
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
              </Tooltip>

              <Tooltip title={t("change_password")}>
                <ColorButton 
                  type={"primary"} 
                  override ={colorSuccess}
                  icon={<UnlockOutlined/>}
                  onClick={() => {
                    setTargetChangePW(original.user)
                  }} />
              </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ]

  return(
    <div>
      <Space style={{ float: 'right' }}>
        <Row gutter={8}>
        <Col>
          <ColorButton
            icon={<FilePdfOutlined />}
            style={{float: "right"}}
            onClick={() => exportTable("pdf")}>
            PDF
          </ColorButton>
        </Col>
        <Col>
          <ColorButton
            icon={<FileExcelOutlined />}
            style={{float: "right"}}
            onClick={() => exportTable("excel")}>
            Excel
          </ColorButton>
        </Col>
          <ColorButton 
            type={"primary"} 
            icon={<PlusCircleOutlined />} 
            override={SECONDARY_COLOR}
            style={{float: "right", marginBottom: '1rem'}}
            onClick={() => setOpenUserModal(true)}>
              {t("add_User")}
          </ColorButton>
          </Row>
        </Space>
      <TSTable 
        ref={tableRef}
        columns={columns}
        url={URL_MEMBER.OFFICER}
        rowKey={"id"}
        size={"small"}
        pageSize={10}/>

      <UserModal 
        open={(openUserModal || targetUser)}
        target={targetUser}
        onUpdated={() => {
          openUserModal ? setOpenUserModal(false) : setTargetUser(null)
          refreshData()
        }}
        onClose={() => 
          openUserModal ? setOpenUserModal(false) : setTargetUser(null)
        }/>

      <ChangePasswordModal 
        open={targetChangePW ? true : false}
        url={`${URL_AUTH.USER}${targetChangePW ? targetChangePW : ""}/change_password/`}
        onClose={() => setTargetChangePW(null)}/>

    </div>
  )
}