import { Alert, AutoComplete, Button, Divider, Form, Input, Modal, Popconfirm, Row, Select, Space, theme, Col, Tooltip, message, Card, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import Header from '../common/Header';
import { GET, POST, PUT } from '../../frameworks/HttpClient';
import { URL_CUSTOMER, URL_STOCK, URL_WEIGHT } from '../../constants/urls';
import * as _ from 'lodash';
import useDropdownItem from '../../hooks/useDropdownItem';
import {Mobile} from 'antd-mobile'
import { DeleteOutlined, FileTextOutlined, FileDoneOutlined,ExceptionOutlined, RightSquareOutlined} from '@ant-design/icons';
import TSTable from '../common/TSTable';
import moment from 'moment';
import { DATETIME_FORMAT, PRODUCT_TRACEABILITY, STOCK_DIRECTION_TRANSLATION } from '../../constants/string';
import { formatComma } from '../../frameworks/Util';
import ColorButton from '../common/ColorButton';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import { TYPE_FARMER } from '../../constants/color';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import { List } from 'antd-mobile'
import Meta from 'antd/es/card/Meta';
import ChangeStockModal from './ChangeStockModal'
import { useTranslation } from 'react-i18next';




export default function StockModal(props) {
  const [changeStockTarget, setChangeStockTarget] = React.useState(null);
  const {
    open,
    target,
    onClose,
    stockWeight,
    isClose
  } = props;

  const isCreated = target == 'add';
  const { token: { colorInfo, colorWarning, colorError } } = theme.useToken();
  const { options, fetchOptions } = useDropdownItem({
    url: URL_CUSTOMER.PRODUCT,
    params: {traceability: [PRODUCT_TRACEABILITY.CT, PRODUCT_TRACEABILITY.FSC]},
    manual: true, pagination: true, 
    key: 'id', value: 'id', label: 'name',
    label_renderer: (item) => {
      const traceability = _.get(item, 'traceability', '');
      return (
        <span style={{
          fontWeight: traceability ? 'bolder' : 'normal',
          color: traceability ? TYPE_FARMER : null
        }}>
          {traceability ? `[${traceability.toUpperCase()}] - ` : ''}{_.get(item, 'name', 'Unknown')}
        </span>
      )
    }
  });
  const navigate = useNavigate();
  const [form] = useForm();
  const tableRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [stock, setStock] = React.useState(null)
  const { lessThanOrEqual } = useWindowDimensionContext();
  const [dataStock , setDataStock] = React.useState([]);
  const [outId , setOutId] = React.useState(null);
  const isOnMobile = lessThanOrEqual('md');
  const { t, i18n } = useTranslation();

  
  
  const columns = [
    { title: t("date"), dataIndex: "created", render: (value) => moment(value).format(DATETIME_FORMAT) },
    { title: t("weighing_Ticket"), dataIndex: 'ticket_code' },
    { title: t("type"), dataIndex: 'direction', render: (value) => _.get(STOCK_DIRECTION_TRANSLATION, value, 'unknown') },
    { title: t("customer"), dataIndex: 'customer_name' },
    // Hide Column FSC
    // { title: 'FSC', dataIndex: 'ticket_traceability', render: (value) => 
    //   <span style={{ color: value ? TYPE_FARMER : null}}>{value ? value.toUpperCase() : '-'}</span>
    // },
    { title: t("weight"), dataIndex: 'weight', render: (value) => `${formatComma(value)}` },
      {
      title: ' ',
      dataIndex: 'ticket',
      width: 80,
      render: (value, original) => (
        <Space direction>
        <Tooltip title={t("weighing_Ticket_Details")}>
          <ColorButton
            override={colorInfo}
            ghost
            type='primary'
            icon={<FileTextOutlined/>}
            onClick={() => navigate(`${PATH.SCALE}/${value}/`)}/>
        </Tooltip>
          { original.ticket_traceability === PRODUCT_TRACEABILITY.FSC && (
            <Popconfirm
              title={t("confirm_removal_from_FSC")}
              okText={t("confirm")}
              okButtonProps={{ loading: loading }}
              cancelText={t("cancel")}
              placement='topRight'
              onCancel={e => null}
              onConfirm={() => removeTicketTraceability(value)}>
              <Tooltip title={t("remove_from_FSC")}>
                <ColorButton
                  ghost
                  type='primary'
                  override={colorWarning}
                  icon={<ExceptionOutlined />}/>
              </Tooltip>
            </Popconfirm>
           
          ) }
          <Tooltip title={t("to_transfer_lots")}>
            <ColorButton
              override={colorInfo}
              ghost
              type='primary'
              icon={<RightSquareOutlined />}
              onClick={() => setChangeStockTarget(original.id)}/>
          </Tooltip>
        </Space>
      )
    }
  ]

  const removeTicketTraceability = async (id) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(`${URL_WEIGHT.WEIGHT_TICKET}${id}/remove-fsc/`)
      if (tableRef.current) {
        tableRef.current.fetchWithoutResetPage();
      }
      handleCloseModal();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_STOCK.STOCK}${target}`);
      setStock(response.data)
      form.setFieldsValue(response.data)
      tableRef.current.fetch({ stock: target });
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const data = await form.validateFields();
      if (isCreated) {
        await POST(URL_STOCK.STOCK, data);
        onClose();
      } else {
        await PUT(`${URL_STOCK.STOCK}${target}/`, data);
        fetchData(target);
        message.success(t("information_updated_successfully"))
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const fetchDataStock = async() => {
    const response = await GET(`${URL_STOCK.STOCK_TRANSACTION}?stock=${target}`)
    setDataStock(response.data.results)
  }

  const handleCloseModal = () => {
    setOutId(null)
  };

  React.useEffect(() => {
    if (open) {
      fetchOptions();
      setErrorMessages(null);
      
      if (!isCreated) {
        fetchData()
      }
    } else {
      form.resetFields();
    }
  }, [open])

  React.useEffect(()=>{
    if(target && target !== 'add')
      fetchDataStock();
  },[target])

  return (
    <div>
      <Modal
        open={open}
        width={1000}
        footer={[]}
        onCancel={onClose}
      >
        <Header title={isCreated ? t("create_lot") : t("lot_details")}/>
        { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/>}
        <Form style={{ marginTop: '1rem' }} form={form} labelCol={{ span: 5 }}>
          <Form.Item label={t("product")} name='product' rules={[{ required: true }]} valuePropName='value'>
            <Select
              showSearch
              options={options}
              defaultActiveFirstOption={false}
              disabled={!isCreated}
              notFoundContent={null}
              optionFilterProp='label'
              onSearch={value => fetchOptions({ search: value })}/>
          </Form.Item>
          <Form.Item label={t("memo")} name='note'>
            <Input.TextArea disabled={isClose === true}/>
          </Form.Item>
          <Form.Item>
            {!isOnMobile ? (
              <Space style={{ float: 'right' }}>
                {isCreated && (<Button style={{ float: 'right' }} loading={loading} onClick={onClose}>{t("cancel")}</Button>)}
                {isClose !== true && <Button type='primary' loading={loading} onClick={handleSubmit}>{ isCreated ? t("add") : t("edit")}</Button>}
              </Space>
            ) : (
              <>
              <Row style={{ marginTop : 10}}>
                <Col md={12} sm={12} xs={12} style={{ textAlign: 'start'}}>
                  <Button style={{ width : '95%', height : 45 }} loading={loading} onClick={onClose}>{t("cancel")}</Button> 
                </Col>
                <Col md={12} sm={12} xs={12} style={{ textAlign: 'end'}}>
                  <Button type='primary' style={{ width : '95%', height : 45 }} loading={loading} onClick={handleSubmit}>{ isCreated ?  t("add") : t("edit")}</Button>
                </Col>
                
              </Row>
              </>
            )}
          </Form.Item>
          
        </Form>
        <div>
          {isOnMobile && !isCreated ? (
           <List>
          {dataStock.map(item=>(
             <List.Item
             key={item.id}
             >
               <Card 
               style={{height:'auto'}}
               title={`${t("weighing_Ticket")} ${item.ticket_code}`}
               actions={[

                <FileDoneOutlined onClick={() => navigate(`${PATH.SCALE}/${item.ticket}/`)}/>,

                item.ticket_traceability && (
                    <ExceptionOutlined onClick={() => setOutId(item.id)}/>
                ),
                <Tooltip title={t("to_transfer_lots")}>
                  <RightSquareOutlined onClick={() => setChangeStockTarget(item.id)} />
                </Tooltip>
               
                
              ]}
               >
               <Meta 
              description={
                <>
                <>{t("date")} {moment(item.created).format(DATETIME_FORMAT)}</>
                <br/>
                <>{t("type")} {_.get(STOCK_DIRECTION_TRANSLATION, item.direction, 'unknown')}</>
                <br/>
                <>{t("customer")}: {item.customer_name}</>
                <br/>
                <>{t("weight")} {parseInt(item.weight).toFixed(2)}</>
                </>
              }/>
               </Card>
             </List.Item>
           ))}
           </List>

          ) : (!isCreated && (
              <TSTable
                ref={tableRef}
                style={{ marginTop: '1rem' }}
                autoFetch={false}
                params={{ stock: target }}
                columns={columns}
                url={URL_STOCK.STOCK_TRANSACTION}
                rowKey='id'
                size='small'
                footer ={(data) => {
                  if(stockWeight){
                    return <div>
                      {t("total_in_weight")} {parseInt(_.get(stockWeight, 'importWeight', 0)).toFixed(2)} &emsp; {t("remain_weight")} {parseInt(_.get(stockWeight, 'remainWeight', 0)).toFixed(2)}
                    </div>
                  }
                }}
              />
            )
          )}
        </div>
      </Modal>

      <ChangeStockModal
      open = {changeStockTarget ? true : false}
      target = {changeStockTarget}
      product = {stock ? stock.product : null}
      stockID = {target ? target : ''}
      onClose = {() => {
        setChangeStockTarget(null)
      }}
      onUpdate = {() => {
        setChangeStockTarget(null)
        fetchDataStock();
        tableRef.current.fetch({ stock: target });
      }}
      />

      <Modal
        visible={outId ? true : false}
        onClose={handleCloseModal}
        closeOnAction
        title={t("do_you_want_to_delete_the_gardener")}
        content={
          <div style={{ textAlign: 'center' }}>
          <Space align='center'>
            <ColorButton 
              type={"primary"} 
              block size='large' 
              override={colorError}
              onClick={() => removeTicketTraceability(outId)}
            >
              {t("actions.delete_data")}
            </ColorButton>
            <ColorButton 
              block size='large' 
              onClick={() => handleCloseModal()} >
              {t("cancel")}
            </ColorButton>
          </Space>
          </div>
        }>
      </Modal>
    </div>
  )
}