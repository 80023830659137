import React from "react";
import {Modal, Space, theme } from "antd";
import ColorButton from "../common/ColorButton";
import { PLANTATION_TRACEABILITY } from "../../constants/string";
import { URL_PLANTATIONS } from "../../constants/urls";
import { PATCH } from "../../frameworks/HttpClient";
import { ExclamationCircleOutline } from "antd-mobile-icons";
import { useTranslation } from 'react-i18next';

export default function ConfirmPlantitonModal(props) {
  const { t, i18n } = useTranslation();
  const {
    token: { colorError, colorWarning, colorInfo, colorPrimary }
  } = theme.useToken();

    const {
        target,
        open,
        onClose,
      } = props;

      React.useEffect(()=>{
      },[target])

      const handleUpdate = async (props) => {
        await PATCH(`${URL_PLANTATIONS.PLANTATIONS}${props.data.id}/`, { traceability: props.data.traceability === PLANTATION_TRACEABILITY.FSC ? 'ct' : 'fsc' });
        props.onUpdate();
      };
      const handleCancle = async() => {
        props.onClose()}
  return(
    <Modal
      title={<p><ExclamationCircleOutline style={{ color: 'red' }} />   Confirm</p>}
      open={open}
      footer={[]}
      onCancel={onClose}
      width={550}>
        {props.data && props.data.traceability === PLANTATION_TRACEABILITY.FSC ? (
        <p>{t("plantations.confirm_rubber_converted", { type : "CT"})}</p>
        ) : (
        <p>{t("plantations.confirm_rubber_converted", { type : "FSC"})}</p>
        )}
    <div style={{ textAlign: 'right' }}>
        <Space>
        <ColorButton 
        onClick={()=>handleCancle(props)}>
            {t("cancel")}
        </ColorButton>
        <ColorButton 
          type='primary'
          override={props.data && props.data.traceability === PLANTATION_TRACEABILITY.FSC ?  colorError: colorPrimary}
          onClick={()=>handleUpdate(props)}
          >
            {t("confirm")}
        </ColorButton>
        </Space>
    </div>
    </Modal>
  )
}
