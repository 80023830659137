import React from "react"
import { Col, Row, Spin, Alert, Empty } from "antd"
import { PATH } from "../../routes/CustomRoutes";
import { createSearchParams, useNavigate } from 'react-router-dom';
import { GET } from "../../frameworks/HttpClient";
import { URL_CUSTOMER } from "../../constants/urls";
import * as _ from 'lodash';
import CustomerCard from "./CustomerCard";
import CustomerFilterHeader from "./CustomerFilterHeader";
import IncomingList from "../scale/IncomingList";
import { useTranslation } from 'react-i18next';


export default function ScaleCustomerView() {
  const navigate = useNavigate();

  const headerRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [datas, setDatas] = React.useState([]);
  const { t, i18n } = useTranslation();


  const fetchData = async (params) => {
    setLoading(true);
    try {
      const response = await GET(URL_CUSTOMER.CUSTOMER, params);
      setDatas(_.get(response, 'data.results', []))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useState(() => {
    fetchData();
  }, [])

  return (
    <div>
      <IncomingList/>
      <CustomerFilterHeader ref={headerRef} loading={loading} refresh={fetchData}/>
      { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/>}
      <div style={{ marginTop: '1rem' }}>
        { !datas.length && <Empty description={t("none")}/> }
        <Spin spinning={loading}>
          <Row gutter={[16, 16]} >
            { datas.map((data, idx) => (
              <Col key={`cusomer-${idx}`} xxl={6} xl={8} lg={12} md={12} sm={24} style={{ width: '100%' }}>
                <CustomerCard
                  data={data}
                  onClick={() => navigate({ pathname: `${PATH.SCALE}/add/`, search: createSearchParams({ customer: data.id }).toString() })}
                  onEdit={() => {
                    if (headerRef.current) {
                      headerRef.current.onEdit(data)
                    }
                  }}/>
              </Col>
            )) }
          </Row>
        </Spin>
      </div>
    </div>
  )
};