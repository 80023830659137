import { Alert, Form, InputNumber, Modal } from 'antd';
import React from 'react';
import Header from '../common/Header';
import * as _ from 'lodash';
import { POST } from '../../frameworks/HttpClient';
import { URL_WEIGHT } from '../../constants/urls';
import ErrorMessages from '../common/ErrorMessages';
import { useTranslation } from 'react-i18next';



export default function ScalePaymentModal(props) {
  const {
    open,
    onClose,
    onSuccess,
    ticketData,
  } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const { t, i18n } = useTranslation();


  const onSubmit = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const data = await form.validateFields();
      await POST(`${URL_WEIGHT.WEIGHT_TICKET}${_.get(ticketData, 'id')}/pay/`, data);
      onSuccess();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      centered={false}
      open={open}
      onCancel={onClose}
      onOk={onSubmit}
      cancelButtonProps={{ loading }}
      okButtonProps={{ loading }}
    >
      <Header title={t("profiles.roles.cashier")}/>
      { errorMessages && <ErrorMessages style={{ marginTop: '1rem' }} message={errorMessages}/> }
      <div style={{ marginTop: '1rem' }}>
        <Form form={form} labelCol={{ span: 8 }}>
          <Form.Item label={t("price")}>
            <InputNumber
              readOnly
              style={{ width: '100%' }}
              value={_.get(ticketData, 'price', 0)} 
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              addonAfter={t("baht")}/>
          </Form.Item>
          <Form.Item label={t("actual_amount_paid")} name='amount' initialValue={_.get(ticketData, 'price', 0)} rules={[{ required: true }]}>
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              addonAfter={t("actual_amount_paid")}/>            
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}