import _ from "lodash";
import { IMPORT_JSON_TYPE } from "../constants/string";
import { GET } from "../frameworks/HttpClient";
import { URL_COMMON, URL_PLANTATIONS } from "../constants/urls";


export default function useImportJsonParser() {


  // Mapping from TrazTru Key and 3rd party JSON key
  const FARMER_DATA_MAPPING = {
    'title': 'farm_title',
    'first_name': 'farm_name',
    'last_name': 'farm_surname',
    'phone': 'farm_mobile',
    'citizen_id': 'farm_idc',
    'raot_number': 'app_no',
    'addr_1': 'farm_addr.addr',
    'addr_2': 'farm_addr.moo',
    'addr_3': 'farm_addr.vil',
    'addr_4': 'farm_addr.soi',
    'addr_5': 'farm_addr.road',
    'tambon': 'farm_addr.tambon',
    'zipcode': 'farm_addr.zipcode',
  }

  const PLANTATION_DATA_MAPPING = {
    "land_name": "land_name",
    "title_deed_no": "land_no",
    "area_rai": "deed.area_rai",
    "area_ngan": "deed.area_ngan",
    "area_wa": "deed.area_wa",
    "rubber_area_rai": "grow_rai_ngan_wa",
    "number_of_trees": "rip_qty",
    "yield_kk": "prod_qty",
    "plant_year": "grow_year",
    "land_addr_1": "land_location.addr",
    "land_addr_2": "land_location.moo",
    "land_addr_3": "land_location.vill",
    "land_addr_4": "land_location.soi",
    "land_addr_5": "land_location.road",
    "land_tambon": "land_location.tambon",
    "land_zipcode": "land_location.zipcode",
    "tree_types": "rubber_type",
    "tapping_duration": "rip_days",
    "tapping_wait": "rip_stop",
    "ltx_pct": "ltx_pct",
    "cup_pct": "cup_pct",
    "uss_pct": "uss_pct",
    "": "",
    "": "",
    "": "",
  }

  function isJson(item) {
    let value = typeof item !== "string" ? JSON.stringify(item) : item;
    try {
      value = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return typeof value === "object" && value !== null;
  }

  function getData(jsonData, mapping) {
    return Object.entries(mapping).reduce(
      (acc, [k, v]) => ({ ...acc, [k]: _.get(jsonData, v, null) }), {}
    )
  }

  async function fetchSubdistrict(tambon, zipcode) {
    try {
      const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${zipcode}`)
      const subDistricts = response.data.results.filter(location => tambon.includes(location.subdistrict_name))
      return subDistricts.length === 0 ? null : ({
        ...subDistricts[0],
        label: subDistricts[0].display_name,
        value: subDistricts[0].subdistrict_id,
      })
    } catch (error) {
      console.log(error.errorMessages)
    } 
  }

  async function fetchDeedOptions(land_name) {
    try {
      const response = await GET(URL_PLANTATIONS.DEED_TYPE);
      const deedOptions = response.data.results.filter(deed => deed.name === land_name)
      return deedOptions.length === 0 ? null : deedOptions[0].id
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  function parseAddress (data, prefix) {
    return Object.entries(data).map(
      ([key, value]) => key.startsWith(prefix) ? value : ''
    ).filter(addr => addr != null && addr !== '-' && addr !== "").join(' ')
  }

  function parseProductType (data) {
    if (data.ltx_pct > 0) return "latex"
    else if (data.cup_pct > 0) return "lump"
    else if (data.uss_pct > 0) return "raw_sheet"
    else return null
  }

  const parser = async (type, jsonData) => {
    if (!isJson(jsonData)) throw new Error("Need JSON Object!")
    else if (type === IMPORT_JSON_TYPE.FARMER) return await parseFarmer(jsonData)
    else if (type === IMPORT_JSON_TYPE.PLANTATION) return await parsePlantation(jsonData)
    else throw new Error("Invalid validation type")
  }

  const parseFarmer = async (jsonData) => {
    const farmerData = getData(jsonData, FARMER_DATA_MAPPING)
    return {
      ...farmerData,
      address: parseAddress(farmerData, "addr_"),
      sub_district: await fetchSubdistrict(farmerData.tambon, farmerData.zipcode),
    }
  }

  const parsePlantation = async (jsonData) => {
    const plantationData = getData(jsonData, PLANTATION_DATA_MAPPING)
    const land_sub_district = await fetchSubdistrict(plantationData.land_tambon, plantationData.land_zipcode)
    return {
      ...plantationData,
      title_deed_type: await fetchDeedOptions(plantationData.land_name),
      address: parseAddress(plantationData, "land_addr_"),      
      sub_district: land_sub_district ? land_sub_district.id : null,
      sub_district_detail: land_sub_district ? land_sub_district.display_name : null,
      product_type: parseProductType(plantationData),
    }
  }

  return parser;
}

