import React from 'react'
import propTypes from "prop-types";
import { Alert, Modal, QRCode, Typography } from "antd";
import { useTranslation } from 'react-i18next';


export default function QRCodeModal(props) {
  const { t, i18n } = useTranslation();
  const { Title } = Typography;

  React.useEffect(() => {
  }, [props.open])

  return (
    <Modal
      title={"Line Login QR Code"}
      centered={true}
      open={props.open}
      footer={<></>}
      onOk={props.onClose}
      onCancel={props.onClose}>
      
      {props.memberId === null &&
        <Alert
          message="ไม่พบรหัสสมาชิก"
          type="error"
          showIcon
          style={{ marginTop: 16, textAlign: "left" }}
        />
      }
      {props.memberId != null &&  
        <QRCode 
          style={{ margin: "auto", marginTop: 16 }}
          value={props.memberId} />
      }
      {props.memberName != null &&
        <p 
          style={{ margin: "auto", marginTop: 20, textAlign: "center" }}  
          level={4}>{props.memberName}</p>
      }
    </Modal>
  )
}
QRCodeModal.defaultProps = {
  open: false,
  memberId: null,
  memberName: null,
  onClose: () => { },
}

QRCodeModal.propTypes = {
  open: propTypes.bool,
  memberId: propTypes.string,
  memberName: propTypes.string,
  onClose: propTypes.func,
}