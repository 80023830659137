import { Button, Col, Form, InputNumber, Row, Space } from 'antd';
import React from 'react';
import { UNIT, UNIT_TRANSLATION } from '../../constants/string';
import moment from 'moment';
import propTypes from 'prop-types';
import { FieldTimeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';


export default function ScaleField(props) {
  const {
    form,
    showButton,
    direction,
    title,
    icon,
    handleScale,
    handleTimeStamp,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <Row gutter={[8]}>
      { showButton && (
        <Col span={4}>
          <Form.Item label=' '>
            <Button block type='primary' onClick={() => handleScale(direction)}>
              {icon}{title}
            </Button>
          </Form.Item>
        </Col>
      )}
      <Col span={showButton ? 8 : 10}>
        <Form.Item label={showButton ? ' ' : title} colon={false} name={`${direction}_weight`}>
          <InputNumber
            style={{ width: '100%' }}
            readOnly={showButton}
            addonAfter={UNIT_TRANSLATION[UNIT.KILOGRAM]}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            precision='2'/>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label= {t("days")} colon={false} name={`${direction}_datetime`}>
          <InputNumber
            readOnly
            style={{ width: '100%' }}
            formatter={(value) => value ? moment(value).format('L') : ''}/>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label={t("time")} colon={false} name={`${direction}_datetime`}>
          <InputNumber
            readOnly
            style={{ width: '100%' }}
            formatter={(value) => value ? moment(value).format('HH:mm') : ''}/>
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label=' '>
          { !showButton && <Button type='primary' icon={<FieldTimeOutlined />} onClick={handleTimeStamp}></Button> }
        </Form.Item>
      </Col>
    </Row>
  )
}

ScaleField.defaultProps = {
  form: null,
  showButton: false,
  direction: 'in',
  title: 'ชั่งเข้า',
  icon: null,
  handleScale: () => null,
  handleTimeStamp: () => null,
};

ScaleField.propTypes = {
  form: propTypes.object,
  showButton: propTypes.bool,
  direction: propTypes.string,
  title: propTypes.string,
  icon: propTypes.any,
  handleScale: propTypes.func,
  handleTimeStamp: propTypes.func,
};
