import React from "react";
import { GET } from "../../frameworks/HttpClient";
import { URL_DELIVERY, URL_WEIGHT } from "../../constants/urls";
import { theme, Space, Card, Row, Col, Typography, Spin, message } from 'antd'
import * as _ from 'lodash';
import ColorButton from "../common/ColorButton";
import { CarOutlined, GoldOutlined, LoginOutlined, LogoutOutlined, RedoOutlined, PushpinOutlined } from '@ant-design/icons';
import { ellipsisString } from '../../frameworks/Util';
import { PATH } from "../../routes/CustomRoutes";
import { createSearchParams, useNavigate } from "react-router-dom";
import { HUMAN_DATE_FORMAT, INPUT_TYPE, WEIGHT_TICKET_DIRECTION } from "../../constants/string";
import moment from "moment";
import { useTranslation } from 'react-i18next';



export default function IncomingList(props) {
  const { token: {  colorInfo, colorWarning } } = theme.useToken();

  const navigate = useNavigate();
  const [incomingList, setIncomingList] = React.useState([]);
  const [outgoingList, setOutgoingList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { t, i18n } = useTranslation();


  const fetchData = async () => {
    setLoading(true);
    try {
      const incomingResponse = await GET(URL_WEIGHT.WEIGHT_TICKET_INCOMING, { })
      setIncomingList(_.get(incomingResponse, 'data.results', []))

      const outgoingResponse = await GET(URL_DELIVERY.DELIVERY_OUTGOING, { sent: false })
      setOutgoingList(_.get(outgoingResponse, 'data.results', []))
    } catch (error) {
      messageApi.error(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const generateTicketParams = (ticket) => {
    return createSearchParams({
      ref: ticket.id,
      direction: WEIGHT_TICKET_DIRECTION.BUY,
      input_type: INPUT_TYPE.MANUAL
    }).toString()
  }

  const generateDeliveryOrderParams = (del) => {
    return createSearchParams({
      del_ref: del.id,
      direction: WEIGHT_TICKET_DIRECTION.SELL,
      input_type: INPUT_TYPE.MANUAL
    }).toString()
  }

  const onItemClicked = (item, searchParams) => {
    if(item.ticket != null) {  // Already edited item
      navigate(`${PATH.SCALE}/${item.ticket}`)
    }
    else {  // Newly created ticket
      navigate({
        pathname: `${PATH.SCALE}/add/`,
        search: searchParams
      })
    }
  }

  React.useState(() => {
    fetchData()
  }, [])

  return (
    <Spin spinning={loading}>
      {contextHolder}
      { (incomingList && incomingList.length > 0) && (
        <Space align='baseline'>
          <Typography.Title style={{ marginTop: 0, marginBottom: '1rem', color: colorInfo }} level={5}>
            {t("upcoming_items")}
          </Typography.Title>
          <ColorButton icon={<RedoOutlined />} size='small' type='primary' ghost override={colorInfo} onClick={fetchData}/>
        </Space>
      )}
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        { incomingList.map(ticket => (
          <Col lg={8} md={12} xd={24}>
            <Card
              size='small'
              hoverable
              onClick={() => onItemClicked(ticket, generateTicketParams(ticket))}
            >
              <div style={{ float: 'right' }}>{moment(ticket.created).format(HUMAN_DATE_FORMAT)}</div>
              <Card.Meta
                title={i18n.language === "en" ? _.get(ticket,'customer_ref_name_en',  _.get(ticket,'customer_ref_name', 'Unknown')) : _.get(ticket,'customer_ref_name', 'Unknown')}
                avatar={<LoginOutlined style={{ color: colorInfo }} />}
                description={
                  <div>
                    <div><GoldOutlined /> {ellipsisString(ticket.product_name, 30)}</div>
                    <div><CarOutlined /> {ticket.vehicle_license_plate ? ticket.vehicle_license_plate : 'Unknown'}</div>
                  </div>
                }/>
            </Card>
          </Col>
        )) }
      </Row>

      { (outgoingList && outgoingList.length > 0) && (
        <Space align='baseline'>
          <Typography.Title style={{ marginTop: 0, marginBottom: '1rem', color: colorInfo }} level={5}>
            {t("rubber_delivery_list")}
          </Typography.Title>
          <ColorButton icon={<RedoOutlined />} size='small' type='primary' ghost override={colorInfo} onClick={fetchData}/>
        </Space>
      )}
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        { outgoingList.map(del => (
          <Col lg={8} md={12} xd={24}>
            <Card
              size='small'
              hoverable
              onClick={() => onItemClicked(del, generateDeliveryOrderParams(del))}
            >
              <div style={{ float: 'right' }}>{moment(del.sent_date).format(HUMAN_DATE_FORMAT)}</div>
              <Card.Meta
                title={i18n.language === "en" ? _.get(del,'customer_ref_name_en',  _.get(del,'customer_ref_name', 'Unknown')) : _.get(del,'customer_ref_name', 'Unknown')}
                avatar={<LogoutOutlined style={{ color: colorInfo }} />}
                description={
                  <div>
                    <div><PushpinOutlined /> {ellipsisString(del.code, 30)}</div>
                    <div><GoldOutlined /> {ellipsisString(del.product_name, 30)}</div>
                    <div><CarOutlined /> {del.vehicle_license_plate ? del.vehicle_license_plate : 'Unknown'}</div>
                  </div>
                }/>
            </Card>
          </Col>
        )) } 
      </Row>
    </Spin>
  )
}