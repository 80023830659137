import { Button, Card, Popconfirm, Space, Typography, theme, Modal } from 'antd';
import React from 'react';
import { formatComma } from '../../frameworks/Util';
import {  DeleteOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import { PRIMARY_COLOR } from '../../constants/color';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import "../../styles.css"
import Header from '../common/Header';
import { useTranslation } from 'react-i18next';
import { UNIT } from '../../constants/string';



export default function StockCard (props) {
  const { lessThanOrEqual } = useWindowDimensionContext();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const isOnMobile = lessThanOrEqual('md');
  const { t, i18n } = useTranslation();


  const {
    data,
    loading,
    onDelete,
    onEdit,
  } = props;

  const { token: { colorInfo } } = theme.useToken();
  
  const blockCardClickEvent = e => {
    if (typeof(_.get(e, 'stopPropagation', null) == 'function')) {
      e.stopPropagation();
    }
  }

  const UNIT_TRANSLATION = {
    [UNIT.GRAM]: t("gram"),
    [UNIT.KILOGRAM]: t("kilogram") ,
    [UNIT.TONNE]: t("tonne"),
    [UNIT.LITRE]: t("litre"),
  }

  

  return (
    <Card 
      className='card-footer' 
      hoverable 
      onClick={onEdit}
      title={(data && data.code) ? data.code : '-'}
      extra={
        onDelete && <Button
          style={{ float: 'right' }}
          danger
          onClick={e => {
            setDeleteOpen(true)
            blockCardClickEvent(e)
          }}
          icon={<DeleteOutlined />}
        />
    }>

      <div>
        <Space direction='vertical'>
          <Typography.Text style={{ margin: 0, color: colorInfo }}>
            {t("weight")} {formatComma(_.get(data, 'weight', 0))} {_.get(UNIT_TRANSLATION, _.get(data, 'unit', ''), '')}
            <Typography.Text italic>
              ({t("dry_latex")} {formatComma(_.get(data, 'dry_rubber_weight', 0))} {_.get(UNIT_TRANSLATION, _.get(data, 'unit', ''), '')})
            </Typography.Text>
          </Typography.Text>
          <Typography.Text 
            type='secondary' 
            className='truncate'
            style={{ margin: 0, height: "3em", lineHeight: "1.5em" }}>
              {(data && data.product_name) ? data.product_name : "-"}
          </Typography.Text>
        </Space>
      </div>
      <footer style={{ background: PRIMARY_COLOR }}/>
    <a onClick={(e) => e.stopPropagation()}>
      <Modal 
      open={deleteOpen} 
      onCancel={() => {
        setDeleteOpen(false)
      }} 
      onOk={() => {
        onDelete()
        setDeleteOpen(false)
      }}
      okText={t("delete")}
      okButtonProps={{ danger: true, icon: <DeleteOutlined/> }}>
        <Header title={t("delete_Stock")}/>
        <div style={{ marginTop: '1rem' }}>
         {t("do_you_want_to_delete_this_stock")}
        </div>
      </Modal>
    </a>

    </Card>
  )
}
