import React from 'react';
import propTypes from 'prop-types';
import { Card, Col, Tooltip, Statistic, Row, List} from 'antd';
import { DARK_GRAY_COLOR, PRIMARY_COLOR } from '../../constants/color';
import { useTranslation } from 'react-i18next';                                                                                         
import * as _ from 'lodash';

export default function PlantationTypeCard(props) {
  const { t } = useTranslation();
  
  return (
    <Card 
      bordered={false} 
      hoverable
      style={{
        boxShadow: '1px 1px 6px #DADADA', 
        borderRadius: 0 , 
        ...props.style,}}>
      <Tooltip 
        placement={"topLeft"} 
        color={"orange"} 
        title={props.explantText ? props.explantText : null}>
        <div 
          style={{ 
            fontSize : 18, 
            color : DARK_GRAY_COLOR, 
            fontWeight: 'bold', 
          }}>{props.title}</div>
      </Tooltip>
      <List
        style={{marginTop: 16}}
        grid={{ 
          gutter: 8, 
          sm: 2,
          md: 5,
          lg: 5,
          xl: 5, }}
        dataSource={props.values}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <Row>
              <Col md={24} sm={24} xs={24}>
                <Statistic
                  value={item.amount}
                  precision={props.precision}
                  valueStyle={{ 
                    fontSize: props.valueFontSize, 
                    textAlign: 'center', 
                    fontWeight: 'bold', 
                    color : item.color ? item.color : DARK_GRAY_COLOR }}> 
                </Statistic>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <div 
                  style={{ 
                    fontSize : 14, 
                    color: DARK_GRAY_COLOR, 
                    fontWeight: 'bold', 
                    textAlign:'center',
                  }}>{item.info}</div>
              </Col>
              <Col md={24} sm={24} xs={24}>
                <div 
                  style={{ 
                    fontSize : 14, 
                    color: PRIMARY_COLOR, 
                    fontWeight: 'bold', 
                    textAlign:'center',
                  }}>({item.rai} {t('rai')})</div>
              </Col>
            </Row>
          </List.Item>
        )}/>
    </Card>
  )
}

PlantationTypeCard.defaultProps = {
  style: {},
  title: "",
  values: [],
  valueFontSize: 32,
  precision : 0,
  explantText: null,
}

PlantationTypeCard.propTypes = {
  style: propTypes.object,
  title: propTypes.string,
  values: propTypes.array,
  valueFontSize: propTypes.number,
  precision : propTypes.number,
  explantText: propTypes.string,
}