import React from "react";
import { Button, theme, Popconfirm, message, Tooltip, Space } from "antd";
import { PlusSquareOutlined, EditOutlined, DeleteOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { SECONDARY_COLOR } from "../../constants/color";
import TSTable from "../common/TSTable";
import { URL_CUSTOMER } from "../../constants/urls";
import CashierModal from "./CashierModal";
import { DELETE } from "../../frameworks/HttpClient";
import ColorButton from "../common/ColorButton";
import { useTranslation } from 'react-i18next';

export default function CashierListView() {
  const tableRef = React.useRef(null);
  const [openCashierModal, setOpenCashierModal] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const { t, i18n } = useTranslation();


  const {
    token: { colorError, colorWarning }
  } = theme.useToken();

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetchWithoutResetPage();
    }
  }

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_CUSTOMER.CASHIER}${id}/`)
      tableRef.current.fetch();
      message.success(t("data_deleted_successfully"))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  const columns = [
    {
      title: t("profiles.full_name"),
      dataIndex: "full_name",
      sorter: true,
      searcher: true,
      width: 400
    },
    {
      title: t("email"),
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: t("status"),
      dataIndex: 'is_active',
      width: 60,
      render: (value) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {value 
              ? <CheckCircleFilled style={{ fontSize: 20, color: 'green' }} />
              : <CloseCircleFilled style={{ fontSize: 20, color: 'red' }} />
            }
          </div>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: 140,
      render: (value, original) => {
        return (
          <div style={{ textAlign: "center" }}>
          <Space>
            {original.is_active === true && (
              <Tooltip title={t("actions.edit")}>
                <ColorButton
                  type='primary'
                  override={colorWarning}
                  icon={<EditOutlined />}
                  onClick={() => setTarget(original)} />
              </Tooltip>
            )}
            <Tooltip title={t("actions.delete_data")}>
            {original.is_active === true && (
              <Popconfirm
                title={t("delete_User")}
                description={t("do_you_want_to_delete_this_user")}
                onConfirm={() => handleDelete(value)}
                okText={t("actions.delete_data")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}>
                <ColorButton
                  type='primary'
                  override={colorError}
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
              
            )}
            </Tooltip>
            </Space>
          </div>
        )
      }
    },
  ]

  return (
    <div>
      <div>
        <ColorButton
          type={"primary"}
          icon={<PlusSquareOutlined />}
          onClick={() => setOpenCashierModal(true)}
          override={SECONDARY_COLOR}
          style={{ marginBottom: 8, float: "right" }}>
          {t("add_User")}
        </ColorButton>
      </div>

      <TSTable
        style={{ marginTop: 16 }}
        ref={tableRef}
        columns={columns}
        url={URL_CUSTOMER.CASHIER}
        rowKey={"id"}
        size={"small"}
        pageSize={10} />

      <CashierModal
        open={(openCashierModal || target) ? true : false}
        target={target}
        onUpdated={() => {
          if (openCashierModal)
            setOpenCashierModal(false);
          if (target)
            setTarget(null);
          refreshData();
        }}
        onClose={() => {
          if (openCashierModal)
            setOpenCashierModal(false);
          if (target)
            setTarget(null);
        }}
      />
    </div>
  )
}