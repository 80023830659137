import React from 'react';
import TSTable from '../common/TSTable';
import { useTranslation } from 'react-i18next';
import { Row, Col, Space, theme, Popconfirm, Tooltip, Alert, message, Skeleton, Input, Button } from 'antd'
import { List } from 'antd-mobile'
import { PlusCircleOutlined, FilePdfOutlined, FileExcelOutlined, EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import ColorButton from '../common/ColorButton';
import { SECONDARY_COLOR } from '../../constants/color';
import FactoryCustomerModal from './FactoryCustomerModal';
import { URL_DELIVERY } from '../../constants/urls';
import { DELETE, GET } from '../../frameworks/HttpClient';
import { useUserContext } from '../../hooks/UserSettingProvider';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from '../../constants/string';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
  
export default function FactoryCustomerView (props) { 
  const { t } = useTranslation();
  const [target, setTarget] = React.useState(null);
  const tableRef = React.useRef(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const { user } = useUserContext();
  const [customer, setCustomer] = React.useState([])
  const [dataLazyLoad ,setDataLazyLoad] = React.useState([])
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const {
    token: { colorError, colorWarning }
  } = theme.useToken();
  
  const columns = [
    {
      title: t("fac_customer.code"),
      dataIndex: "code",
      sorter: true, 
      searcher : true,
    },
    {
      title: t("fac_customer.business_name"),
      dataIndex: "name",
      sorter: true, 
      searcher : true,
    },
    {
      title: t("fac_customer.invoice_no"),
      dataIndex: "invoice_id",
      sorter: true, 
      searcher : true,
    },
    {
      title: `${t("fac_customer.office")}/${t("fac_customer.branch")} `,
      dataIndex: "type",
      sorter: true, 
      searcher : true,
    },
    {
      title: t("fac_customer.address"),
      dataIndex: "address",
      sorter: true, 
      searcher : true,
    },
    {
      title: t("fac_customer.office_phone"),
      dataIndex: "phone", 
      sorter: true, 
      searcher : true,
    },
    {
      title: t('action'),
      dataIndex: "id",
      skipReport: true,
      render: (value, original) => {
        return (
          <div style={{textAlign: "center"}}>
            <Space>
              <Tooltip title={t("actions.edit")}>
                <ColorButton
                  type={"primary"}
                  override={colorWarning}
                  icon={<EditOutlined />}
                  onClick={() => setTarget(original)} />
              </Tooltip>
              <Tooltip title= {t('actions.delete_data')}>
                <Popconfirm
                  title={t("farmers.delete_title")}
                  description={t("farmers.delete_confirm")}
                  onConfirm={() => handleDelete(value)}
                  okText={t("delete")}
                  okButtonProps={{ danger: true }}
                  cancelText={t("cancel")}>
                  <ColorButton 
                    type={"primary"}
                    override={colorError} 
                    icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </Space>
          </div>
        )
      }
    }
  ]

  const refreshData = () => {
    if(!isOnMobile && tableRef.current){
      tableRef.current.fetchWithoutResetPage();
    } else if (isOnMobile){
      fetchCustomer();
    }
  }

  const handleDelete = async(id) => {
    try {
      await DELETE(`${URL_DELIVERY.SI_CUSTOMER}${id}/`)
      message.success(`${t('data_deleted_successfully')}`);
      refreshData();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const exportTable = async(type) => {
    if(tableRef.current){
      if(type == "pdf"){
        await tableRef.current.exportPDF({
          title: t("member_list"),
          subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
          // landscape: true
        })
      } else {
        await tableRef.current.exportXlsx({
          title: t("member_list"),
          subtitle: `${t("data_retrieval_date")} ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
          filename: `${t("member_list")} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
        })
      }
    }
  }

  const fetchCustomer = async() => {
    try {
      const response = await GET(URL_DELIVERY.SI_CUSTOMER, { factory : _.get(user, 'factory', null)})
      setCustomer(response.data.results)
      setDataLazyLoad(response.data.links.next)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const fetchMoreData= () => {
    setTimeout(fatchDataLazyload, 1000);
  };

  const fatchDataLazyload = async () =>{
    setLoading(true);
    try{
      if(dataLazyLoad){
        const response = await GET(dataLazyLoad)
        setCustomer([...customer, ...response.data.results])
        setDataLazyLoad(response.data.links.next)
      }
    } catch (error) {
      messageApi.error(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const fetchDataCustomerSearch = async() => {
    try {
      const response = await GET(`${URL_DELIVERY.SI_CUSTOMER}?page_size=10&search=${search}`, { factory : _.get(user, 'factory', null)});
      setCustomer(response.data.results);
      setDataLazyLoad(response.data.links.next);
    } catch (error) {
      message.error(error.errorMessages);
    } 
  }

  React.useEffect(() => {
    if(isOnMobile){
      fetchCustomer();
    }
  },[user, isOnMobile])

  return (
    <div>
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: '1rem', textAlign: "left"}}
        />
      }

      {!isOnMobile ?
      <>
        <Space style={{ float: 'right' }}>
          <Row gutter={8}>
            <Col>
              <ColorButton
                icon={<FilePdfOutlined />}
                style={{float: "right"}}
                onClick={() => exportTable("pdf")}>
                PDF
              </ColorButton>
            </Col>
            <Col>
              <ColorButton
                icon={<FileExcelOutlined />}
                style={{float: "right"}}
                onClick={() => exportTable("excel")}>
                Excel
              </ColorButton>
            </Col>
            <Col>
              <ColorButton 
                type={"primary"}
                override={SECONDARY_COLOR}
                icon={<PlusCircleOutlined />} 
                style={{float: "right", marginBottom: '1rem'}}
                onClick={() => setTarget('add')}>
                  {t("fac_customer.add")}
              </ColorButton>
            </Col>
          </Row>
        </Space>

        <TSTable
          ref={tableRef}
          rowKey={"id"}
          size={"small"}
          url={`${URL_DELIVERY.SI_CUSTOMER}`}
          params={{ factory : _.get(user, 'factory', null)}}
          columns={columns}
          pageSize={10}
        />
      </>
      :

      <>
        <div>
          <ColorButton 
            block color='warning'
            size='large'
            icon={<PlusCircleOutlined />} 
            type={"primary"}
            style ={{ marginBottom : 16}}
            override={SECONDARY_COLOR}
            onClick={() => setTarget('add')}
            
            >
              {t("fac_customer.add")}
          </ColorButton>
            <Space.Compact style={{ width: '100%' }} size='middle'>
              <Input placeholder={`${'search'}...`} onPressEnter={() => fetchDataCustomerSearch()} onChange={e => setSearch(_.get(e, 'target.value', ''))}/>
              <Button icon={<SearchOutlined/>} onClick={() => fetchDataCustomerSearch()}>{t("search")}</Button>
            </Space.Compact>
            <List>
              {customer && customer.map(item => (
                <List.Item
                  key={item.id}
                  description={`${item.code}`}
                  onClick={() =>  {
                      setTarget(item);
                  }}
                >
                  {item.name}
                </List.Item>))}
            </List>
          <InfiniteScroll
            dataLength={customer.length}
            next={fetchMoreData}
            hasMore={dataLazyLoad}
            hasChildren={true}
            loader={
              <Skeleton
                paragraph={{
                  rows: 1,
                }}
                active
              />
            }
          />
        </div>
      </>
      }

      <FactoryCustomerModal
        open={(target)}
        target={target}
        onClose={() => setTarget(null)}
        onUpdate={() => {
          setTarget(null)
          refreshData()
        }}
        handleDelete={(id) => {
          handleDelete(id)
          setTarget(null)
          refreshData()
        }}
      />
    </div>
  )
}