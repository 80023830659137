import { Card, Col, Divider, Empty, Modal, Row, theme, Typography } from "antd";
import moment from "moment";
import React from "react";
import { HISTORY_DETAIL, HISTORY_DETAIL_NAME, HUMAN_DATE_FORMAT, TIME_FORMAT, UNIT_TRANSLATION } from "../../constants/string";
import { URL_WEIGHT } from "../../constants/urls";
import { GET } from "../../frameworks/HttpClient";
import { useWindowDimensionContext } from "../../hooks/WindowDimensionProvider";
import Header from "../common/Header";
import TSTable from "../common/TSTable";
import { useTranslation } from 'react-i18next';



export default function ScaleCardHistoryModal(props) {
  const {
    open,
		onClose,
    target
	} = props;
  const filterEmptyOut = (jsonInput) => {
    const entries = Object.entries(jsonInput)
    const nonEmptyOrNull = entries.filter(([ _, val]) => val !== "" && val !== null && val != undefined && val != [])
    return Object.fromEntries(nonEmptyOrNull)
  }

  const {
		token: { colorPrimary, colorWarning, colorInfo, colorSuccess, colorError },
	} = theme.useToken();
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const { Text, Title } = Typography;
  const tableRef = React.useRef(null);
  const [ dataHistory, setDataHistory ] = React.useState(null);
  const { t, i18n } = useTranslation();


  const columns = [
    { title: t("date"), width: 100, align: 'top',  dataIndex: 'updated', render: (value) => <div style= {{textAlign : 'center', verticalAlign: 'top'}}>{moment(value).format(HUMAN_DATE_FORMAT)}</div> },
    { title: t("time"), width: 80, align: 'top', dataIndex: 'updated', render: (value) => <div style= {{textAlign : 'center', verticalAlign: 'top'}}>{moment(value).format(TIME_FORMAT)}</div>},
    { title: t("user_who_made_the_edit"), width: 250, dataIndex: 'updated_by_name', render:(value) => value ? value : "-" },
    // { 
    //   title: 'รายละเอียด', width: 180,
    //   render:(value,original) => 
    //   <>
    //     {Object.keys(original.new_values).map((key, index) => {
    //       if(HISTORY_DETAIL_NAME[`${key}`])
    //         return (
    //           <div>
    //             {(index !== 0 ) && <Divider style ={{ marginTop: 10, marginBottom: 10}}/>}
    //             {HISTORY_DETAIL_NAME[`${key}`]} 
    //           </div>
    //         )
    //     })
    //   }
    //   </>
    // },
    { 
      title: t("before_editing"),
      render:(value,original) => {
        return(
          renderBeforeEdit(original)
        )
    }},
    { 
      title: t("after_editing"),
      render:(value,original) => {
        return(
          renderAfterEdit(original)
        )
      }
    },
  ]

  const fetchData = () => {
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  const fetchDataHistory = async() => {
    try {
      const response = await GET(`${URL_WEIGHT.TICKET_UPDATELOG}?ticket=${target}`)
      setDataHistory(response.data.results)
    } catch (error) {
      console.log(error.errorMessage);
    }
  }

  const renderBeforeEdit = (original) => {
    let data = filterEmptyOut(original.old_values)
        return(
        <>
        {Object.keys(data).map((key, index) => {
          
          if(HISTORY_DETAIL_NAME[`${key}`] && key !== HISTORY_DETAIL.STOCK_REFS && key !== HISTORY_DETAIL.PLANTATION_SOURCES)
            return (
              <div>
                <b style={{ color : colorError}}>{`${HISTORY_DETAIL_NAME[`${key}`]} : `}</b>
                {(key == HISTORY_DETAIL.PRODUCT_NAME || key == HISTORY_DETAIL.VEHICLE_LICENSE_PLATE || key == HISTORY_DETAIL.CUSTOMER) ? ( 
                  data[key]
                ):(
                  parseFloat(data[key]).toFixed(2)
                )
                }
              </div>
            )

            if(key == HISTORY_DETAIL.PLANTATION_SOURCES){
              return(
                <>
                {original.new_values.plantation_sources.map((value) => {
                  return (
                    <div>
                    <b style={{ color : colorError}}>{`${HISTORY_DETAIL_NAME[`${key}`]} : ${value.plantation_code}`}</b><br/>
                    {`${t("weight_from_farm")} : ${parseFloat(value.weight).toFixed(2)}` }<br/>
                    {`จำนวนมีด(วัน) : ${value.cutting_days}` }
                    </div>
                  )
                })}
                </>)
            }

            if(key == HISTORY_DETAIL.STOCK_REFS)
              return(
                <>
                {original.old_values.stock_refs.map((value) => {
                  return (
                    <div>
                    <b style={{ color : colorError}}>{`${HISTORY_DETAIL_NAME[`${key}`]} : `} {value.stock_code}</b><br/>
                    {`${t("batch_weight")} : ${parseFloat(value.weight).toFixed(2)}` }<br/>
                    {`${t("unit")} : ${UNIT_TRANSLATION[value.unit]}` }
                    </div>
                  )
                })}
                </>)
          }
          )
        }
        </>
      )}

  const renderAfterEdit = (original) => {
    {
      let data = filterEmptyOut(original.new_values)
      return(
      <>
        {Object.keys(data).map((key, index) => {
          if(HISTORY_DETAIL_NAME[`${key}`] && key !== HISTORY_DETAIL.STOCK_REFS && key !== HISTORY_DETAIL.PLANTATION_SOURCES){
            return (
              <div>
                <b style={{ color : 'green'}}>{`${HISTORY_DETAIL_NAME[`${key}`]} : `}</b>
                {(key == HISTORY_DETAIL.PRODUCT_NAME || key == HISTORY_DETAIL.VEHICLE_LICENSE_PLATE || key == HISTORY_DETAIL.CUSTOMER_NAME) ? ( 
                  data[key]
                ):(
                  parseFloat(data[key]).toFixed(2)
                )
                }
              </div>
            )
        }

        if(key == HISTORY_DETAIL.PLANTATION_SOURCES){
          return(
            <>
            {original.new_values.plantation_sources.map((value) => {
              return (
                <div>
                <b style={{ color : 'green'}}>{`${HISTORY_DETAIL_NAME[`${key}`]} : ${value.plantation_code}`}</b><br/>
                {`น้ำหนักจากแปลง : ${parseFloat(value.weight).toFixed(2)}` }<br/>
                {`จำนวนมีด(วัน) : ${value.cutting_days}` }
                </div>
              )
            })}
            </>)
        }

          if(key == HISTORY_DETAIL.STOCK_REFS){
            return(
              <>
              {original.new_values.stock_refs.map((value) => {
                return (
                  <div>
                  <b style={{ color : 'green'}}>{`${HISTORY_DETAIL_NAME[`${key}`]} : ${value.stock_code}`}</b><br/>
                  {`น้ำหนักในล็อต : ${parseFloat(value.weight).toFixed(2)}` }<br/>
                  {`หน่วย : ${UNIT_TRANSLATION[value.unit]}` }
                  </div>
                )
              })}
              </>)
          }
        })}
      </>
    )}
  }


  React.useEffect(() => {
    if(target){
      if(isOnMobile){
        fetchDataHistory()
      }
      if (tableRef.current){
        tableRef.current.fetch({ ticket: target });
      }
    }
  },[target])

    return (
      <Modal
        open={open}
        width={1200}
        footer={[]}
        onCancel={onClose}
        cancelText={t("close")}
      >
      <Header title={<h3>{t("edit_history")}</h3>}/>
      {!isOnMobile &&
      <Row style={{ marginTop: 10}} >
        <Col md={24} sm={24} xs={24} style={{ textAlign: 'start'}}>
          {/* <Header h2 title={"ประวัติการแก้ไข"}></Header> */}
          <TSTable
            ref={tableRef}
            style={{ marginTop: 10, width: "auto" }}
            columns={columns}
            url={`${URL_WEIGHT.TICKET_UPDATELOG}`}
            params={{ ticket: target }}
            rowKey='id'
            pageSize={5}
            />
        </Col>
			</Row>}

      {isOnMobile && 
        ((dataHistory && dataHistory.length > 0) ? dataHistory.map((original) =>
        <Card style={{ width: '100%', marginTop: 10 }}>
          <Row>
            <Col md={8} sm={8} xs={24}>
              <div><Title level={5} style={{ marginTop : 10}}>{`${moment(original.created).format(HUMAN_DATE_FORMAT)} - ${moment(original.created).format(TIME_FORMAT)}`}</Title></div>
              <div><Text>{t("user_who_made_the_edit")} : {original.updated_by_name}</Text></div>
            </Col>
            <Col md={8} sm={8} xs={12}>
              <div><Title level={5} style={{ marginTop : 10}}>{t("before_editing")}</Title></div>
              <div style={{ marginLeft : 10 }}>{renderBeforeEdit(original)}</div>
            </Col>
            <Col md={8} sm={8} xs={12}>
              <div><Title level={5} style={{ marginTop : 10}}>{t("after_editing")}</Title></div>
              <div style={{ marginLeft : 10 }}>{renderAfterEdit(original)}</div>
            </Col>
          </Row>
        </Card>) : <Empty style={{marginTop : 10}}/>)
      }
      
      </Modal>
    )
}