import React from "react";
import { Layout, Button, theme, Modal, Tooltip, Image, Card, Alert,  } from 'antd'
import Cookies from "js-cookie";
import { useTranslation } from 'react-i18next';



export default function LogoutCashierModal(props) {
  // const [isOpen, setIsOpen] = React.useState(false);
  const logout = () => {
    props.onClose();
    props.onCashierId();
    Cookies.remove('id');
  };
  const { t, i18n } = useTranslation();

  return(
    <Modal 
      title={t("end_shift")}
      open={props.open}
      onOk={logout}
      onCancel={props.onClose} >
      <p>{t("do_you_want_to_end_your_shift")}</p>
    </Modal>
  )
}
