import React from 'react';
import { Tabs } from 'antd';
import { useUserContext } from '../../hooks/UserSettingProvider';
import StockView from './StockView';
import { useTranslation } from 'react-i18next';
import StockHistory from './StockHistory';

export default function StockTabsView() {
  const { user } = useUserContext();
  const [activeKey, setActiveKey] = React.useState("1");
  const onTabsChange = (key) => setActiveKey(key);
  const { t } = useTranslation();

  const TabStock = [
    {
      key: "1", 
      label: <span>{t("stock")}</span>, 
      children: <StockView/>
    },
    {
      key: "2", 
      label: <span>{t("history")}</span>,
      children: <StockHistory/> 
    },
  ]

  return (
    <Tabs
      activeKey={activeKey}
      onChange={onTabsChange}
      size='large'
      items={TabStock}
      /> 
  )
}
