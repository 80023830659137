import React from 'react';
import propTypes from "prop-types";
import { Radio, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

export default function LanguageMenu(props) {
	const { t, i18n } = useTranslation();
  const initialLang = Cookies.get('traztru-lng')
  const { Text } = Typography;
	const [lng, setLng] = React.useState(initialLang ? initialLang : props.initialLang );

	const handleItemClick = async (e) => {
    props.setIsLoading(true)
    try {
      await i18n.changeLanguage(e.toLowerCase());
      Cookies.set('traztru-lng', e)
		  setLng(e);  
    } finally {
      props.setIsLoading(false)
    }
		
	};

	React.useEffect(() => {
		setLng(i18n.language.toUpperCase())
	}, [])

  return (
    <Space style ={{ marginRight : 16}}>
      <Radio.Group value={lng} onChange={(e) => handleItemClick(e.target.value)}>
        <Radio.Button value="TH"><Text strong>TH</Text></Radio.Button>
        <Radio.Button value="EN"><Text strong>EN</Text></Radio.Button>
      </Radio.Group>
    </Space>
	)
};

LanguageMenu.defaultProps = {
  setIsLoading: () => {},
	position: "left",
	initialLang: "TH",
}

LanguageMenu.propTypes = {
  setIsLoading: propTypes.func,
	position: propTypes.string,
	initialLang: propTypes.string,
}