import React from 'react';
import { Checkbox as CheckboxAntd, List} from 'antd-mobile'
import * as _ from 'lodash';
import ErrorMessages from '../common/ErrorMessages';
import { Spin, Pagination } from 'antd';
import { URL_DELIVERY } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';


export default function DeliveryMobileList (props) {
  const {
    checkedList,
    handleCheck,
  } = props;

  const [datas, setDatas] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0)
  const [Loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const fetchData = async (page) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const searchParams = {
        'page_size': 10,
        'page': page
      }
      const response = await GET(URL_DELIVERY.DELIVERY_TRANSACTION, searchParams)
      setDatas(_.get(response, 'data.results', []))
      setTotal(response.data.total)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [])

  return (
    <Spin spinning={Loading}>
      <ErrorMessages message={errorMessages}/>
      <List>
        {datas.map(data => (
          <List.Item key={_.get(data, 'id', '')}>
            <CheckboxAntd
              onChange={(e) => handleCheck(data, e)}
              checked={checkedList.includes(data.id)}>
              {`${_.get(data, 'code', '-')} - ${_.get(data, 'product_name', '')} (${_.get(data, 'sender_name', 'unknown sender')})`}
            </CheckboxAntd>
          </List.Item>
        ))}
      </List>
      <div style={{textAlign:'center'}}>
        <Pagination
          defaultPageSize={10}
          defaultCurrent={1}
          showTotal={(total) => `Total ${total} items`}
          showSizeChanger={true}
          current={page}
          total={total}
          onChange={(page) => {
            setPage(page)
            fetchData(page)
          }}/>
      </div>
    </Spin>
  )
}