import React from "react";
import { Col, Modal, Row, message, Select, Spin, theme, Button } from "antd";
import Form from "antd/es/form/Form";
import { URL_CUSTOMER, URL_MEMBER } from "../../constants/urls";
import { GET, POST } from "../../frameworks/HttpClient";
import _ from "lodash";
import ColorButton from "../common/ColorButton";
import { useTranslation } from 'react-i18next';


export default function SearchFarmerModal(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [farmer, setFarmer] = React.useState([]);
  const [debouncedFarmer, setDebouncedFarmer] = React.useState(null);
  const { t, i18n } = useTranslation();


  const {
    token: { colorPrimary, colorWarning },
  } = theme.useToken();
  
  const debounceFarmer = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedFarmer(_searchVal) }, 800),
    []
  );

  const fetchFarmer = async () => {
    setFetching(true);
    try {
      const response = await GET(`${URL_MEMBER.FARMER}?search=${debouncedFarmer}`)
      setFarmer(response.data.results.map((item) => {
        return {
          ...item,
          label: `${item.full_name}, ${item.phone}`,
          value: item.user
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

    const handleOK = async() => {
        setLoading(true);
        let data = {}
        try {
            data = await form.validateFields();
            let farmer = {ref : data.name.value}
  
            await POST(URL_CUSTOMER.CUSTOMER, farmer)
  
            props.onUpdate();
        } catch (error) {
            message.error(error.errorMessages);
        } finally {
            setLoading(false);
        }
      }

  const formItemLayout = {labelCol: { span: 6 }}

  React.useEffect(() => {
    if (!props.open && form) {
      form.resetFields();
    }
  }, [props.open])

  React.useEffect(() => {
    if (debouncedFarmer)
      fetchFarmer();
  }, [debouncedFarmer])

  return (
    <Modal
      {...formItemLayout}
      title={t("add_farmers")}
      open={props.open}
      onCancel={props.onClose}
      confirmLoading={loading}
      onOk={handleOK}
      footer={
        <>
          <Button loading={loading} onClick={props.onClose}>{t("cancel")}</Button>
          <ColorButton type='primary' override={props.target ? colorWarning : colorPrimary} loading={loading} onClick={handleOK}>{props.target ? t("undo") : t("add_farmers")}</ColorButton>
        </>
      }>

      <Form
        form={form}
        autoComplete="off"
        layout={"vertical"}>
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item
              name={"name"}
              label={t("search_for_farmers")}
              rules={[{ required: true }]}>
              <Select 
                initialvalues={"-"}
                autoComplete="off" 
                showSearch
                labelInValue
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : "-"}
                onSearch={debounceFarmer}
                options={farmer}
                allowClear={"-"}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}