import React from "react";
import { Col, Modal, Row, Input, message, Select, Spin, Button, theme, Popconfirm } from "antd";
import Form from "antd/es/form/Form";
import { DELETE, GET, PATCH, POST } from "../../frameworks/HttpClient";
import { URL_CUSTOMER, URL_COMMON } from "../../constants/urls";
import _ from "lodash";
import { useWindowDimensionContext } from "../../hooks/WindowDimensionProvider";
import { CUSTOMER_TYPE } from "../../constants/string";
import ColorButton from "../common/ColorButton";
import { useTranslation } from 'react-i18next';

export default function CustomerModal(props) {
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');

  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [subdistricts, setSubdistricts] = React.useState([]);
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [isGeneral, setIsGeneral] = React.useState(false);
  const { t, i18n } = useTranslation();


  const {
    token: { colorPrimary, colorWarning },
  } = theme.useToken();


  const debounceSubdistrict = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );

  const fetchSubdistrict = async () => {
    setFetching(true);
    try {
      const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}`)
      setSubdistricts(response.data.results.map((item) => {
        return {
          ...item,
          label: item.display_name,
          value: item.subdistrict_id
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  const handleOK = async() => {
    setLoading(true);
    let data = {}
    try {
      data = await form.validateFields();
      data['sub_district'] = data.sub_district.value

      if(props.target){
        await PATCH(`${URL_CUSTOMER.CUSTOMER}${props.target.id}/`, data)
      } else {
        await POST(URL_CUSTOMER.CUSTOMER , data)
      }
      props.onUpdate();

    } catch (error) {
      message.error(t("please_fill_your_information_completely"));
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_CUSTOMER.CUSTOMER}${id}/`)
      message.success(t("data_deleted_successfully"))
      props.onUpdate();
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (debouncedCode)
      fetchSubdistrict();
  }, [debouncedCode])

  React.useEffect(() => {
    if (props.open) {
      const data = props.target
      if (data) {
        form.setFieldsValue({
          ...data,
          sub_district: data.sub_district ? {value : data.sub_district, label : data.sub_district_detail} : null
        });
        // Check general type ?
        setIsGeneral((data.type === CUSTOMER_TYPE.GENERAL) ? true : false);
      } else {
        form.resetFields();
      }
    }
  }, [props.open])

  return (
    <div>
      <Modal
        title={props.target ? t("customer_information") : t("add_a_customer")}
        open={props.open}
        onCancel={props.onClose}
        confirmLoading={loading}
        onOk={handleOK}
        footer={
          <>
            {props.target && 
              <Popconfirm
                title={t("confirm_delete_customer")}
                onConfirm={() => handleDelete(props.target.id)}
                okText= {t("actions.delete_data")}
                okButtonProps={{ danger: true }}
                cancelText={t("cancel")}
                placement='topRight'>
          
                <ColorButton
                  style={{ float: 'left'}}
                  type={'primary'}
                  danger >
                 {t("actions.delete_data")}
                </ColorButton>
              </Popconfirm>
            }
            <ColorButton loading={loading} onClick={props.onClose}> {t("cancel")} </ColorButton>
            <ColorButton 
              style={{background: props.target ? colorWarning : colorPrimary, color : 'white'}} 
              loading={loading} 
              disabled={(props.target && !isGeneral)}
              onClick={handleOK}>
                {props.target ? t("actions.edit") : t("add_general_customers")}
            </ColorButton>
          </>
        }>
          
        <Form
          form={form}
          autoComplete="off"
          layout={"vertical"}
          >
          
          <Row gutter={16}>
              
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={"full_name"}
                label={t("profiles.full_name"," :")}
                rules={[{ required: true }]}>
                <Input autoComplete="off"  />
                
              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={"code"}
                label={t("code"," :")}>
                <Input autoComplete="off" disabled={props.target && !isGeneral}/>
              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={"address"}
                label={t("farmers.address")}
                rules={[{ required: true }]}>
                  <Input.TextArea />
              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Form.Item  
                name={"sub_district"}
                label={t("subdistrict_district_province_zip_code")}
                rules={[{ required: true }]}>
              <Select
                initialvalues={"-"}
                autoComplete="off" 
                showSearch
                labelInValue
                filterOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : "-"}
                onSearch={debounceSubdistrict}
                options={subdistricts}
                allowClear={"-"}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
