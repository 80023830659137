import React from "react";
import { Alert, Button, Col, DatePicker, Form, Modal, Row, Select, Spin, theme } from "antd";
import Header from "../common/Header";
import { useForm } from "antd/es/form/Form";
import { DATE_FORMAT, MONTH_FORMAT, PLANTATION_STATUS_OPTIONS, REPORT_FILTER } from "../../constants/string";
import { FileExcelOutlined, FilePdfOutlined, CodeOutlined } from "@ant-design/icons";
import { URL_COMMON, URL_CUSTOMER, URL_DELIVERY, URL_MEMBER } from "../../constants/urls";
import { GET } from "../../frameworks/HttpClient";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const EXPORT_FORMAT = {
  EXCEL: 'excel',
  GEOJSON: 'geojson'
}

export default function ReportModal(props) {
  const [data, setData] = React.useState(null);
  const [form] = useForm();
  const [filters, setFilters] = React.useState({});
  const [fetching, setFetching] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(null);
  const { t, i18n } = useTranslation();

  // Options
  const [stationsOptions, setStationsOptions] = React.useState([]);
  const [DELOptions, setDELOptions] = React.useState([]);
  const [productTypeOptions, setProductTypeOptions] = React.useState([]);
  const [provinceOptions, setProvinceOptions] = React.useState([]);

  // Search Debounce
  const [debouncedCode, setDebouncedCode] = React.useState("");
  const [debouncedDEL, setDebouncedDEL] = React.useState(null);
  const [debouncedProductType, setDebouncedProductType] = React.useState(null);

  const {
    token: { colorSuccessTextActive, colorWarning }
	} = theme.useToken();

  const debounceStations = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal ? _searchVal : '') }, 800),
    []
  );

  const debounceDEL = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedDEL(_searchVal ? _searchVal : '') }, 800),
    []
  );

  const debounceProductType = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedProductType(_searchVal ? _searchVal : '') }, 800),
    []
  );

  const updateFilters = (myData) => {
		if (myData) {
			setFilters({
        showStation: myData.filter.find(filter => filter === REPORT_FILTER.STATION) ? true : false,
        showUserCC: myData.filter.find(filter => filter === REPORT_FILTER.USER_CC) ? true : false,
				showMonth: myData.filter.find(filter => filter === REPORT_FILTER.MONTH) ? true : false,
        showMonthRange: myData.filter.find(filter => filter === REPORT_FILTER.MONTH_RANGE) ? true : false,
        showDel001: myData.filter.find(filter => filter === REPORT_FILTER.DEL001) ? true : false,
        showProductType: myData.filter.find(filter => filter === REPORT_FILTER.PRODUCT_TYPE) ? true : false,
        showCCT: myData.filter.find(filter => filter === REPORT_FILTER.CCT) ? true : false,
        showApproveDateRange: myData.filter.find(filter => filter === REPORT_FILTER.APPROVE_DATE_RANGE) ? true : false,
        showStatus: myData.filter.find(filter => filter === REPORT_FILTER.STATUS) ? true : false, // Plantation status
        showProvince: myData.filter.find(filter => filter === REPORT_FILTER.PROVINCE) ? true : false,
			})
		} else {
			setFilters({});
		}
	}

  const getParams = (values) => {
    let params = {...values}
    
    if (values[REPORT_FILTER.MONTH_RANGE]) {
      params["start_month"] = values[REPORT_FILTER.MONTH_RANGE][0].format(MONTH_FORMAT);
      params["end_month"] = values[REPORT_FILTER.MONTH_RANGE][1].format(MONTH_FORMAT);
      delete params[REPORT_FILTER.MONTH_RANGE]
    }

    if (values[REPORT_FILTER.STATION]) {
      params["station"] = values[REPORT_FILTER.STATION].value;
    }

    if (values[REPORT_FILTER.USER_CC]) {
      params["station"] = values[REPORT_FILTER.USER_CC].value;
    }

    if (values[REPORT_FILTER.MONTH]) {
      params["month"] = values[REPORT_FILTER.MONTH].format(MONTH_FORMAT);
    }

    if (values[REPORT_FILTER.DEL001]) {
      params["del001"] = values[REPORT_FILTER.DEL001].value;
    }

    if (values[REPORT_FILTER.CCT]) {
      params[REPORT_FILTER.CCT] = values[REPORT_FILTER.CCT].value;
    }

    if (values[REPORT_FILTER.APPROVE_DATE_RANGE]) {
      params["approve_date_start"] = values[REPORT_FILTER.APPROVE_DATE_RANGE][0].format(DATE_FORMAT);
      params["approve_date_end"] = values[REPORT_FILTER.APPROVE_DATE_RANGE][1].format(DATE_FORMAT);
      delete params[REPORT_FILTER.APPROVE_DATE_RANGE]
    }

		return params;
	}

  const fetchStations = async () => {
		try {
			setFetching(true)
      let optionsFactory = []

      // if(filters["showUserCC"] || filters["showStation"] || filters["showCCT"]){
			//   const CC = await GET(`${URL_MEMBER.COLLECTING_CENTER}?search=${debouncedCode ? debouncedCode : ''}`);
			//   optionsCC = (CC.data.results.map(item => ({...item, value: (filters["showUserCC"] ? item.user : item.id), label: item.name})))
      // }

      // if(filters["showStation"]){
        const factory = await GET(`${URL_MEMBER.FACTORY}?name=${debouncedCode}`);
        optionsFactory = (factory.data.results.map(item => ({...item, value: item.id, label: item.name})))
      // }
        setStationsOptions(optionsFactory)

		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setFetching(false)
		}
	}

  const fetchDEL = async () => {
		try {
			setFetching(true)
			const del = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}?code=${debouncedDEL}`);
      const delTicket = del.data.results
			let optionsDEL = (delTicket.map(item => ({...item, value: item.id, label: `${item.code} - ${item.product_name}`})))
      setDELOptions([...optionsDEL])

		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setFetching(false)
		}
	}

  const fetchProductType = async () => {
		try {
			setFetching(true)
			const Productype = await GET(`${URL_CUSTOMER.MASTER_PRODUCT_TYPE}`);
			setProductTypeOptions(Productype.data.results.map(item => ({...item, value: item.id, label: item.name})))
		} catch (error) {
			setErrorMessages(error.errorMessages);
		} finally {
			setFetching(false)
		}
	}

  const fetchProvinces = async () => {
    try {
      const response = await GET(URL_COMMON.PROVINCE, {page_size: 999})
      setProvinceOptions(response.data.map((item) => {
        return {
          ...item,
          label: item.name_th,
          value: item.id
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const handleSubmit = async (format) => {
    if (!data.url) {
			setErrorMessages("Report not found!");
			return;
		}

    props.onStartLoading()
    setIsLoading(true)
	
		// Prepare data
		setErrorMessages(null);
		try {
      if(form.getFieldValue(REPORT_FILTER.PRODUCT_TYPE)){
        const productType = form.getFieldValue(REPORT_FILTER.PRODUCT_TYPE)
        form.setFieldValue(REPORT_FILTER.PRODUCT_TYPE, productType.value)
      }
      
			const values = await form.validateFields();
			const params = getParams(values);
			// Goto export
      const response = await GET(data.url, params, false, true) // Download as blob
			
			if(props.namePrefix != null) {
				const fileURL = URL.createObjectURL(response.data);
				const fileLink = document.createElement('a');
        const suffix = format === EXPORT_FORMAT.GEOJSON ? 'geojson' : 'xlsx'
				fileLink.href = fileURL;
        fileLink.download = `${props.namePrefix.replace(/ /g, "_")}_${moment().unix()}.${suffix}`;
				fileLink.click();
				setTimeout(() => {
					fileLink.remove();
				}, 100);
			}
		} catch (error) {
			if (error.errorMessages) 
				setErrorMessages(error.errorMessages);
		}
		finally {
      props.onEndLoading()
      setIsLoading(false)
	  }
  }

  React.useEffect(() => {
    if (debouncedCode)
      fetchStations();
  }, [debouncedCode])

  React.useEffect(() => {
    if(debouncedDEL)
      fetchDEL();
  }, [debouncedDEL])

  React.useEffect(() => {
    if(debouncedProductType)
      fetchProductType();
  }, [debouncedProductType])

  React.useEffect(() => {
		if (filters && (Object.keys(filters).length !== 0)) {
			if (filters["showStation"] || filters["showUserCC"] || filters["showCCT"]) {
				fetchStations()
			}
      if (filters["showProductType"]) {
				fetchProductType();
			}
      if (filters["showProvince"]) {
        fetchProvinces();
      }
		}
	}, [filters])

  React.useEffect(() => {
		setData(props.data);
		updateFilters(props.data);
	}, [props.data])

  React.useEffect(() => {
		if (props.open) {
			setErrorMessages(null);
			form.resetFields();
		} else {
      setDebouncedCode("");
    }
	}, [props.open])


  return(
    <Modal
      open={props.open}
      onOk={props.onClose}
      onCancel={props.onClose}
      footer={[
        <Button key={"1"} onClick={props.onClose}>{t("cancel")}</Button>,
        props.data && !props.data.hideExcel && (
          <Button
            key={"2"}
            type={"primary"}
            loading={isLoading}
            icon={<FileExcelOutlined />}
            style={{ background: colorSuccessTextActive }}
            onClick={() => handleSubmit(EXPORT_FORMAT.EXCEL)}
            >
            {"Excel"}
          </Button>
        ),
        props.data && props.data.geojson && (
          <Button
            key={"2"}
            type={"primary"}
            loading={isLoading}
            icon={<CodeOutlined />}
            style={{ background: colorWarning }}
            onClick={() => handleSubmit(EXPORT_FORMAT.GEOJSON)}
          >
            {"GeoJSON"}
          </Button>
        )
    ]}>
      <Header title={data ? data.detail : t("menu.report")}/>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginTop: "12px", marginBottom: "12px", textAlign: "left"}}
          />
        }
      	<Form 
					style={{marginTop: 16}}
					form={form}
					layout={"vertical"}>
          <Row gutter={16} justify={"start"}>
          {data && filters["showMonthRange"] && (
            <Col xl={12} sm={12} xs={24} key={"showMonthRange"}>
              <Form.Item 
                name={REPORT_FILTER.MONTH_RANGE} 
                label={t("reports.month_range")} 
                rules={[{ required: data.required }]}>
                <DatePicker.RangePicker picker="month" style={{ width: '100%' }} inputReadOnly={true} />
              </Form.Item>
            </Col>
					)}
          

          {data && filters["showStation"] && (
							<Col xl={12} sm={12} xs={24} key={"showStation"}>
								<Form.Item 
									name={REPORT_FILTER.STATION} 
									label={"Station"}
                  rules={[{ required: data.required }]}>
									<Select 
                    initialvalues={"-"}
                    autoComplete="off" 
                    showSearch
                    labelInValue
                    filterOption={false}
                    allowClear={true} 
                    options={stationsOptions}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceStations}/>
								</Form.Item>
							</Col>
						)}

            {data && filters["showUserCC"] && (
							<Col xl={12} sm={12} xs={24} key={"showStation"}>
								<Form.Item 
									name={REPORT_FILTER.USER_CC} 
									label={t("collecting_center")}
                  rules={[{ required: data.required }]}>
									<Select 
                    initialvalues={"-"}
                    autoComplete="off" 
                    showSearch
                    labelInValue
                    filterOption={false}
                    allowClear={true} 
                    options={stationsOptions}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceStations}/>
								</Form.Item>
							</Col>
						)}

            {data && filters["showMonth"] && (
              <Col xl={12} sm={12} xs={24} key={"showMonth"}>
                <Form.Item 
                  name={REPORT_FILTER.MONTH} 
                  label={t("month")}
                  rules={[{ required: data.required }]}>
                  <DatePicker picker={"month"} style={{ width: '100%' }} inputReadOnly={true}/>
                </Form.Item>
              </Col>
            )}

            {data && filters["showDel001"] && (
							<Col xl={12} sm={12} xs={24} key={"showDel001"}>
								<Form.Item 
									name={REPORT_FILTER.DEL001} 
									label={"DEL Code"}
                  rules={[{ required: data.required }]}>
									<Select 
                    initialvalues={"-"}
                    autoComplete="off" 
                    showSearch
                    labelInValue
                    filterOption={false}
                    allowClear={true} 
                    options={DELOptions}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceDEL}/>
								</Form.Item>
							</Col>
						)}

            {data && filters["showProductType"] && (
							<Col xl={12} sm={12} xs={24} key={"showProductType"}>
								<Form.Item 
									name={REPORT_FILTER.PRODUCT_TYPE} 
									label={t("product_type")}
                  rules={[{ required: false }]}>
									<Select labelInValue allowClear={true} autoComplete="off" options={productTypeOptions}/>
								</Form.Item>
							</Col>
						)}

            {data && filters["showCCT"] && (
              <Col xl={24} sm={24} xs={24} key={"showCCT"}>
                <Form.Item 
                  name={REPORT_FILTER.CCT} 
                  label={t("collecting_center")}>
                  <Select 
                    initialvalues={""}
                    autoComplete="off" 
                    showSearch
                    labelInValue
                    filterOption={false}
                    allowClear={true} 
                    options={stationsOptions}
                    notFoundContent={fetching ? <Spin size="small" /> : "-"}
                    onSearch={debounceStations}/>
                </Form.Item>
              </Col>
            )}

            {data && filters["showApproveDateRange"] && (
              <Col xl={24} sm={24} xs={24} key={"showApproveDateRange"}>
                <Form.Item 
                  name={REPORT_FILTER.APPROVE_DATE_RANGE} 
                  label={t("approved_date")}>
                    <DatePicker.RangePicker picker={"date"} style={{ width: '100%' }} inputReadOnly={true} />
                </Form.Item>
              </Col>
            )}

            {data && filters["showStatus"] && (
              <Col xl={12} sm={12} xs={24} key={"showStatus"}>
                <Form.Item 
                  name={REPORT_FILTER.STATUS} 
                  label={t("status")}>
                  <Select 
                    autoComplete="off" 
                    showSearch
                    filterOption={false}
                    allowClear={true} 
                    options={PLANTATION_STATUS_OPTIONS}/>
                </Form.Item>
              </Col>
            )}

            {data && filters["showProvince"] && (
              <Col xl={12} sm={12} xs={24} key={"showProvince"}>
                <Form.Item 
                  name={REPORT_FILTER.PROVINCE} 
                  label={t("province")}>
                  <Select 
                    autoComplete="off" 
                    showSearch
                    allowClear={true}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={provinceOptions}/>
                </Form.Item>
              </Col>
            )}

          </Row>
        </Form>
    </Modal>
  )
}