import { Alert } from 'antd';
import React from 'react';


export default function ErrorMessages(props) {
  const { message, style } = props;

  return message ? (
    <Alert style={{ marginTop: '1rem', ...style }} message={message} type='error' showIcon />
  ) : null
}