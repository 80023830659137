import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

const initialLang = Cookies.get('traztru-lng')

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: initialLang ? initialLang.toLowerCase() : 'th',
    fallbackLng: initialLang ? initialLang.toLowerCase() : 'th',
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true
    }
  });
export default i18n;