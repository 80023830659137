import React from "react";
import propTypes from "prop-types";
import { Button, Card, Col, Modal, Row, Input, Image, Select, Spin, DatePicker, message, theme } from "antd";
import { MapContainer, TileLayer, FeatureGroup, Marker, LayersControl, LayerGroup } from "react-leaflet";
import Header from "../common/Header";
import Form from "antd/es/form/Form";
import { POST, GET, PATCH } from "../../frameworks/HttpClient";
import { URL_COMMON, URL_MEMBER } from "../../constants/urls";
import ImageEditorModal from "../common/ImageEditorModal";
import { EditControl } from 'react-leaflet-draw';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined } from '@ant-design/icons';
import userImg from '../../assets/user.png';
import { DATE_FORMAT, DEFAULT_LOCATION } from "../../constants/string";
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import SearchField from '../common/SearchField';
import _ from "lodash";
import dayjs from "dayjs";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';

delete L.Icon.Default.prototype._getIconUrl;

export default function CCRegisterModal(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [imageData, setImageData] = React.useState(null);
  const [uploadFile, setUploadFile] = React.useState(null)
  const [fetching, setFetching] = React.useState(false);
  const [subdistricts, setSubdistricts] = React.useState([]);
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [center, setCenter] = React.useState(DEFAULT_LOCATION);
  const [deleteMode, setDeleteMode] =  React.useState(false);
  const [marker, setMarker] = React.useState(null);
  const [positions, setPositions] = React.useState(null);
  const [loadTileMap, setLoadTileMap] = React.useState(false);
  const [mapKey, setMapKey] = React.useState(Date.now());
  const [searchPosition, setSearchPosition] = React.useState(null)
  const [inputLat, setInputLat] = React.useState('');
  const [inputLng, setInputLng] = React.useState('');
  const mapRef = useRef();
  const uploadRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();


  const genderTH = [
    { value: 'นาย', label: t("prefix.mr") },
    { value: 'นาง', label: t("prefix.mrs") },
    { value: 'นางสาว', label: t("prefix.ms") },
    { value: 'เด็กชาย', label: t("prefix.boy") },
    { value: 'เด็กหญิง', label: t("prefix.girl") },
  ]

  const genderEN = [
    { value: 'นาย', label: t("prefix.mr") },
    { value: 'นาง', label: t("prefix.mrs") },
    { value: 'นางสาว', label: t("prefix.ms") },
  ]

  const iconMarker = new L.Icon({
    iconUrl: require('../../assets/marker-1.png'),
    iconRetinaUrl: require('../../assets/marker-1.png'),
    iconAnchor: [13, 40],
    iconSize: [25, 40],
  });

  const iconSearchMarker = new L.Icon({
    iconUrl: require('../../assets/search-marker.png'),
    iconRetinaUrl: require('../../assets/search-marker.png'),
    iconAnchor: [13, 40],
    iconSize: [25, 40],
  });


  const debounceSubdistrict = React.useCallback(_.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800), []);

  const handleLatLngChange = (e) => {
    const { name, value } = e.target;
    if (name === 'lat') {
      setInputLat(value);
    } else if (name === 'lng') {
      setInputLng(value);
    }
  };

  const handleFlyTo = () => {
    const lat = parseFloat(inputLat);
    const lng = parseFloat(inputLng);

    if (!isNaN(lat) && !isNaN(lng)) {
      setSearchPosition([lat,lng])
      mapRef.current.flyTo([lat, lng], 20);
    } else {
      message.error('กรุณากรอก Latitude, Longitude');
    }
  };
    
  const fetchSubdistrict = async () => {
    setFetching(true);
    try {
      const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}`)
      setSubdistricts(response.data.results.map((item) => {
        return {
          ...item,
          label: item.display_name,
          value: item.subdistrict_id
        }
      }))
    } catch (error) {
      console.log(error.errorMessages)
    } finally {
      setFetching(false);
    }
  }

  const prepareMarker = (e) => {
    let data = { latitude : e.lat, longitude : e.lng }
    setMarker(data)
    setPositions(e)
  }

  const handleDeleteButton = () => {
    if(deleteMode === false){
      setDeleteMode(true)
    }else {
      setDeleteMode(false)
    }
  }

  const handleOK = async() => {
    setErrorMessages(null);
    setLoading(true);
    try {
      let data = await form.validateFields();
      if (data.sub_district) 
        data['sub_district'] = data.sub_district.value
      if (data["birthdate"])
        data["birthdate"] = data.birthdate.format(DATE_FORMAT);
      if (data["license_expire_date"])
        data["license_expire_date"] = data.license_expire_date.format(DATE_FORMAT);
      if(imageData)
        data['card'] = imageData
      data['location'] = marker
      if (data['password'] === data['confirm_password']){
        if  (props.target) {
          if (data["username"])
            delete data["username"]

          await PATCH(`${URL_MEMBER.COLLECTING_CENTER}${props.target.id}/`, data);
          message.success("แก้ไขข้อมูลสำเร็จ");
        } else {
          await POST(URL_MEMBER.COLLECTING_CENTER, data);
          message.success("สร้างจุดรับซื้อสำเร็จ");
        }
        props.onUpdated();
        setMarker(null)
        setPositions(null)
      } else {
        message.error("รหัสผ่านไม่ตรงกัน");
      }

    } catch (error) {
      if(error.errorMessages)
        message.error(error.errorMessages);
    } finally {
        setLoading(false);
    }
  }

  React.useEffect(() => {
    if (debouncedCode)
      fetchSubdistrict();
  }, [debouncedCode])

  React.useEffect(() => {
    if (props.open) {
      const data = props.target
      if (data) {
        form.setFieldsValue({
          ...data,
          license_expire_date: data.license_expire_date ? dayjs(data.license_expire_date) : null,
          birthdate: data.birthdate ? dayjs(data.birthdate) : null,
          sub_district: data.sub_district ? {value : data.sub_district, label : data.sub_district_detail} : null
        });
        setMarker(data.location)
        setPositions([data.location.latitude, data.location.longitude])
        setCenter([data.location.latitude, data.location.longitude])
      } else {
        form.resetFields();
        setPositions(null)
        setMarker(null)
        setCenter([8.914421765502166, 99.15133086426016])
      }
    }
    setInputLat(null);
    setInputLng(null);
    setSearchPosition(null);
  }, [props.open])

  React.useEffect(() => {
    if (props.open) {
      setMapKey(Date.now());
      setLoadTileMap(true);
    }
  }, [center])

  return (
    <Modal
      width={'80%'}
      open={props.open}
      cancelText={t("cancel")}
      onCancel={props.onClose}
      confirmLoading={loading}
      okText={props.target ? t("edit") : t("add")}
			okButtonProps={{ style: {background: props.target ? colorWarning : colorPrimary}}}
      onOk={handleOK}>
      <Header title={props.target ? t("collecting_center") : t("collecting_centers.register")}></Header>

      <Form
        style={{marginTop: 24}}
        form={form}
        layout="vertical"
        autoComplete="off">
        <Row gutter={16}>
          <Col md={8} sm={24} xs={24}>
            <Row gutter={16}>
              <Col md={24} sm={24} xs={24} style={{marginBottom: 16}}>
                <div style={{ textAlign: "center" }} className='section-wrapper'>
                  <Image
                    src={imageData ? imageData : (props.target && props.target.card) ? props.target.card : userImg}
                    height={180} 
                    width={"auto"} />
                  <div>
                    <Button 
                      style={{marginTop: 8}}
                      icon={<UploadOutlined />} 
                      onClick={() => uploadRef.current.click()}>
                        {t("upload_image")}
                    </Button>
                  </div>
                </div>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <Form.Item  
                  name={"name"}
                  label={t("collecting_centers.name")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <Form.Item  
                  name={"name_en"}
                  label={t("collecting_centers.name_en")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <Form.Item  
                  name={"prefix"}
                  label={t("collecting_centers.acronym")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col md={16} sm={24} xs={24}>
            <Row gutter={16}>
              <Col md={6} sm={24} xs={24}>
                <Form.Item  
                  name={"title"}
                  label={t("collecting_centers.title")}
                  rules={[{ required: true }]}>
                    <Select
                      style={{ width: '100%' }}
                      options={i18n.language === "th" ? genderTH : genderEN}
                    />
                </Form.Item>
              </Col>

              <Col md={9} sm={24} xs={24}>
                <Form.Item  
                  name={"first_name"}
                  label={t("collecting_centers.first_name")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={9} sm={24} xs={24}>
                <Form.Item  
                  name={"last_name"}
                  label={t("collecting_centers.last_name")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"citizen_id"}
                  label={t("citizen_id")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"birthdate"}
                  label={t("birthday")}
                  rules={[{ required: true }]}>
                    <DatePicker style={{ width: '100%' }} format={DATE_FORMAT}/>
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"gender"}
                  label={t("genders")}>
                    <Select style={{ width: '100%' }}
                      options={[
                        { value: 'm', label: t('male') },
                        { value: 'f', label: t('female') }
                      ]}
                    />
                </Form.Item>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <Form.Item  
                  name={"address"}
                  label={t("address")}
                  rules={[{ required: true }]}>
                    <Input.TextArea />
                </Form.Item>
              </Col>

              <Col md={24} sm={24} xs={24}>
                <Form.Item  
                  name={"sub_district"}
                  label={t("subdistrict_district_province_zip_code")}
                  rules={[{ required: true }]}>
                <Select 
                  initialvalues={"-"}
                  autoComplete="off" 
                  showSearch
                  labelInValue
                  filterOption={false}
                  notFoundContent={fetching ? <Spin size="small" /> : "-"}
                  onSearch={debounceSubdistrict}
                  options={subdistricts}
                  allowClear={"-"}/>
                </Form.Item>
              </Col>

              <Col md={16} sm={24} xs={24}>
                <Form.Item  
                  name={"email"}
                  label={t("email")}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"phone"}
                  label={t("phone_number")}
                  rules={[{ required: true }]}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"certificate_code"}
                  label="Certificate FSC Code">
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>

              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"license_number"}
                  label={t("collecting_centers.license_number")}>
                    <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Form.Item  
                  name={"license_expire_date"}
                  label={t("collecting_centers.license_expire_date")}>
                    <DatePicker style={{ width: '100%' }} format={DATE_FORMAT}/>
                </Form.Item>
              </Col>
            </Row>
          </Col>

        <Col md={24} sm={24} xs={24}>
          <Card style={{marginBottom : 16}} >
          <Row style={{ marginBottom : 16 }}>
          <Col md={8} sm={24} xs={24} style={{marginRight : 5}} >
            <Input
              type="text"
              placeholder="latitude"
              name="lat"
              value={inputLat}
              onChange={handleLatLngChange}
              addonBefore="lat"
            />
          </Col>
          <Col md={8} sm={24} xs={24} style={{marginRight : 5}} >
            <Input
              type="text"
              placeholder="longitude"
              name="lng"
              value={inputLng}
              onChange={handleLatLngChange}
              addonBefore="lng"
            />
          </Col>
          <Col md={2} sm={24} xs={24}>
            <Button onClick={handleFlyTo} style={{marginRight : 5}}>{t("search")}</Button>
          </Col>
          <Button danger type={deleteMode ? "dashed" :  "primary" } onClick={() => handleDeleteButton()}>{deleteMode ? "Cancle" : "Delete"}</Button>
          </Row>

          {loadTileMap === true && (
            <div style={{height: 400}}>
              <MapContainer zoom={9} maxZoom={20} center={center} ref={mapRef} key={mapKey} attributionControl={false}>
                <LayersControl>
                  <LayersControl.BaseLayer checked name="Google Map">
                    <TileLayer
                      maxZoom={20}
                      attribution="Google Maps"
                      url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                    />
                  </LayersControl.BaseLayer>

                  <LayersControl.BaseLayer name="Google Map Satellite">
                    <LayerGroup>
                      <TileLayer
                        maxZoom={20}
                        attribution="Google Maps Satellite"
                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                      />
                      <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"/>
                    </LayerGroup>
                  </LayersControl.BaseLayer>
                </LayersControl>
          
                {/* {positions && <Marker position={positions} />} */}
                <FeatureGroup>
                    <EditControl
                      onCreated = {(e) => {
                        prepareMarker(e.layer._latlng)
                        mapRef.current.removeLayer(e.layer)
                      }}
                      draw={{
                        polyline: false,
                        rectangle: false,
                        circle: false,
                        circlemarker:false,
                        polygon: false,
                        marker : {icon : iconMarker}
                      }}
                      edit = {{ edit: false , remove : false}} />
                  </FeatureGroup>

                  {positions && 
                    <Marker 
                      position={ positions }
                      icon={ iconMarker }
                      eventHandlers={{
                        click: (e) => {
                          if(deleteMode === true ){
                            mapRef.current.removeLayer(e.target);
                            setPositions(null);
                            setMarker(null);
                            setDeleteMode(false);
                          }
                        }
                      }}
                  />}

                  {searchPosition && 
                    <Marker 
                      position={searchPosition} 
                      icon={ iconSearchMarker }  
                      eventHandlers={{
                      click: (e) => {
                        if(deleteMode === true ){
                          mapRef.current.removeLayer(e.target);
                          setDeleteMode(false);
                          setSearchPosition(null);
                        }
                      }
                    }}
                    />
                  }
              </MapContainer>
            </div>
          )}
              </Card>
            </Col>
          
          <Col md={8} sm={24} xs={24}>
            <Form.Item  
              name={"username"}
              label="Username"
              rules={[{ required: true }]}>
                <Input autoComplete="off" disabled={props.target ? true : false}/>
            </Form.Item>
          </Col>

          {!props.target && (
            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"password"}
                label={t("password")}
                rules={[{ required: true, message: 'Please input your password!' }]}>
                  <Input.Password />
              </Form.Item>
            </Col>
          )}

          {!props.target && (
            <Col md={8} sm={24} xs={24}>
              <Form.Item  
                name={"confirm_password"}
                label={t("confirm_password")}
                dependencies={['password']}
						    hasFeedback
                rules={[
                  { required: true, message: 'Please confirm your password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}>
                  <Input.Password />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>

      <ImgCrop aspect={16 / 9} aspectSlider>
        <input id="imgSelect"
          type="file"
          accept="image/*"
          ref={uploadRef}
          style={{ display: 'none' }}
          onChange={async (evt) => {
            if (evt.target.files.length > 0) {
              setUploadFile(URL.createObjectURL(evt.target.files[0]))
              evt.target.value = null  // clear reference to enable select same file}
          }
        }}/>
      </ImgCrop>

      <ImageEditorModal
        open={uploadFile ? true : false}
        image={uploadFile}
        onUpdated={(image) => {
          setImageData(image)
          setUploadFile(null)
        }}
        onClose={() => setUploadFile(null)}/>
    </Modal>
  )
}

CCRegisterModal.defaultProps = {
	open: false,
	target: null,
  onClose: () => {},
  onUpdated: () => {}
}

CCRegisterModal.propTypes = {
	open: propTypes.bool, 
	target: propTypes.object,
	onClose: propTypes.func,
  onUpdated: propTypes.func,
}