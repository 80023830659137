import React from 'react';
import { Button, Drawer, Image, Layout, Menu, Popover, Space, theme, Avatar, Card , Spin } from 'antd';
import { NavBar, Toast, SafeArea, TabBar } from 'antd-mobile';
import { useUserContext } from '../../hooks/UserSettingProvider';
import * as _ from 'lodash';
import ChangePasswordModal from '../user/ChangePasswordModal';
import LogoutModal from '../user/LogoutModal';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  UserOutlined, 
  UnlockOutlined,
} from '@ant-design/icons';
import { BG_LAYOUT_COLOR } from '../../constants/color';
import { Footer } from 'antd/es/layout/layout';
import LogoutCashierModal from '../collectingcenter/LogoutCashierModal';
import { URL_CUSTOMER } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';
import Cookies from 'js-cookie';
import { USER_TYPE } from '../../constants/string';
import { useTranslation } from 'react-i18next';
import LanguageMenu from './LanguageMenu';

const { Header, Sider, Content } = Layout;

export default function MainMenu(props) {
  const {
    menus,
    currentMenu,
    onMenuSelected,
    children
  } = props;

  const { user } = useUserContext(); 
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  // Menu render
  const [collapsed, setCollapsed] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  // User menu
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
	const [openLogout, setOpenLogout] = React.useState(false);
	const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [openLogoutCashier, setOpenLogoutCashier] = React.useState(false);
  const [cashier, setCasheir] = React.useState([]);
  const [saveIdCashier, setSaveIdCashier] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  const userType = user.user_type

  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();
  const { Meta } = Card;

  const fetchData = async() => {
    const response = await GET(`${URL_CUSTOMER.CASHIER}${saveIdCashier}/`)
    setCasheir(response.data)
  }

  const getMenus = (isMobile) => {
    const list = isMobile 
      ? menus.filter(menu => !menu.disableMobile && (menu.permission != "false"))
      : menus.filter(menu => !menu.disableDesktop && (menu.permission != "false"))
  
    return isMobile 
    ? <TabBar
        onChange={(e) => onMenuSelected({key : e})}
        activeKey={currentMenu}
        defaultActiveKey={currentMenu}>
          {list.map(item => (
            <TabBar.Item 
              key={item.key}
              icon={item.icon}
              title={item.labelMobile ? item.labelMobile : item.label}
              SafeArea={true}/>
          ))}
      </TabBar>
    : (<Menu
        mode='inline'
        onClick={(e) => {
          onMenuSelected(e);
          setDrawerOpen(false);
        }} 
        defaultSelectedKeys={[currentMenu]}
        selectedKeys={[currentMenu]}
        items={list}>
      </Menu>)
  }

  React.useEffect(() => {
    if(saveIdCashier){
      fetchData();
    }
  },[saveIdCashier])

  React.useEffect(()=>{
    setSaveIdCashier(Cookies.get('id'))
  },[])
 
  React.useEffect(() => {
    if(props.cashierId){
      setSaveIdCashier(props.cashierId)
    }
  },[props.cashierId])

  return (
    <div>
      <Spin spinning={isLoading}>
      <Layout style={{ minHeight: '100vh' }}>
        {!isOnMobile && !(userType === USER_TYPE.FARMER) && 
          <Sider  
            className='shadow'
            style={{ background: "#FFF"}}
            trigger={null} collapsible collapsed={collapsed}>
            <div style={{textAlign: "center", marginTop: 24, marginBottom: 16}}>
              <Image style={{ height: "56px", width: "auto" }} src={"/logo_txt_white_bg.png"} preview={false}/>
            </div>

            {getMenus(false)}
            {saveIdCashier && 
              <Card
                actions={[
                  <Button
                    style={{width: "auto"}}
                    onClick = {() =>{ setOpenLogoutCashier(true)}}
                    danger>
                    {t("end_shift")}
                  </Button> 
                ]}>
                <Meta
                  title={cashier.full_name}
                  avatar={<Avatar style={{ backgroundColor: '#87d068' }}size={"smail"} icon={<UserOutlined />} />}
                  description="online"
                />
              </Card>
            }
          </Sider>}

          <Drawer
            placement='left'
            title=''
            headerStyle={{ height: 0 }}
            bodyStyle={{ padding: 0 }}
            width={250}
            closeIcon={false}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}>
            <div style={{textAlign: "center", marginTop: 24, marginBottom: 16}}>
            <Image style={{ height: "56px", width: "auto" }} src={"/logo_txt_white_bg.png"} preview={false}/>
              
            </div>

            {getMenus(false)}
            {saveIdCashier && 
            <Card
              actions={[
                <Button
                  type="primary"
                  style={{width: "auto"}}
                  onClick = {() =>{ setOpenLogoutCashier(true)}}
                  danger>
                  {t("end_shift")}
                </Button> 
              ]}>
              <Meta
                title={cashier.full_name}
                avatar={<Avatar style={{ backgroundColor: '#87d068' }}size={"smail"} icon={<UserOutlined />} />}
                description="online"
              />
            </Card>
          
            }
          </Drawer>
       
        <Layout style={{background: BG_LAYOUT_COLOR }}>
          <Header 
            style={{ padding: 0, background: BG_LAYOUT_COLOR, color: colorPrimary, fontWeight: "bold",
              marginLeft: ((isOnMobile && userType === USER_TYPE.COLLECTING_CENTER) || (userType === USER_TYPE.FARMER)) ? 24 : 0}}>
            {!((isOnMobile && userType === USER_TYPE.COLLECTING_CENTER) || (userType === USER_TYPE.FARMER)) &&
              <Button
                style={{ color: colorPrimary, fontSize: '16px', width: 64, height: 64 }}
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => {
                  if (isOnMobile) 
                    setDrawerOpen(true)
                  else
                    setCollapsed(!collapsed)
                }}/>
            }

              {_.get(menus.find(menu => menu.key == currentMenu), 'label', '-')}
 
              <div style={{display: "inline-block", float: "right", marginRight: 16}}>
                <LanguageMenu setIsLoading={(e) => setIsLoading(e)}/>
                <Popover
                  trigger='click'
                  title={user.display_name ? user.display_name : user.username}
                  open={openUserMenu}
                  onOpenChange={(newOpen) => setOpenUserMenu(newOpen)}
                  content={
                    <Space direction='vertical' >
                      {!props.isLiff && 
                        <Button
                          type='text'
                          style={{ textAlign: 'left' }}
                          block
                          icon={<UnlockOutlined />}
                          onClick={() => {
                            setOpenChangePassword(true);
                            setOpenUserMenu(false);
                          }}>
                          {t("change_password")}
                        </Button>
                      }
                      <Button 
                        type='text' 
                        style={{ textAlign: 'left' }} 
                        block 
                        icon={<LogoutOutlined/>} 
                        onClick={() => {
                          setOpenLogout(true);
                          setOpenUserMenu(false);
                        }}>
                        {t("logout")}
                      </Button>
                    </Space>
                  }>

                  <Button 
                    shape='round' 
                    size='large' 
                    style={{ boxShadow: '0 0 0', color: colorPrimary}} 
                    onClick={() => setOpenUserMenu(true)}>
                    {isOnMobile ? _.get(user, 'username[0]', <UserOutlined/>) : _.get(user, 'username', <UserOutlined/>)}
                  </Button>
                </Popover>
              </div>
            </Header>

          <Content
            style={{
              margin: '0px 16px 16px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer
            }}>
              
            {children}
          </Content>
          
          {((isOnMobile && userType === USER_TYPE.COLLECTING_CENTER) || (userType === USER_TYPE.FARMER))&&
            <Footer 
              style={{ bordertop: '1px solid #e8e8e8', position: 'fixed', left: 0, bottom: 0,
                width: '100%', backgroundcolor: 'white', padding : 10}}>
                 {getMenus(true)}
            </Footer>
          }
        </Layout>
      </Layout>
           
      <LogoutModal 
        open={openLogout}
        isLiff={props.isLiff ? true : false}
        onClose={() => setOpenLogout(false)}
        onUpdate={() => setOpenLogout(false)}/>

      <ChangePasswordModal 
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}/>

      <LogoutCashierModal
        open={openLogoutCashier}
        onClose={()=>setOpenLogoutCashier(false)}
        onCashierId={()=>{
          props.onCashierId()
          setSaveIdCashier(null)
        }}/>
        </Spin>
    </div>
  )
}
