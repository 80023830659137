import { Button, Col, Input, Row, Select, Space } from 'antd';
import React from 'react';
import { CUSTOMER_TYPE } from '../../constants/string';
import { TYPE_COLLECTINGCENTER, TYPE_CUSTOMER, TYPE_FARMER } from '../../constants/color';
import { UserAddOutlined, SearchOutlined } from '@ant-design/icons';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import * as _ from 'lodash';
import ColorButton from '../common/ColorButton';
import CustomerModal from './CustomerModal';
import SearchFarmerModal from './SearchFarmerModal';
import SearchCCModal from './SearchCCModal';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';



const CustomerFilterHeader = React.forwardRef((props, ref) => {
  const {
    refresh,
    style,
    loading,
  } = props;

  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');

  const [search, setSearch] = React.useState('');
  const [type, setType] = React.useState(null);

  const [openSearchFarmerModal, setOpenSearchFarmerModal] = React.useState(false);
  const [openSearchCCModal, setOpenSearchCCModal] = React.useState(false);
  const [openCustomerModal, setOpenCustomerModal] = React.useState(false);
  const [target, setTarget] = React.useState(null);
  const { t, i18n } = useTranslation();


  React.useImperativeHandle(ref, () => ({
    search, type, onEdit
  }));

  const onEdit = (data) => {
    setTarget(data);
  }

  const handleSearch = () => {
    if (refresh) {
      refresh({ search, type });
    }
  }

  const closeModal = (modalState) => {
    if (target)
      setTarget(null);

    modalState(false);
  }

  const handleUpdate = (modalState) => {
    closeModal(modalState)
    refresh({ search, type }, true);
  }


const CUSTOMER_TYPE_TRANSLATION = {
  [CUSTOMER_TYPE.GENERAL]: t("general_customer"),
  [CUSTOMER_TYPE.FARMER]: t("farmer"),
  [CUSTOMER_TYPE.COLLECTING_CENTER]: t("collecting_center"),
  [CUSTOMER_TYPE.FACTORY]: t("factory")
}

const CUSTOMER_TYPE_TRANSLATION_MOBILE = {
  [CUSTOMER_TYPE.GENERAL]: t("general"),
  [CUSTOMER_TYPE.FARMER]: t("farmer"),
  [CUSTOMER_TYPE.COLLECTING_CENTER]: t("cc_title"),
  [CUSTOMER_TYPE.FACTORY]: t("factory")
}

const CUSTOMER_TYPE_OPTIONS = [
  { key: 'all', value: null, label: t("all") },
  { key: CUSTOMER_TYPE.GENERAL, value: CUSTOMER_TYPE.GENERAL, label: t("general_customer")  },
  { key: CUSTOMER_TYPE.FARMER, value: CUSTOMER_TYPE.FARMER, label: t("farmer") },
  { key: CUSTOMER_TYPE.COLLECTING_CENTER, value: CUSTOMER_TYPE.COLLECTING_CENTER, label: t("collecting_center") },
  { key: CUSTOMER_TYPE.FACTORY, value: CUSTOMER_TYPE.FACTORY, label: t("factory") },
]



  

  React.useEffect(() => {
    handleSearch();
  }, [type]);

  return (
    <div style={style}>
      <Row gutter={[16, 16]} wrap>
        <Col xl={props.readOnly ? 24 : 8} lg={24} md={24}>
          <Space.Compact style={{ width: '100%' }} size='middle'>
            <Select
              style={{ width: 250 }}
              placeholder={t("all")}
              defaultValue={null}
              options={CUSTOMER_TYPE_OPTIONS}
              onChange={value => setType(value)}/>
            <Input placeholder= {t("search_for_customers")} onPressEnter={handleSearch} onChange={e => setSearch(_.get(e, 'target.value', ''))}/>
            <Button loading={loading} icon={ <SearchOutlined/>} onClick={handleSearch}> {t("search")} </Button>
          </Space.Compact>
        </Col>

        {!isOnMobile && !props.readOnly &&
        <Col xl={16} lg={24} md={24} >
          <Row gutter={[8]}>
            <Col md={8} sm={24} xs={8}>
              <ColorButton block icon={<UserAddOutlined/>} type='primary' override={TYPE_CUSTOMER} onClick={() => setOpenCustomerModal(true)}>
                {CUSTOMER_TYPE_TRANSLATION[CUSTOMER_TYPE.GENERAL]}
              </ColorButton>
            </Col>
            <Col md={8} sm={24} xs={8}>
              <ColorButton block icon={<UserAddOutlined/>} type='primary' override={TYPE_COLLECTINGCENTER} onClick={() => setOpenSearchCCModal(true)}>
                {CUSTOMER_TYPE_TRANSLATION[CUSTOMER_TYPE.COLLECTING_CENTER]}
              </ColorButton>
            </Col>
            <Col md={8} sm={24} xs={8}>
              <ColorButton block icon={<UserAddOutlined/>} type='primary' override={TYPE_FARMER} onClick={() => setOpenSearchFarmerModal(true)}>
                {CUSTOMER_TYPE_TRANSLATION[CUSTOMER_TYPE.FARMER]}
              </ColorButton>
            </Col>
          </Row>
        </Col>}
      </Row>

      {isOnMobile && !props.readOnly &&
        <Col xl={24} lg={24} md={24} style={{ marginTop : 16}}>
          <Row gutter={[8]}>
            <Col md={8} sm={8} xs={8}>
              <ColorButton block style={{ height : 45}} type='primary' override={TYPE_CUSTOMER} onClick={() => setOpenCustomerModal(true)}>
                {CUSTOMER_TYPE_TRANSLATION_MOBILE[CUSTOMER_TYPE.GENERAL]}
              </ColorButton>
            </Col>
            <Col md={8} sm={8} xs={8}>
              <ColorButton block style={{ height : 45}} type='primary' override={TYPE_COLLECTINGCENTER} onClick={() => setOpenSearchCCModal(true)}>
                {CUSTOMER_TYPE_TRANSLATION_MOBILE[CUSTOMER_TYPE.COLLECTING_CENTER]}
              </ColorButton>
            </Col>
            <Col md={8} sm={8} xs={8}>
              <ColorButton block style={{ height : 45}} type='primary' override={TYPE_FARMER} onClick={() => setOpenSearchFarmerModal(true)}>
                {CUSTOMER_TYPE_TRANSLATION_MOBILE[CUSTOMER_TYPE.FARMER]}
              </ColorButton>
            </Col>
          </Row>
        </Col>}

      <SearchFarmerModal
        open={openSearchFarmerModal}
        onUpdate={() => handleUpdate(setOpenSearchFarmerModal)}
        onClose={() => closeModal(setOpenSearchFarmerModal)}/>

      <SearchCCModal
        open={openSearchCCModal}
        onUpdate={() => handleUpdate(setOpenSearchCCModal)}
        onClose={() => closeModal(setOpenSearchCCModal)}/>

      <CustomerModal
        open={(openCustomerModal || target) ? true : false}
        target={target}
        onUpdate={() => handleUpdate(setOpenCustomerModal)}
        onClose={() => closeModal(setOpenCustomerModal)}/>
    </div>
  )
})

export default CustomerFilterHeader;


CustomerFilterHeader.defaultProps = {
  refresh: () => null,
  style: {},
  loading: false,
  readOnly: false,
}

CustomerFilterHeader.prototype = {
  refresh: propTypes.func,
  style: propTypes.object,
  loading: propTypes.bool,
  readOnly: propTypes.bool,
}