import React from "react";
import { theme, Card, Form, Row, Col, Radio, Input, DatePicker, Alert, Select, Button } from "antd";
import { DATE_FORMAT, FARMER_PLANTATION } from "../../constants/string";
import { URL_PLANTATIONS } from "../../constants/urls";
import { DELETE, GET } from "../../frameworks/HttpClient";
import dayjs from "dayjs";
import UploadFile from "../common/UploadFile";
import { useTranslation } from 'react-i18next';



const PlantationInfoView = React.forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const [useWorker, setUseWorker] = React.useState();
  const [formatTaxPaidDate, setFormatTaxPaidDate] = React.useState(null);
  
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [deedOptions, setDeedOptions] = React.useState([]);
  const [trigger, setTrigger] = React.useState(false);
  const deedRef = React.useRef(null);
  const rentalRef = React.useRef(null);
  const invoiceRef = React.useRef(null);
  const proxyRef = React.useRef(null);
  const plantYear = Form.useWatch('plant_year', form)
  const CURRENT_BUDDHIST_YEAR = new Date().getFullYear() + 543;
  const MAX_YEAR_ALLOW = 50
  const { t, i18n } = useTranslation();


  React.useImperativeHandle(ref, () => ({
    getData: () => {
      return handleRegister();
    },
  }));

  // Handle radio group
  const onRadioChange = (e) => {
    form.setFieldValue(e.target.name, e.target.value);
    setTrigger(!trigger);
  }

  const onChangeHaveWorker = (e) => {
    setUseWorker(e.target.value);
  };

  const onDateChange = (value, dateString) => {
    setFormatTaxPaidDate(dateString);
  };

  const fetchDeedOptions = async () => {
    try {
      const response = await GET(URL_PLANTATIONS.DEED_TYPE);
      setDeedOptions(response.data.results.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id
        }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  const handleRegister = async () => {
    setErrorMessages(null)
    let deedFile = null
    let rentalFile = null
    let invoiceFile = null

    if (deedRef.current)
      deedFile = await deedRef.current.getData()
    
    if (rentalRef.current)
      rentalFile = await rentalRef.current.getData()

    if (invoiceRef.current)
      invoiceFile = await invoiceRef.current.getData()

    if (proxyRef.current)
      invoiceFile = await proxyRef.current.getData()

    try {
      let data = await form.validateFields();
      if (formatTaxPaidDate)
        data["tax_paid_date"] = formatTaxPaidDate;
      data["deed_document"] = deedFile ? deedFile : [];
      data["rental_document"] = rentalFile ? rentalFile : [];
      data["invoice_document"] = invoiceFile ? invoiceFile : [];
      data["proxy_document"] = invoiceFile ? invoiceFile : [];
      return data;
    } catch (error) {
      setErrorMessages(error.errorMessages);
      return {}
    } finally { 
      form.resetFields();
    }

  };

  const {
    token: { colorPrimary, colorWarning, colorError },
  } = theme.useToken();

  React.useEffect(() => {
    if (props.data) {
      let data = props.data;
      form.setFieldsValue(data)
      if (data.tax_paid_date)
        form.setFieldValue("tax_paid_date", dayjs(data.tax_paid_date, DATE_FORMAT));
      setFormatTaxPaidDate(data.tax_paid_date);
    }
  }, [props.data]);

  React.useEffect(() => {
    // Fetch Options when open app
    fetchDeedOptions();
  }, [])

  return (
    <Card>
      {errorMessages && 
        <Alert
          style={{marginBottom: 8}}
          message={errorMessages}
          type="error"
          showIcon
        />
      }

      <Form form={form} layout="vertical" autoComplete="off">
        <Row gutter={16}>
          <Col md={24} sm={24} xs={24}>
            <Form.Item name={"type"} label={t("type")}>
              <Radio.Group name={"type"} defaultValue={useWorker} onChange={onRadioChange}>
                <Radio value={FARMER_PLANTATION.OWNER}>{t("garden_owner")}</Radio>
                <Radio value={FARMER_PLANTATION.RENTER}>{t("garden_tenant")}</Radio>
                <Radio value={FARMER_PLANTATION.PROXY}>{t("rubber_plantation_workers")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          
            
              {form.getFieldValue("type") === FARMER_PLANTATION.OWNER && (
                <Col md={24} sm={24} xs={24}>
                  <Form.Item name={"use_worker"}>
                    <Radio.Group name={"use_worker"} onChange={onChangeHaveWorker}>
                      <Radio value={false}>
                        {t("own_sell_the_rubber")}
                      </Radio>
                      <Radio value={true}>
                        {t("own_tap_the_rubber")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}

              {form.getFieldValue("type") === FARMER_PLANTATION.RENTER && (
                <Col md={24} sm={24} xs={24}>
                  <Form.Item name={"use_worker"}>
                    <Radio.Group name={"use_worker"} onChange={onChangeHaveWorker}>
                      <Radio value={false}>
                        {t("garden_tenant_1")}
                      </Radio>
                      <Radio value={true}>
                        {t("garden_tenant_2")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}

              {form.getFieldValue("type") === FARMER_PLANTATION.PROXY && (
                <Col md={24} sm={24} xs={24}>
                  <Form.Item name={"use_worker"}>
                    <Radio.Group name={"use_worker"} onChange={onChangeHaveWorker}>
                      <Radio value={false}>
                        {t("ubber_plantation_workers_1")}
                      </Radio>
                      <Radio value={true}>
                        {t("ubber_plantation_workers_2")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}

              {form.getFieldValue('use_worker') === true && (
                  <div>
                      <Col md={24} sm={24} xs={24}>
                        <Form.Item
                          name={"number_of_workers"}
                          label={t("number_of_rubber_tapping")}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </Col>
                  </div>
              )}
        </Row>

        {form.getFieldValue("type") === FARMER_PLANTATION.PROXY && (
          <Row gutter={16}>
            <Col md={2} sm={24} xs={24}>
              <p style={{ marginTop: 30 }}>{t("principal")}</p>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item name={"proxy_full_name"} label={t("profiles.full_name")}>
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {form.getFieldValue("type") === FARMER_PLANTATION.PROXY && (
          <Row gutter={16}>
            <Col md={2} sm={24} xs={24}>
              <p style={{ marginTop: 30 }}>{t("agent")}</p>
            </Col>

            <Col md={8} sm={24} xs={24}>
              <Form.Item name={"appoint_full_name"} label={t("profiles.full_name")}>
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {form.getFieldValue("type") === FARMER_PLANTATION.PROXY && (
          <Row gutter={16}>
            <Col md={8} sm={24} xs={24}>
              <Form.Item
                name={"proxy_document"}
                label={t("attach_upload_of_power_of_attorney_from_the_garden_owner")}
              >
                <UploadFile
                  className={"upload-list-inline"}
                  style={{ background : colorPrimary , color : 'white'}}
                  btnText = {t("upload_power_of_attorney")} 
                  maxCount = {3}
                  ref = {proxyRef}
                  dataList={props.proxy_document ? props.proxy_document : null}
                  onRemove={(data) => DELETE(`${URL_PLANTATIONS.DOCUMENT}${data.id}/`)}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
        {(form.getFieldValue("type") === FARMER_PLANTATION.RENTER ||
            form.getFieldValue("type") === FARMER_PLANTATION.PROXY) && (
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                name={"rental_documents"}
                label={t("attach_copy_showing_proof_of_rights")}
              >
              <UploadFile
                className={"upload-list-inline"}
                style={{ background : colorPrimary , color : 'white'}}
                btnText = {t("upload_lease_agreement")} 
                maxCount = {3}
                ref = {rentalRef}
                dataList={props.rental_document ? props.rental_document : null}
                onRemove={(data) => DELETE(`${URL_PLANTATIONS.DOCUMENT}${data.id}/`)}
              />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={16}>
        <Col xl={8} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={16}>
            <Col lg={13} md={24} sm={24} xs={24}>
              <Form.Item name={"title_deed_type"} label={t("type_of_rights_document")}>
                <Select options={deedOptions}/>
              </Form.Item>
            </Col>

            <Col lg={11} md={24} sm={24} xs={24}>
              <Form.Item name={"title_deed_no"} label={t("document_number")}>
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xl={16} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={16}>
            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item name={"area_rai"} label={t("area_as_per_rights_document")}>
                <Input 
                autoComplete="off" 
                addonAfter={t("rai")}
                onChange={(e) => props.setShowLabelSpace(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item name={"area_ngan"} >
                <Input
                  style={{ marginTop: 27 }}
                  autoComplete="off"
                  addonAfter={t("ngan")}
                />
              </Form.Item>
            </Col>


            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item name={"area_wa"}>
                <Input
                  style={{ marginTop: 27 }}
                  autoComplete="off"
                  addonAfter={t("square_wah")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
                {/* <Col md={8} sm={24} xs={24}>
      <Form.Item
        label={"พื้นที่ตามเอกสารสิทธิ์"}>
      <Input label="asd" autoComplete="off" value={props.space} addonBefore="พื้นที่ทั้งหมด" addonAfter="ไร่" readOnly />
      </Form.Item>
      </Col> */}

      <Col md={8} sm={24} xs={24}>
      <Form.Item
        name={"rubber_area_rai"}
        rules={[{ required: true }]}
        label={t("rubber_plantation_area_approx_in_rai")}>
      <Input autoComplete="off" />
      </Form.Item>
      </Col>

      <Col md={8} sm={24} xs={24}>
      <Form.Item
        name={"number_of_trees"}
        label={t("number_of_rubber_trees")}>
      <Input autoComplete="off" />
      </Form.Item>
      </Col>

      <Col md={8} sm={24} xs={24}>
      <Form.Item
        name={"non_product_rai"}
        label={t("non_productive_area_rai")}>
      <Input autoComplete="off" />
      </Form.Item>
      </Col>

      {/* <Col md={8} sm={24} xs={24}>
      <Form.Item
        name={"yield_rai"}
        label={"ให้ผลผลิตแล้ว(ไร่)"}>
      <Input autoComplete="off" />
      </Form.Item>
      </Col> */}

      <Col md={8} sm={24} xs={24}>
      <Form.Item
        name={"yield_kk"}
        label={t("yield_in_kg_per_rai_per_year")}>
      <Input autoComplete="off" />
      </Form.Item>
      </Col>


      <Col md={8} sm={24} xs={24}>
      <Form.Item
        name={"plant_spacing"}
        label={t("planting_distance")}>
        <Select
          style={{ width: '100%' }}
          options={[
            { value: '3x7', label: '3x7' },
            { value: '3x8', label: '3x8' },
            { value: '2.5x7', label: '2.5x7' },
            { value: '2.5x8', label: '2.5x8' },
          ]}
          />
      </Form.Item>
      </Col>

      <Col md={8} sm={24} xs={24}>
      <Form.Item
        name={"plant_year"}
        label={t("year_of_plantingB_E_")}
        rules={[() => ({ 
          validator(_, value) {
            if (value && (CURRENT_BUDDHIST_YEAR - value > MAX_YEAR_ALLOW)) {
              return Promise.reject(t("The_planting_year_must_not_exceed_the_current_year") + MAX_YEAR_ALLOW + " ปี")
            }
            return Promise.resolve();
          } 
        })]}
        onBlur={() => {
          if (plantYear && (CURRENT_BUDDHIST_YEAR - plantYear > MAX_YEAR_ALLOW)) {
            form.setFieldValue('plant_year', CURRENT_BUDDHIST_YEAR - MAX_YEAR_ALLOW)
          }
        }}>
      <Input 
        autoComplete="off"
        type="number"
        min={CURRENT_BUDDHIST_YEAR - MAX_YEAR_ALLOW}
        addonAfter={<>{t("age")}  {plantYear ? CURRENT_BUDDHIST_YEAR - parseInt(plantYear) : 0} {t("years")}</>}/>
      </Form.Item>
      </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item 
            name={"deed_document"}
            label={t("proof_of_rights_documents")}
            >
              <UploadFile
                className={"upload-list-inline"}
                style={{ background : colorPrimary , color : 'white'}}
                btnText = {t("upload_proof_of_rights_documents")} 
                maxCount = {3}
                ref = {deedRef}
                dataList={props.deed_document ? props.deed_document : null}
                onRemove={(data) => DELETE(`${URL_PLANTATIONS.DOCUMENT}${data.id}/`)}
              />
            </Form.Item>
              <p style={{ color: "red" }}>
                {t("please_upload_file")}
              </p>
            </Col>

          <Col md={12} sm={24} xs={24}>
            <Form.Item name={"is_tax_paid"} label={t("tax_payment")}>
              <Radio.Group name={"is_tax_paid"} onChange={onRadioChange}>
                <Radio value={true}>{t("land_tax_paid")}</Radio>
                <Radio value={false}>{t("land_tax_not_yet_paid")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {form.getFieldValue("is_tax_paid") === true && (
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                name={"invoice_id"}
                label={t("latest_tax_payment_document_number")}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          )}

          {form.getFieldValue("is_tax_paid") === true && (
            <Col md={12} sm={24} xs={24}>
              <Form.Item name={"tax_paid_date"} label={t("date_of_latest_tax_payment")}>
                <DatePicker
                  style={{ width: "100%" }}
                  format={DATE_FORMAT}
                  onChange={onDateChange}
                />
              </Form.Item>
            </Col>
          )}

          {form.getFieldValue("is_tax_paid") === true && (
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={"invoice_documents"}
                label={t("attach_proof_of_current_year_land_tax_payment")}
              >
              <UploadFile
                className={"upload-list-inline"}
                style={{ background : colorPrimary , color : 'white'}}
                btnText = {t("upload_proof_of_land_tax_payment")} 
                maxCount = {1}
                ref = {invoiceRef}
                dataList={props.invoice_document ? props.invoice_document : null}
                onRemove={(data) => DELETE(`${URL_PLANTATIONS.DOCUMENT}${data.id}/`)}
              />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Card>
  );
});

PlantationInfoView.defaultProps = {};

PlantationInfoView.propTypes = {};

export default React.memo(PlantationInfoView);
