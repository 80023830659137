import React from "react";
import { Alert, AutoComplete, Form, Input, Modal, Select } from "antd";
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_STOCK } from "../../constants/urls";
import {
  DownOutlined,
} from '@ant-design/icons';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';



export default function ChangeStockModal(props) {
  const [form] = Form.useForm();
  const [stockOptions, setStockOptions] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const { t, i18n } = useTranslation();

  const {
    open,
    target,
    onClose,
    product,
    stockID,
  } = props;

  const handleOK = async() => {
    setErrorMessages(null)
    try {
      let data = await form.validateFields();
      await POST(`${URL_STOCK.STOCK_TRANSACTION}${target}/move/`, data)
      props.onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)      
    }
  }

  const fetchStock = async (product_id) => {
    setStockOptions([]);
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_STOCK.STOCK, { is_closed: false, product: product_id })
      let options = (_.get(response, 'data.results', []).map(el => ({
        ...el, value: el.id, label: el.code,
      })))
      setStockOptions(options.filter(item => item.id !== stockID ))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (open) {
        fetchStock(product)
    } else {
      setSelected(null);
      form.resetFields();
    }
  },[open])

  return(
    <Modal
    title={t("to_transfer_lots")}
    open={props.open}
    onCancel={onClose}
    onOk={handleOK}
    okText={t("confirm")}
    cancelText={t("cancel")}>
      { errorMessages && <Alert style={{ marginTop: '1rem' }} message={errorMessages} type='error' showIcon/>}
      <Form form={form}>
        <Form.Item name={"stock"} label={t("select_the_lot_you_want")} style={{ margin : 10, marginBottom : 26 }}>
          <Select options={stockOptions} onChange={(e) => setSelected(e)}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}