import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import CustomRoutes from './routes/CustomRoutes';

import { Layout, Spin, ConfigProvider } from 'antd';
import { PRIMARY_COLOR, WHITE_COLOR_BG } from './constants/color';
import UserSettingProvider from './hooks/UserSettingProvider';
import WindowDimensionProvider from './hooks/WindowDimensionProvider';
const { Content } = Layout;


function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
          colorBgLayout: WHITE_COLOR_BG,
          fontSize: 16,
        },
      }}>
      <WindowDimensionProvider>
        <UserSettingProvider>
          <React.Suspense fallback={
            <Layout>
              <Content>
                <Spin  size="large" tip="Loading..."/>
              </Content>
            </Layout>
          }>
            <Router>
              <div className="App">
                <CustomRoutes />
              </div>
            </Router>
          </React.Suspense>
        </UserSettingProvider>
      </WindowDimensionProvider>
    </ConfigProvider>
  );
}

export default App;
