import { Layout, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import HeaderScreen from "../components/common/HeaderScreen";
import { BG_LAYOUT_COLOR } from "../constants/color";
import DashboardTabCcView from "../views/DashboardTabCcView";

export default function DashboardDetailCCScreen() {

  const {
		token: { colorBgContainer, colorWarning, colorInfo, colorPrimary, colorError },
	} = theme.useToken();

  return (
    <Layout style={{ minHeight: '100vh', background: BG_LAYOUT_COLOR }}>
      <HeaderScreen title='Dashboard'/>
      <Content 
        style={{ 
          margin: "0px 24px 24px 24px", 
          padding: 24,
          textAlign: "left", 
          minHeight: 280,
          background: colorBgContainer }}>
        
        <DashboardTabCcView/>

      </Content>
    </Layout>
  )
}