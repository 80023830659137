import React from "react";
import { Modal, Form, Row, Col, Input, Divider, Radio, Alert, message, theme, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next';
import { PATCH, POST } from "../../frameworks/HttpClient";
import { URL_DELIVERY } from "../../constants/urls";
import { useUserContext } from "../../hooks/UserSettingProvider";
import * as _ from 'lodash';
import ColorButton from "../common/ColorButton";

export default function FactoryCustomerModal (props){
  const { open, onClose, target, onUpdate, handleDelete } = props;
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isCreate = target === 'add';
  const [loading, setLoading] = React.useState(false);
  const { user } = useUserContext();

  const {
		token: { colorPrimary, colorWarning },
	} = theme.useToken();

  const handleSubmit = async() => {
    setErrorMessages(null);
    try {
      const data = await form.validateFields();
      data['factory'] = _.get(user, 'factory', null);
      if(isCreate){
        await POST(URL_DELIVERY.SI_CUSTOMER, data)
      } else {
        await PATCH(`${URL_DELIVERY.SI_CUSTOMER}${target.id}/`, data)
      }
      onUpdate();
      message.success(t('data_saved_successfully'))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  React.useEffect(() => {
    if(open){
      if(!isCreate){
        form.setFieldsValue(target);
      }
    } else{
      form.resetFields();
    }
  },[open])

  return(
    <Modal
      open={open}
      onCancel={onClose}
      onOk={handleSubmit}
      okText={isCreate ? t('fac_customer.add') : t('fac_customer.edit') }
      okButtonProps={{ style: {background: isCreate ? colorPrimary : colorWarning}}}
      cancelText={''}
      width={800}
      footer={
        <>
          {!isCreate && 
            <Popconfirm
              title={t("confirm_delete_customer")}
              onConfirm={() => handleDelete(props.target.id)}
              okText= {t("actions.delete_data")}
              okButtonProps={{ danger: true }}
              cancelText={t("cancel")}
              placement='topRight'>
        
              <ColorButton
                style={{ float: 'left'}}
                type={'primary'}
                danger >
               {t("actions.delete_data")}
              </ColorButton>
            </Popconfirm>
          }
          <ColorButton loading={loading} onClick={props.onClose}> {t("cancel")} </ColorButton>
          <ColorButton 
            style={{background: !isCreate ? colorWarning : colorPrimary, color : 'white'}} 
            loading={loading} 
            onClick={handleSubmit}>
              {!isCreate ? t("actions.edit") : t("add_general_customers")}
          </ColorButton>
        </>
      }
    >
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginTop: '1rem', textAlign: "left"}}
        />
      }
      <Divider orientation="left" orientationMargin="0">
        {t("fac_customer.detail")}
      </Divider>

      <Form
      style={{marginTop: 24}}
      form={form}
      layout="vertical"
      autoComplete="off">
        <Row gutter={16}>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"code"} label={t("fac_customer.code")} rules={[{ required: true }]}>
              <Input autoComplete="off" placeholder={t("fac_customer.code")}/>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"name"} label={t("fac_customer.business_name")} rules={[{ required: true }]}>
               <Input autoComplete="off" placeholder={t("fac_customer.business_name")}/>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"invoice_id"} label={t("fac_customer.invoice_no")}>
              <Input autoComplete="off" placeholder={t("fac_customer.invoice_no")}/>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"type"} label={`${t("fac_customer.office")}/${t("fac_customer.branch")}`}>
              <Input autoComplete="off" placeholder={t("fac_customer.invoice_no")}/>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"address"} label={t("fac_customer.address")}>
              <Input.TextArea autoComplete="off" placeholder={t("fac_customer.address")}/>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"delivery_address"} label={t("fac_customer.shipping_address")} >
            <Input.TextArea autoComplete="off" placeholder={t("fac_customer.shipping_address")}/>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"phone"} label={t("fac_customer.office_phone")}>
              <Input autoComplete="off" placeholder={t("fac_customer.office_phone")}/>
            </Form.Item>
          </Col>
          <Divider orientation="left" orientationMargin="0">
            {t("fac_customer.contractor_detail")}
          </Divider>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"contractor_name"} label={t("fac_customer.contractor_name")} rules={[{ required: true }]}>
              <Input autoComplete="off" placeholder={t("fac_customer.contractor_name")}/>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"contractor_email"} label={t("fac_customer.contractor_email")}>
              <Input autoComplete="off" placeholder={t("fac_customer.contractor_email")}/>
            </Form.Item>
          </Col>
          <Col xl={12} md={12} xs={24}>
            <Form.Item name={"contractor_phone"} label={t("fac_customer.contractor_phone")}>
              <Input autoComplete="off" placeholder={t("fac_customer.contractor_phone")}/>
            </Form.Item>
          </Col>
        </Row>
        
      </Form>
    </Modal>
  )
}