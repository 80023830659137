import { Form, Input, message, Modal } from "antd";
import React from "react";
import Header from "./Header";
import propTypes from "prop-types";
import useImportJsonParser from "../../hooks/useImportJsonParser";
import { useTranslation } from 'react-i18next';



export default function AddJsonModal(props) {
  const [form] = Form.useForm();
  const parser = useImportJsonParser();
  const { t, i18n } = useTranslation();


  const handleImportJson = async () => {
    try {
      const data = await form.validateFields();
      props.onImported(await parser(props.importType, JSON.parse(data['jsonData'])))
      props.onClose()
    } catch (error) {
      message.error(t("error_occurred_while_importing_json"))
      console.log(error)
    }
  }

  React.useEffect(() => {
    if (props.open) {
      form.resetFields();
    }
  }, [props.open])

  return (
    <Modal
      okText={t("ok")}
      cancleText={t("cancel")}
      width={'30%'}
      open={props.open}
      onOk={handleImportJson}
      onCancel={props.onClose}
    >
      <Header title={props.title}></Header>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
          
        <Form.Item name={"jsonData"}>
          <Input.TextArea rows={20} />
        </Form.Item>
      </Form>
    </Modal>
  )
}


AddJsonModal.defaultProps = {
  title: "",
  open: false,
  importType: "",
  onClose: () => null,
  onImported: () => null,
}

AddJsonModal.prototype = {
  title: propTypes.string,
  open: propTypes.bool,
  importType: propTypes.string,
  onClose: propTypes.func,
  onImported: propTypes.func,
}
