export const DATE_FORMAT = "YYYY-MM-DD"
export const HUMAN_DATE_FORMAT = "DD/MM/YYYY"
export const TIME_FORMAT = "HH:mm"
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm"
export const HUMAN_DATETIME_FORMAT = "DD/MM/YYYY HH:mm"
export const HUMAN_DATETIME_2_DIGIT_FORMAT = "DD/MM/YY HH:mm"
export const MONTH_FORMAT = "YYYY-MM"
export const YEAR_FORMAT = "YYYY"
export const DEFAULT_LOCATION = [9.095027670375663, 98.95762924382421]

export const USER_PERMISSION_LIST = [
  { id: 1, key: 1, menu: "จัดการแปลง", is_read: false, is_create: false, is_edit: false, is_delete: false },
  { id: 2, key: 2, menu: "สมาชิก", is_read: false, is_create: false, is_edit: false, is_delete: false },
  { id: 3, key: 3, menu: "จุดรับซื้อ", is_read: false, is_create: false, is_edit: false, is_delete: false },
  { id: 4, key: 4, menu: "โรงงาน", is_read: false, is_create: false, is_edit: false, is_delete: false },
  { id: 5, key: 5, menu: "ตั้งค่าผู้ใช้", is_read: false, is_create: false, is_edit: false, is_delete: false },
  { id: 6, key: 6, menu: "รายงาน", is_read: false, is_create: false, is_edit: false, is_delete: false },
]

export const PRODUCT_TRACEABILITY = {
  CT : "ct",
  FSC : 'fsc',
  NONE : 'none'
}

export const PLANTATION_TRACEABILITY = {
  CT : "ct",
  FSC : 'fsc',
  NONE : 'none'
}

export const USER_TYPE = {
  OFFICER: "officer",
  FACTORY: "factory",
  COLLECTING_CENTER: "cc",
  FARMER: "farmer",
}

export const  PLANTATION_STATUS = {
  DRAFT: 'draft',
  CHECKING:'checking',
  WAITING: 'waiting',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}

export const FARMER_STATUS = {
  draft: "ฉบับร่าง", 
  checking: "รอตรวจสอบพื้นที่", 
  waiting: "รออนุมัติ",
  approved: "อนุมัติ",
  rejected: "ไม่อนุมัติ",
}

export const HISTORY_DETAIL_NAME = {
  customer: "ลูกค้า",
  product_name: "สินค้า",
  in_weight: "น้ำหนักรับเข้า", 
  out_weight: "น้ำหนักส่งออก",
  paid_amount: "ราคาจ่ายจริง",
  gross_price_per_unit: "ราคากลางเปิดตลาด",
  drc: "DRC%",
  plantation: "แปลงสวนยาง",
  weight : "น้ำหนัก(แปลงสวนยาง)",
  unit : "หน่วยชั่ง",
  stock_refs: "ล็อต",
  plantation_sources: "แปลงสวนยาง",
  vehicle_license_plate: "ป้ายทะเบียน"

}

export const HISTORY_DETAIL = {
  CUSTOMER_ID: "customer_id",
  CUSTOMER_NAME: "customer_name",
  PRODUCT_ID: "product_id",
  PRODUCT_NAME: "product_name",
  VEHICLE_ID: "vehicle_id", 
  VEHICLE_LICENSE_PLATE: "vehicle_license_plate", 
  IN_WEIGHT: "in_weight", 
  OUT_WEIGHT: "out_weight",
  PAID_AMOUNT: "paid_amount",
  GROSS_PRICE_PER_UNIT: "gross_price_per_unit",
  DRC : "drc",
  PLANTATION_SOURCES: "plantation_sources",
  WEIGHT : "weight",
  UNIT : "unit",
  STOCK_REFS: "stock_refs",
}

export const UNIT = {
  GRAM: 'gram',
  KILOGRAM: 'kilo_gram',
  TONNE: 'tonne',
  LITRE: 'litre', 
}

export const UNIT_TRANSLATION = {
  [UNIT.GRAM]: 'กรัม',
  [UNIT.KILOGRAM]: 'กิโลกรัม',
  [UNIT.TONNE]: 'ตัน',
  [UNIT.LITRE]: 'ลิตร',
}

export const UNIT_OPTIONS = [
  { key: UNIT.GRAM, value: UNIT.GRAM, label: UNIT_TRANSLATION[UNIT.GRAM] },
  { key: UNIT.KILOGRAM, value: UNIT.KILOGRAM, label: UNIT_TRANSLATION[UNIT.KILOGRAM] },
  { key: UNIT.TONNE, value: UNIT.TONNE, label: UNIT_TRANSLATION[UNIT.TONNE] },
  { key: UNIT.LITRE, value: UNIT.LITRE, label: UNIT_TRANSLATION[UNIT.LITRE] },
]

export const STOCK_DIRECTION = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
}

export const STOCK_DIRECTION_TRANSLATION = {
  [STOCK_DIRECTION.DEPOSIT]: 'รับเข้า',
  [STOCK_DIRECTION.WITHDRAW]: 'ตัดออก',
}

export const WEIGHT_TICKET_DIRECTION = {
  BUY: 'buy',
  SELL: 'sell',
}

export const WEIGHT_TICKET_DIRECTION_TRANSLATION = {
  [WEIGHT_TICKET_DIRECTION.BUY]: 'ชั่งซื้อ',
  [WEIGHT_TICKET_DIRECTION.SELL]: 'ชั่งขาย',
}

export const MANUAL_TICKET_DIRECTION_TRANSLATION = {
  [WEIGHT_TICKET_DIRECTION.BUY]: 'รับสินค้า',
  [WEIGHT_TICKET_DIRECTION.SELL]: 'ส่งสินค้า',
}

export const CUSTOMER_TYPE = {
  GENERAL: 'general',
  FARMER: 'farmer',
  COLLECTING_CENTER: 'cc',
  FACTORY: 'factory'
}

export const CUSTOMER_TYPE_TRANSLATION = {
  [CUSTOMER_TYPE.GENERAL]: 'ลูกค้าทั่วไป',
  [CUSTOMER_TYPE.FARMER]: 'ชาวสวน',
  [CUSTOMER_TYPE.COLLECTING_CENTER]: 'จุดรับซื้อ',
  [CUSTOMER_TYPE.FACTORY]: 'โรงงาน'
}

export const  PLANTATION_STATUS_OPTIONS = [
  { key: 'all', value: "", label: 'ทั้งหมด' },
  { key: PLANTATION_STATUS.DRAFT, value: PLANTATION_STATUS.DRAFT, label: FARMER_STATUS[PLANTATION_STATUS.DRAFT] },
  { key: PLANTATION_STATUS.CHECKING, value: PLANTATION_STATUS.CHECKING, label: FARMER_STATUS[PLANTATION_STATUS.CHECKING] },
  { key: PLANTATION_STATUS.WAITING, value: PLANTATION_STATUS.WAITING, label: FARMER_STATUS[PLANTATION_STATUS.WAITING] },
  { key: PLANTATION_STATUS.APPROVED, value: PLANTATION_STATUS.APPROVED, label: FARMER_STATUS[PLANTATION_STATUS.APPROVED] },
  { key: PLANTATION_STATUS.REJECTED, value: PLANTATION_STATUS.REJECTED, label: FARMER_STATUS[PLANTATION_STATUS.REJECTED] },
]

export const FARMER_STATUS_OPTIONS = [
  { key: 'all', value: null, label: 'ทั้งหมด' },
  { key: 'none', value:'none', label: 'ไม่ทราบ'},
  { key: 'draft', value: 'draft', label: FARMER_STATUS.draft },
  { key: 'waiting', value: 'waiting', label: FARMER_STATUS.waiting },
  { key: 'checking', value: 'checking', label: FARMER_STATUS.checking },
  { key: 'approved', value: 'approved', label: FARMER_STATUS.approved },
  { key: 'rejected', value: 'rejected', label: FARMER_STATUS.rejected },
]

export const CUSTOMER_TYPE_OPTIONS = [
  { key: 'all', value: null, label: 'ทั้งหมด' },
  { key: CUSTOMER_TYPE.GENERAL, value: CUSTOMER_TYPE.GENERAL, label: CUSTOMER_TYPE_TRANSLATION[CUSTOMER_TYPE.GENERAL] },
  { key: CUSTOMER_TYPE.FARMER, value: CUSTOMER_TYPE.FARMER, label: CUSTOMER_TYPE_TRANSLATION[CUSTOMER_TYPE.FARMER] },
  { key: CUSTOMER_TYPE.COLLECTING_CENTER, value: CUSTOMER_TYPE.COLLECTING_CENTER, label: CUSTOMER_TYPE_TRANSLATION[CUSTOMER_TYPE.COLLECTING_CENTER] },
  { key: CUSTOMER_TYPE.FACTORY, value: CUSTOMER_TYPE.FACTORY, label: CUSTOMER_TYPE_TRANSLATION[CUSTOMER_TYPE.FACTORY] },
]

export const VEHICLE_TYPE = {
  TRUCK: 'truck', 
  PICKUP: 'pickup', 
  SIDECAR: 'sidecar', 
  MOTORCYCLE: 'motorcycle', 
  OTHER: 'other', 
}

export const VEHICLE_TYPE_TRANSLATION = {
  [VEHICLE_TYPE.TRUCK]: 'รถบรรทุก',
  [VEHICLE_TYPE.PICKUP]: 'รถกระบะ',
  [VEHICLE_TYPE.SIDECAR]: 'รถพ่วงข้าง',
  [VEHICLE_TYPE.MOTORCYCLE]: 'รถจักรยานยนต์',
  [VEHICLE_TYPE.OTHER]: 'อื่นๆ',
}

export const VEHICLE_TYPE_OPTIONS = [
  { key: VEHICLE_TYPE.TRUCK, value: VEHICLE_TYPE.TRUCK, label: VEHICLE_TYPE_TRANSLATION[VEHICLE_TYPE.TRUCK] },
  { key: VEHICLE_TYPE.PICKUP, value: VEHICLE_TYPE.PICKUP, label: VEHICLE_TYPE_TRANSLATION[VEHICLE_TYPE.PICKUP] },
  { key: VEHICLE_TYPE.SIDECAR, value: VEHICLE_TYPE.SIDECAR, label: VEHICLE_TYPE_TRANSLATION[VEHICLE_TYPE.SIDECAR] },
  { key: VEHICLE_TYPE.MOTORCYCLE, value: VEHICLE_TYPE.MOTORCYCLE, label: VEHICLE_TYPE_TRANSLATION[VEHICLE_TYPE.MOTORCYCLE] },
  { key: VEHICLE_TYPE.OTHER, value: VEHICLE_TYPE.OTHER, label: VEHICLE_TYPE_TRANSLATION[VEHICLE_TYPE.OTHER] },
]

export const GEOGRAPHY_LAYER_TYPES = {
  DE_FOREST: 'de_forest',
  NATIONAL_PARK: 'national_park',
  NON_HUNTING: 'non_hunting',
  PROTECTED_AREA: 'protected_area',
  FOREST_COVER: 'forest_cover',
  RESERVED_FOREST: 'reserved_forest',
}

export const GEOGRAPHY_LAYER_COLOR = {
  DE_FOREST: '#FF5733',
  NATIONAL_PARK: '#003300',
  NON_HUNTING: '#76448A',
  PROTECTED_AREA: '#0066ff',
  FOREST_COVER: '#339933',
  RESERVED_FOREST: '#ff0000',
  NEIGHBOR_PLOT: '#ffc100',
}

export const GEOGRAPHY_LAYER_NAMES = {
  DE_FOREST: 'EU Deforestation',
  NATIONAL_PARK: 'National Park',
  NON_HUNTING: 'Non Hunting',
  PROTECTED_AREA: 'Protected Area',
  FOREST_COVER: 'Forest Cover',
  RESERVED_FOREST: 'Reserved Forest',
  NEIGHBOR_PLOT: 'Neighbor Plot',
}

export const PLANTATION_DOCUMENT_TYPE = {
  DEED: "deed",
  RENTAL: "rental",
  INVOICE: "invoice",
  PROXY: "proxy",
  ADDITIONAL: "additional",
}

export const CHEMICAL_TYPE = {
  PV: 'pv',
  WHITE_CHEMICAL: 'ยาขาว',
  TMTD: "TMTD",
}

export const CHEMICAL_TYPE_TRANSLATION = {
  [CHEMICAL_TYPE.PV]: 'PV',
  [CHEMICAL_TYPE.WHITE_CHEMICAL]: 'ยาขาว',
  [CHEMICAL_TYPE.TMTD]: 'TMTD',
}

export const CHEMICAL_TYPE_OPTIONS = [
  { key: CHEMICAL_TYPE.PV, value: CHEMICAL_TYPE.PV, label: CHEMICAL_TYPE_TRANSLATION[CHEMICAL_TYPE.PV] },
  { key: CHEMICAL_TYPE.WHITE_CHEMICAL, value: CHEMICAL_TYPE.WHITE_CHEMICAL, label: CHEMICAL_TYPE_TRANSLATION[CHEMICAL_TYPE.WHITE_CHEMICAL] },
  { key: CHEMICAL_TYPE.TMTD, value: CHEMICAL_TYPE.TMTD, label: CHEMICAL_TYPE_TRANSLATION[CHEMICAL_TYPE.TMTD] },
]

export const PLANTATION_POLYGON_TYPE = {
  TOTAL: "total",
  OPERATION: "operation",
  WATER_SOURCE: "water_source",
  CONSERVATION: "conservation",
  RESIDENTAL: "residental",
}

export const PLANTATION_POLYGON_TYPE_TRANSLATION = {
  [PLANTATION_POLYGON_TYPE.TOTAL]: 'พื้นที่ตามเอกสารสิทธิ์',
  [PLANTATION_POLYGON_TYPE.OPERATION]: 'พื้นที่ทำสวน',
  [PLANTATION_POLYGON_TYPE.WATER_SOURCE]: 'พื้นที่แหล่งน้ำ',
  [PLANTATION_POLYGON_TYPE.CONSERVATION]: 'พื้นที่อนุรักษ์',
  [PLANTATION_POLYGON_TYPE.RESIDENTAL]: 'พื้นที่อยู่อาศัย'
}

export const PLANTATION_POLYGON_TYPE_OPTIONS = [
  { key: PLANTATION_POLYGON_TYPE.TOTAL, value: PLANTATION_POLYGON_TYPE.TOTAL, label: PLANTATION_POLYGON_TYPE_TRANSLATION[PLANTATION_POLYGON_TYPE.TOTAL] },
  { key: PLANTATION_POLYGON_TYPE.OPERATION, value: PLANTATION_POLYGON_TYPE.OPERATION, label: PLANTATION_POLYGON_TYPE_TRANSLATION[PLANTATION_POLYGON_TYPE.OPERATION] },
  { key: PLANTATION_POLYGON_TYPE.WATER_SOURCE, value: PLANTATION_POLYGON_TYPE.WATER_SOURCE, label: PLANTATION_POLYGON_TYPE_TRANSLATION[PLANTATION_POLYGON_TYPE.WATER_SOURCE] },
  { key: PLANTATION_POLYGON_TYPE.CONSERVATION, value: PLANTATION_POLYGON_TYPE.CONSERVATION, label: PLANTATION_POLYGON_TYPE_TRANSLATION[PLANTATION_POLYGON_TYPE.CONSERVATION] },
  { key: PLANTATION_POLYGON_TYPE.RESIDENTAL, value: PLANTATION_POLYGON_TYPE.RESIDENTAL, label: PLANTATION_POLYGON_TYPE_TRANSLATION[PLANTATION_POLYGON_TYPE.RESIDENTAL] }
]

export const POLYGON_TYPE_COLOR = {
  TOTAL: "#FA8808",
  OPERATION: "#00FF34",
  WATER_SOURCE: "#009EFF",
  CONSERVATION: "#FFF85F",
  RESIDENTAL: "#B38FFF",
}

export const INPUT_TYPE = {
  WEIGHT: 'weighting',
  MANUAL: 'manual'
}

export const OFFICER_PERMISSION = {
  MANAGER : "officer_manager",
  STAFF: "officer_staff",
  REPORT: "officer_report",
}

export const FARMER_PLANTATION = {
  OWNER : "owner",
  RENTER: "renter",
  PROXY: "proxy",
}

export const WORKER_TYPE = {
  FOREIGN : "foreign",
  THAI_LOCAL : "thai_local",
  THAI : "thai",
}

export const WORKER_TYPE_TEXT = {
  [WORKER_TYPE.FOREIGN]: "แรงงานต่างด้าว",
  [WORKER_TYPE.THAI_LOCAL]: "แรงงานไทยในพื้นที่",
  [WORKER_TYPE.THAI]: "แรงงานไทยต่างพื้นที่",
}

export const WORKER_TYPE_OPTIONS = [
  { key: WORKER_TYPE.FOREIGN, value: WORKER_TYPE.FOREIGN, label: WORKER_TYPE_TEXT[WORKER_TYPE.FOREIGN] },
  { key: WORKER_TYPE.THAI_LOCAL, value: WORKER_TYPE.THAI_LOCAL, label: WORKER_TYPE_TEXT[WORKER_TYPE.THAI_LOCAL] },
  { key: WORKER_TYPE.THAI, value: WORKER_TYPE.THAI, label: WORKER_TYPE_TEXT[WORKER_TYPE.THAI] },
]

export const REPORT_FILTER = {
  STATION: 'station',
  MONTH: "month",
	MONTH_RANGE: "monthRange",
  DEL001: "del001",
  USER_CC: "user_cc",
  PRODUCT_TYPE: "product_type",
  CCT: "cct",
  APPROVE_DATE_RANGE: "approveDateRange",
  STATUS: "status", // Plantation status
  PROVINCE: "province"
}
export const FARMER_FOCUS = {
  FOREIGN:'ชาวสวนนอกพื้นที่',
  LOCAL:'ชาวสวนในพื้นที่',
}
// export const FARMER_FOCUS_TRANSLATION = {
//   [FARMER_FOCUS.FOREIGN]: 'ชาวสวนนอกพื้นที่',
//   [FARMER_FOCUS.LOCAL]: 'ชาวสวนในพื้นที่',
// }
export const FARMER_TYPE = {
  OWNER:'เจ้าของสวน',
  RENTER:'คนเช่าสวน',
  PROXY:'ผู้ได้รับมอบอำนาจ',
}
export const AUBBER_AGE = {
  LT_7:'แปลงที่มีอายุน้อยกว่า 7 ปี',
  L7_24:'แปลงที่มีอายุ 7-24 ปี',
  GT_24:'แปลงที่มีอายุมากกว่า 24 ปี',
}

export const IMPORT_JSON_TYPE = {
  FARMER: "FARMER",
  PLANTATION: "PLANTATION"
}

export const IS_FSC_OPTIONS = [
  { key: 'fsc', label: 'FSC', value: true },
  { key: 'ct', label: 'CT', value: false },
]

export const LANGUAGE = {
  EN: "en",
  TH: "th"
}